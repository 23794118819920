import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { isNonNullish } from 'remeda';
import { useIsMobile } from 'shared/hooks/utils';
import { animationClassList } from 'shared/utils/view';
import { DialogShowId, DialogShowSize, showDialog, sideMenuHide } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import AYSModal from '../AYSModal';
import { Icon, IconName } from '../Icon';

import styleIdentifiers from './sideMenu.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function SideMenu() {
  const sideMenu = useSelector((state: StoreState) => state.view.sideMenu);
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const sideMenuRef = useRef<HTMLDivElement>(null);
  const swipeData = {
    totalSize: 0,
    start: 0,
  };

  useEffect(() => {
    if (sideMenu.active) {
      document.addEventListener('keydown', handleKeyPress);
      if (isMobile && isNonNullish(sideMenuRef.current)) {
        swipeData.totalSize = window.innerWidth;
        sideMenuRef.current!.addEventListener('touchstart', onTouchStart);
        sideMenuRef.current!.addEventListener('touchend', onTouchEnd);
      }
    } else {
      document.removeEventListener('keydown', handleKeyPress);
      if (isMobile && isNonNullish(sideMenuRef.current)) {
        sideMenuRef.current!.removeEventListener('touchstart', onTouchStart);
        sideMenuRef.current!.removeEventListener('touchend', onTouchEnd);
      }
    }

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
      if (isMobile && isNonNullish(sideMenuRef.current)) {
        sideMenuRef.current!.removeEventListener('touchstart', onTouchStart);
        sideMenuRef.current!.removeEventListener('touchend', onTouchEnd);
      }
    };
  }, [sideMenu.active, sideMenu.askBeforeClose]);

  const handleKeyPress = (event: any) => event.key === 'Escape' && sideMenu.active && hide();

  const onTouchStart = (e) => {
    swipeData.start = e.changedTouches[0].screenX;
  };
  const onTouchEnd = (e) =>
    e.changedTouches[0].screenX - swipeData.start > swipeData.totalSize / 4 && hide();

  const hide = () => {
    if (sideMenu.askBeforeClose) {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        keepMountOnExit: true,
        title: t(i18nKeys.ATTENTION),
        children: (
          <AYSModal text={t(i18nKeys.FORM.SIDE_MENU.LOST_INFO)} onConfirm={() => sideMenuHide()} />
        ),
      });
    } else {
      sideMenuHide();
    }
  };

  const nodeRef = useRef(null);

  return (
    <CSSTransition
      in={sideMenu.active || false}
      timeout={400}
      nodeRef={nodeRef}
      unmountOnExit={sideMenu.unmount}
      classNames={animationClassList('side', styles)}
    >
      <div
        ref={nodeRef}
        className={styles('SideMenu', 'right', status)}
        style={{
          zIndex: sideMenu.zIndex,
        }}
      >
        <div className={styles('background', 'absolute-fill')} onClick={() => hide()} />
        <div ref={sideMenuRef} className={styles('menu')}>
          <Icon name={IconName.SMALL_REMOVE} className={styles('close-icon')} onClick={hide} />
          {sideMenu.content}
        </div>
      </div>
    </CSSTransition>
  );
}

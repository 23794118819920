import { useLayoutEffect, useState } from 'react';
import { useGetDebtorsInfinite } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router';
import { flat, map, pipe } from 'remeda';
import { openEditClient } from 'shared/action-component/EditClientForm';
import { Button, CustomSelect } from 'shared/io';
import { treatClient } from 'shared/serializer';
import { getNextPageParam } from 'shared/utils';
import { getSearchInvoicesUrlParams } from 'shared/utils/hooks';
import { clientActions } from 'store/client/client.actions';
import { Debtor } from 'types/store/client-state';

import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type DebtorFieldProps = InvoiceFormSharedProps;

export const DebtorField = ({}: DebtorFieldProps) => {
  const { t } = useTranslation();
  const { setValue, watch } = useFormContext<InvoiceFormFieldValues>();

  const [selectSearchValue, setSelectSearchValue] = useState<string>();
  const { data: debtorsResponse, fetchNextPage } = useGetDebtorsInfinite(
    { name: selectSearchValue },
    {
      query: {
        getNextPageParam,
      },
    },
  );

  const location = useLocation();
  const params = getSearchInvoicesUrlParams(location);
  const currentDebtor = watch('debtor_attributes');

  useLayoutEffect(() => {
    const { debtor_id } = params;

    if (!currentDebtor && debtor_id) {
      clientActions.detail({
        id: Number(debtor_id),
        callback: ({ data: dataDebtor }) => {
          const findedDebtor = treatClient(dataDebtor);

          if (!findedDebtor) return;

          setValue('debtor_attributes', findedDebtor);
        },
      });
    }
  }, []);

  const addDebtor = () =>
    openEditClient({
      callback: (newDebtor: Debtor) => {
        setValue('debtor_attributes', newDebtor);
      },
    });

  const pages = debtorsResponse?.pages ?? [];

  const debtors = pipe(
    pages,
    map((e) => e.data),
    flat(),
    map(treatClient),
  );

  return (
    <>
      <Controller
        name="debtor_attributes"
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            name="debtor_attributes"
            items={debtors}
            keyText="full_name"
            className={styles('input', 'invoice-select-input')}
            selectClassName={styles('input', 'invoice-custom-select')}
            valueClassName={styles('value')}
            errorClassName={styles('error')}
            noArrow
            noBorder
            filter
            onChangeFilter={setSelectSearchValue}
            noMargin
            infiniteScroll
            placeholderFilter={t(i18nKeys.FORM.RESEARCH)}
            placeholder={t(i18nKeys.INVOICING.CREATE.SELECT_CLIENT)}
            load={fetchNextPage}
          />
        )}
      />
      <Button label="+" onClick={addDebtor} noMargin />
    </>
  );
};

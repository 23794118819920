import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { pick } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, CustomSelect } from 'shared/io';
import { useGetClientState } from 'shared/utils/selectors';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './PaymentDelayModal.module.scss';

const styles = classNames.bind(styleIdentifiers);
type PaymentDelayModalProps = {
  onSubmit: (values: Partial<Debtor>) => void;
  initialValues?: Partial<Debtor>;
};

export const PaymentDelayModal = ({ onSubmit, initialValues }: PaymentDelayModalProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();
  const { isLoading: isClientLoading } = useGetClientState()!;

  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const CgvItems = company.terms_and_conditions_attributes.map(
    ({ name, id, default: isDefault }) => ({
      description: name,
      value: Number(id),
      default: isDefault,
    }),
  );

  const defaultCgv = CgvItems.find((e) => e.default);

  return (
    <div className={styles('payment-delay-modal')}>
      <FormProvider {...form}>
        <Controller
          defaultValue={defaultCgv?.value}
          name="terms_and_conditions_id"
          render={() => (
            <CustomSelect
              noMargin
              selectClassName={styles('select')}
              keyText="description"
              keyValue="value"
              items={CgvItems}
              name="terms_and_conditions_id"
            />
          )}
        />
      </FormProvider>
      <div className={styles('button-wrapper')}>
        <Button
          isLoading={isClientLoading}
          label={t(i18nKeys.CONFIRM)}
          onClick={() => {
            onSubmit(pick(form.getValues(), 'terms_and_conditions_id'));
          }}
        />
      </div>
    </div>
  );
};

import {
  CreateWorkflowBody,
  PlanType,
  StepType,
  Workflow,
  WorkflowSteps,
  WorkflowStepsAttributes,
} from 'api/models';
import { get, omit } from 'lodash-es';
import { CompanyConfiguration } from 'shared/hooks';

import { WorkflowStepsTarget } from '../Workflows.constants';

import { TemplateConditions, TEMPLATES } from './WorkflowForm.constants';

// TODO: KILL ME ! When FUC**** CustomSelect is destroyed !
export const isTrue = (e?: string | boolean) => String(e) === 'true';

export const getWorkflowRights = (company: CompanyConfiguration, planType: PlanType) => {
  const {
    package: { can_use_advanced_payment_plans, can_use_payment_plans },
  } = company;

  switch (planType) {
    case PlanType.payment:
      return {
        canAdd: can_use_payment_plans && can_use_advanced_payment_plans,
        canDuplicate: can_use_payment_plans && can_use_advanced_payment_plans,
        canDelete: can_use_payment_plans && can_use_advanced_payment_plans,
        canEdit: can_use_payment_plans,
        canAddStep: can_use_payment_plans,
        canEditStep: can_use_payment_plans,
        canDeleteStep: can_use_payment_plans,
      };
    default:
      return {
        canAdd: false,
        canEdit: false,
        canDuplicate: false,
        canDelete: false,
        canAddStep: false,
        canEditStep: false,
        canDeleteStep: false,
      };
  }
};

export const getWorkflowStepRights = (company: CompanyConfiguration, planType: PlanType) => {
  const {
    package: { can_use_payment_plans, can_use_tasks },
  } = company;

  switch (planType) {
    case PlanType.payment:
      return {
        canAddTask: can_use_payment_plans && can_use_tasks,
        canEditTask: can_use_payment_plans && can_use_tasks,
        canAddReminder: can_use_payment_plans,
        canEditReminder: can_use_payment_plans,
      };
    default:
      return {
        canAddTask: false,
        canAddReminder: false,
        canEditTask: false,
        canEditReminder: false,
      };
  }
};

const getTemplates = (planType: PlanType, name: WorkflowStepsTarget): TemplateConditions[] =>
  get(TEMPLATES, `${planType}.${name}`) || [];

export const getAvailableTemplates = (
  planType: PlanType,
  name: WorkflowStepsTarget,
  values: WorkflowStepsAttributes[],
  index: number,
): StepType[] => {
  let templates = getTemplates(planType, name);

  const firstValue = templates.find((e) => e.first)?.value;
  const lastValue = templates.find((e) => e.last)?.value;

  if (
    firstValue &&
    values.some((el) => el.step_type === firstValue) &&
    index <= values.findIndex((el) => el.step_type === firstValue)
  ) {
    templates = templates.filter((e) => e.value === firstValue);
  }

  if (
    lastValue &&
    values.some((el) => el.step_type === lastValue) &&
    index > values.findIndex((el) => el.step_type === lastValue)
  ) {
    templates = templates.filter((e) => e.value === lastValue);
  }

  templates = templates.filter((e) =>
    e.single ? !values.some((el) => el.step_type === e.value) : true,
  );

  return templates.map((el) => el.value);
};

export const isBeforeDueDate = (name: WorkflowStepsTarget) =>
  name === WorkflowStepsTarget.BEFORE_DUE_DATE;

export const isAfterDueDate = (name: WorkflowStepsTarget) =>
  name === WorkflowStepsTarget.AFTER_DUE_DATE;

export const removeStepId = (step: WorkflowStepsAttributes) => omit(step, 'id');

const formatStepsValues = (step: WorkflowStepsAttributes): WorkflowStepsAttributes =>
  step?.template
    ? {
        ...omit(step, 'template'),
        template_id: step.template.id,
      }
    : step;

export const formatValues = ({
  before_due_date_steps,
  after_due_date_steps,
  ...rest
}: CreateWorkflowBody): CreateWorkflowBody => ({
  ...rest,
  before_due_date_steps: before_due_date_steps.map(formatStepsValues),
  after_due_date_steps: after_due_date_steps.map(formatStepsValues),
});

const getDefaultStepsValues = ({ id, attributes }: WorkflowSteps) => ({
  id,
  ...attributes,
});

export const getDefaultValues = (planType: PlanType, worflow?: Workflow): CreateWorkflowBody => {
  if (!worflow) {
    return {
      name: '',
      plan_type: planType,
      before_due_date_steps: [],
      after_due_date_steps: [],
    };
  }

  const {
    attributes: { before_due_date_steps, after_due_date_steps, ...rest },
  } = worflow;

  return {
    ...rest,
    before_due_date_steps: before_due_date_steps.map(getDefaultStepsValues),
    after_due_date_steps: after_due_date_steps.map(getDefaultStepsValues),
  };
};

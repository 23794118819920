import { i18nKeys } from 'locales/index';
import { useTranslation } from 'locales/logic';
import { isDefined } from 'remeda';

import { ActionIcon, Badge, Box, Group, Text, Tooltip, useMantineTheme } from '@mantine/core';
import { IconCircleArrowUpFilled } from '@tabler/icons-react';

const badgeStyles = {
  notification: {
    root: {
      padding: '11px 6px',
    },
  },
  tag: {
    root: {
      borderColor: '#CCD7DE',
      borderWidth: 1,
      padding: '11px 6px',
    },
  },
};

interface MenuItemBadgesProps {
  unreadCount?: number;
  tagLabel?: string;
  showUpsell?: boolean;
}

export const MenuItemBadges = ({ unreadCount, tagLabel, showUpsell }: MenuItemBadgesProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  if (!isDefined(unreadCount) && !isDefined(tagLabel) && !isDefined(showUpsell)) return null;

  return (
    <Group gap={5}>
      {isDefined(unreadCount) && unreadCount > 0 && !showUpsell && (
        <Badge styles={badgeStyles.notification} size="sm" color="#CCD7DE" radius={6}>
          <Text fw={500} fz={12} c="#345D79">
            {unreadCount}
          </Text>
        </Badge>
      )}
      {isDefined(tagLabel) && (
        <Badge
          styles={{ ...badgeStyles.tag, label: { textTransform: 'capitalize' } }}
          color="#F2F5F7"
          size="sm"
          radius={6}
        >
          <Text fw={500} fz={12} c="#345D79">
            {tagLabel}
          </Text>
        </Badge>
      )}
      {showUpsell && (
        <Tooltip
          label={t(i18nKeys.NAV.UPSELL)}
          position="right"
          offset={30}
          withArrow
          arrowSize={8}
          radius={6}
        >
          <Box
            color="blue"
            h={20}
            style={{ pointerEvents: 'auto', filter: 'opacity(1)', cursor: 'default' }}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
            }}
            variant="transparent"
          >
            <IconCircleArrowUpFilled stroke={1.5} size={20} color={theme.colors.blue[4]} />
          </Box>
        </Tooltip>
      )}
    </Group>
  );
};

import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { isConnectorActive } from 'shared/utils/connector';

import { IconFileDollar, IconInvoice, IconShoppingCart } from '@tabler/icons-react';

import { Menu } from '../types';

export function useBuildInvoiceMenu(): Menu {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const hasActiveConnector = isConnectorActive();

  return useMemo(() => {
    const items = [
      {
        path: '/invoices/listing',
        label: t(i18nKeys.NAV.ALL_INVOICE),
        Icon: IconFileDollar,
      },
      {
        path: '/credit-notes/listing',
        label: t(i18nKeys.NAV.CREDIT_NOTES),
        Icon: IconInvoice,
      },
      {
        path: '/products',
        label: t(i18nKeys.NAV.PRODUCTS),
        Icon: IconShoppingCart,
        isHidden: hasActiveConnector,
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.COMMON.INVOICING),
      Icon: IconFileDollar,
      items,
    };
  }, [company, hasActiveConnector, t]);
}

import { AxiosInstance } from 'axios';
import { z } from 'zod';

import { notifications } from '@mantine/notifications';
import { useMutation } from '@tanstack/react-query';

import { addActionNameToMutationResult } from './utils/add-action-name-to-mutation-result';
import { ApiError, ApiResponse, useAxiosInstance } from './utils';

const MAX_FILE_SIZE = 5000000;
const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp', 'image/pdf'];

const attachmentsSchema = z
  .any()
  .refine((file) => file.size <= MAX_FILE_SIZE, 'Max image size is 5MB.')
  .refine((file) => ACCEPTED_IMAGE_TYPES.includes(file.type), 'Invalid image type.')
  .nullable();

const ExternalMailSchema = z.object({
  body: z.string(),
  original_mail_id: z.string(),
  subject: z.string(),
  to_mail: z.array(z.string()),
  attachments: z.array(attachmentsSchema).optional(),
});

export type ExternalMail = z.infer<typeof ExternalMailSchema>;

function createFormData(data: ExternalMail) {
  const formData = new FormData();

  formData.append('original_mail_id', data.original_mail_id);
  formData.append('body', data.body);
  formData.append('subject', data.subject);

  data.to_mail.forEach((email) => formData.append('to_mail[]', email));
  data.attachments?.forEach((file) => formData.append('attachments[]', file));

  return formData;
}

export async function sendExternalMail(axiosInstance: Promise<AxiosInstance>, data: ExternalMail) {
  const instance = await axiosInstance;
  const formData = createFormData(data);

  return instance.post('external_mails', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}

export function useSendExternalMail() {
  const axiosInstance = useAxiosInstance();

  const mutation = useMutation<ApiResponse, ApiError, ExternalMail>({
    mutationFn: (data) => sendExternalMail(axiosInstance, data),
    onSuccess: (response) => {
      notifications.show({
        message: response.data.message,
        color: 'green',
      });
    },
    onError: (error) => {
      notifications.show({
        message:
          error.response?.data.error_message ?? error.response?.data.errors[0] ?? error.message,
        color: 'red',
      });
    },
  });

  return addActionNameToMutationResult<ApiResponse, ApiError, ExternalMail, 'sendExternalMail'>(
    'sendExternalMail',
    mutation,
  );
}

export enum EmailDeliveryStatus {
  PROCESSED = 'processed',
  DELIVERED = 'delivered',
  OPEN = 'open',
  CLICK = 'click',
  BOUNCE = 'bounce',
  DROPPED = 'dropped',
  DEFERRED = 'deferred',
}

export type Activity = {
  activity_type:
    | 'comment'
    | 'payment'
    | 'invoice'
    | 'callcenter'
    | 'dispute'
    | 'pause'
    | 'lawyer'
    | 'bailiff'
    | 'reminders'
    | 'preference'
    | 'formal_notice';
  attachment_url: string;
  author: string;
  comment: string;
  date: Date;
  description: string;
  document_type: string;
  email_statuses?: {
    [key in EmailDeliveryStatus]?: Array<string>;
  };
  end_pause_at: Date;
  has_document: boolean;
  id: number;
  invoice_feedback: InvoiceFeedback;
  linked_credit_note_id?: number;
  new: boolean;
  payment_information: ActivityPaymentInfo;
  payment_plan_id: number;
  reason: string;
  tracked_id: number;
  tracked_reference: string;
  tracked_type: 'Invoice' | 'CreditNote' | 'Debtor' | 'Question' | 'CompanyDetail' | 'Task';
  reminder?: {
    id: string;
    wasSentByMail: boolean;
    wasSentByPost: boolean;
  };
};

export type AmountLocalizedMoneyObject = {
  currency: string;
  amount: number;
};

export type ActivityPaymentInfo = {
  currency: string;
  amount: number;
  modality: string;
  date: Date;
  counterparty_name: string;
  notes: string;
  external_id: string;
  localized_money_object: AmountLocalizedMoneyObject;
};

export type InvoiceFeedback = {
  id: number;
  type: string;
  conversation: Conversation;
  closed: boolean;
  has_tp_case_pdf: boolean;
};

export type Conversation = {
  id: number;
  type: string;
  messages: ConversationMessage[];
};

export type ConversationMessage = {
  id: number;
  type: string;
  is_system: boolean;
  body: string;
  author: string;
  owner: boolean;
  attachment_url: string;
  attachment_name: string;
  right?: boolean;
  date: Date;
};

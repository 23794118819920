import { useCancelPaymentPlan, useGetActivities, useGetPaymentPlan } from 'api';
import { Id } from 'api/models';
import InvoiceTimeline from 'app/Private/Invoices/ToHandleDetail/InvoiceTimeline/InvoiceTimeline';
import AttachedInvoicesList from 'app/Private/ThirdpartyCases/AttachedInvoices';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import moment from 'moment';
import { useHistory, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import AYSModal from 'shared/components/AYSModal';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import RecovrLogoLoader from 'shared/components/Loader';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { formatActivities } from 'shared/utils/activities.utils';
import { formatCommunication } from 'shared/utils/normalization';
import { formattedDate } from 'shared/utils/view';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import {
  AttachedInvoice,
  AttachedInvoices,
  CurrencyLocalizedMoneyObject,
} from 'types/store/thirdparty-case-state';

import { computeNextStepDate, computeStatus } from '../PaymentPlan.utils';

import { PaymentPlanDetailTable } from './PaymentPlanDetailTable';

import styleIdentifiers from './PaymentPlanDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

type PaymentPlanDetailProps = {
  planId?: Id;
  inModal?: boolean;
};

export const PaymentPlanDetail = ({ planId, inModal }: PaymentPlanDetailProps) => {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const history = useHistory();

  const canUseAdvancedPaymentPlans = company.package.can_use_advanced_payment_plans;

  const { id: paymentPlanId } = useParams<{ id: string }>();

  const {
    data: paymentPlanResponse,
    isLoading,
    isError,
  } = useGetPaymentPlan(planId || paymentPlanId);

  const { mutate: cancelPaymentPlan } = useCancelPaymentPlan();

  const {
    data: activitiesListResponse,
    isLoading: activitiesLoading,
    isError: activitiesError,
  } = useGetActivities({
    payment_plan_id: planId || paymentPlanId,
  });

  if (isLoading && !paymentPlanResponse && activitiesLoading) {
    return <RecovrLogoLoader />;
  }

  if (isError || !paymentPlanResponse || activitiesError || !activitiesListResponse) {
    return <span>Error</span>;
  }

  const { data: paymentPlan } = paymentPlanResponse;

  const {
    id,
    attributes: {
      created_at,
      currency,
      communication,
      completed,
      cancelled,
      cancelled_at,
      debtor,
      invoices,
      payment_steps_attributes,
      workflow,
      with_fees,
      current_step,
    },
  } = paymentPlan;

  const goToList = () => {
    dialogHide(DialogShowId.CUSTOM);
    history.push('/payment-plans/listing');
  };

  const cancelAndRedirect = (unpause) => {
    cancelPaymentPlan(
      { id, data: { unpause } },
      {
        onSuccess: () => {
          goToList();
          dialogHide(DialogShowId.CONFIRM);
        },
      },
    );
  };

  const handleClickCancelPlan = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.PAYMENT_PLAN)}
          confirmButtonColor={ButtonColor.BLUE}
          confirmButtonText={t(i18nKeys.YES)}
          cancelButtonText={t(i18nKeys.NO)}
          onCancel={() => cancelAndRedirect(false)}
          onConfirm={() => cancelAndRedirect(true)}
        />
      ),
    });
  };

  const handleClickEditPlan = () => {
    dialogHide(DialogShowId.INVOICE);
    history.push(`/payment-plan/${id}/edit`);
  };

  const currentStep = payment_steps_attributes.find((step) => step.id === current_step?.toString());

  const invoicesFormated = {
    type: 'attached_invoice',
    ...omit(invoices, ['details']),
    details: invoices!.details.map(
      ({
        id: invoiceId,
        attributes: { due_date: due_date_invoice, localized_money_object, ...rest },
      }) =>
        ({
          id: Number(invoiceId),
          due_date: moment.utc(due_date_invoice) as unknown as Date,
          localized_money_object: localized_money_object as unknown as CurrencyLocalizedMoneyObject,
          ...rest,
        }) as unknown as AttachedInvoice,
    ),
  } as AttachedInvoices;

  const hasPlanEnded = cancelled || completed;

  const STATUS = computeStatus(cancelled, completed);

  const activitiesListFormatted = formatActivities(activitiesListResponse);

  return (
    <div className={styles('payment-plan-detail', inModal && 'in-modal')}>
      <div className={styles('title-line')}>
        {!inModal && <Icon name={IconName.MINIMAL_LEFT} onClick={() => history.goBack()} />}
        <h1>{`${t(i18nKeys.PAYMENT_PLAN.TITLE.ONE)} #${id}`}</h1>
        <div className={styles('buttons-wrapper')}>
          <Button
            title={!canUseAdvancedPaymentPlans ? t(i18nKeys.NOT_INCLUDED) : ''}
            label={t(i18nKeys.EDIT)}
            noMargin
            onClick={handleClickEditPlan}
            disabled={!canUseAdvancedPaymentPlans || hasPlanEnded}
          />
          <Button
            label={t(i18nKeys.CANCEL)}
            noMargin
            onClick={handleClickCancelPlan}
            color={ButtonColor.RED}
            disabled={hasPlanEnded}
          />
        </div>
      </div>
      <div className={styles('line-wrapper')}>
        <Card title={t(i18nKeys.CLIENT.GENERAL_INFO)}>
          <div>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CREATION_DATE)}</span>
              <span className={styles('value')}>{formattedDate(created_at, currentLang)}</span>
            </div>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>WORKFLOW</span>
              <span className={styles('value')}>{workflow.attributes.name}</span>
            </div>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>
                {t(i18nKeys.PAYMENT_PLAN.ATTRIBUTES.COMMUNICATION)}
              </span>
              <span className={styles('value')}>{formatCommunication(communication)}</span>
            </div>
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.WITH_FEES)}</span>
              <span className={styles('value')}>{t(i18nKeys[with_fees ? 'YES' : 'NO'])}</span>
            </div>
          </div>
        </Card>
        <Card title={t(i18nKeys.DETAILS)}>
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.COMMON.CLIENT)}</span>
            <span className={styles('value', 'bigger')}>
              <NavLink to={`/clients/${debtor.id}`}>{debtor.attributes.full_name}</NavLink>
            </span>
          </div>
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.STATUS)}</span>
            <span className={styles('value')}>{t(i18nKeys[STATUS])}</span>
          </div>
          {cancelled && (
            <div className={styles('item')}>
              <span className={styles('subtitle')}>{t(i18nKeys.CANCELLED_AT)}</span>
              <span className={styles('value')}>{formattedDate(cancelled_at, currentLang)}</span>
            </div>
          )}
          <div className={styles('item')}>
            <span className={styles('subtitle')}>{t(i18nKeys.NEXT_DEADLINE)}</span>
            <span className={styles('value')}>
              {computeNextStepDate(STATUS, currentLang, currentStep)}
            </span>
          </div>
        </Card>
      </div>
      <AttachedInvoicesList
        title={t(i18nKeys.INVOICES)}
        invoices={invoicesFormated}
        withoutFees={!with_fees}
      />
      <PaymentPlanDetailTable currency={currency} paymentSteps={payment_steps_attributes} />
      {!!activitiesListFormatted.activities.length && (
        <InvoiceTimeline
          metadata={activitiesListFormatted.metadata}
          activities={activitiesListFormatted.activities}
        />
      )}
    </div>
  );
};

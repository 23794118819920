import { PropsWithChildren, useEffect, useState } from 'react';
import { PlanType, StepType, Template, WorkflowStepsAttributes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { StoreState } from 'types/storeTypes';

import { getWorkflowStepRights } from '../WorkflowForm.utils';

import { WorkflowFormStepReminderTemplate } from './WorkflowFormStepReminderTemplate';

import styleIdentifiers from './WorkflowFormStep.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepReminderProps = {
  planType: PlanType;
  availableTemplates: StepType[];
  step?: Partial<WorkflowStepsAttributes>;
};

export const WorkflowFormStepReminder = ({
  planType,
  availableTemplates,
  step,
  children,
}: PropsWithChildren<WorkflowFormStepReminderProps>) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const {
    sending_medium = [],
    recovery_step_types: { recovery_plan_steps: templateDescVal = [] },
  } = useSelector((state: StoreState) => state.app.constants);

  const { canAddReminder, canEditReminder } = getWorkflowStepRights(company, planType);

  const form = useFormContext<WorkflowStepsAttributes>();

  const [show, setShow] = useState<StepType | undefined>(step?.step_type);

  const templatesTypes = [step?.step_type, ...availableTemplates].filter((e) => e);

  const {
    watch,
    register,
    setValue,
    formState: { errors },
  } = form;

  register('template', { required: true });
  register('step_type', { required: true });

  const template = watch('template');
  const sending_media = watch('sending_media') || [];
  const stepType = watch('step_type');

  const addMedium = () => {
    const defaultSendingMedium = (sending_medium.filter(
      (e) => !sending_media.some((el) => e.value === el.name),
    ) || sending_medium)[0];

    if (sending_media.length < sending_medium.length) {
      setValue(
        'sending_media',
        [
          ...sending_media,
          {
            name: defaultSendingMedium.value,
            order: sending_media.length + 1,
            force_sending: 'true',
          },
        ],
        {
          shouldTouch: true,
        },
      );
    }
  };

  useEffect(() => {
    setShow(stepType);
  }, [stepType]);

  const onClickTemplate = (selectedTemplate?: Template) => {
    setValue('template', selectedTemplate);
    setValue('step_type', selectedTemplate?.attributes?.step_type as any);
  };

  const isFormDisabled = step ? !canEditReminder : !canAddReminder;

  return (
    <>
      <div className={styles('head')}>
        {t(step ? i18nKeys.EDIT_REMINDER : i18nKeys.ADD_REMINDER)}
      </div>
      <div className={styles('content')}>
        {isFormDisabled && <p className={styles('not-included')}>{t(i18nKeys.NOT_INCLUDED)}</p>}
        <FormSection title={t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}>
          <SendingMedia fieldName="sending_media" className={styles('input')} />
          {sending_media.length < sending_medium.length && (
            <Button
              color={ButtonColor.GREY}
              label={t(i18nKeys.ACTIONS.ADD)}
              iconRight={IconName.PLUS}
              onClick={addMedium}
              disabled={isFormDisabled}
              small
            />
          )}
        </FormSection>
        {children}
        <FormSection title={t(i18nKeys.COMMON.TEMPLATE)} className={styles('form-section')}>
          <div className={styles('actions')}>
            {get(errors, 'template') && (
              <div className={styles('error-message', 'error')}>
                {t(i18nKeys.PLAN.SELECT_TEMPLATE)}
              </div>
            )}
            <div>
              {templateDescVal
                .filter((e) => templatesTypes.includes(e.value as StepType))
                .map(({ description, value: templateType }) => (
                  <div
                    key={`template-${templateType}`}
                    onClick={() => setShow(templateType as StepType)}
                    className={styles(
                      'action',
                      show && 'show-list',
                      show !== templateType && 'hide',
                      template && template.attributes.step_type === templateType && 'selected',
                    )}
                  >
                    <div className={styles('template-name')}>
                      <Icon name={IconName.SEND} />
                      <span>{description}</span>
                      {show === templateType && (
                        <Icon
                          name={IconName.SMALL_REMOVE}
                          className={styles('remove-icon')}
                          onClick={() => onClickTemplate()}
                        />
                      )}
                    </div>
                    {show === templateType && (
                      <WorkflowFormStepReminderTemplate
                        disabled={isFormDisabled}
                        params={{
                          step_type: templateType,
                          plan_type: planType,
                        }}
                        onClick={(selectedTemplate?: Template) => onClickTemplate(selectedTemplate)}
                      />
                    )}
                  </div>
                ))}
            </div>
          </div>
        </FormSection>
      </div>
    </>
  );
};

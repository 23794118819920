import React, { HTMLAttributes } from 'react';
import classNames from 'classnames/bind';

import { IconColor, IconName } from './Icon.constants';

import styleIdentifiers from './Icon.module.scss';

const styles = classNames.bind(styleIdentifiers);

type IconProps = HTMLAttributes<HTMLSpanElement> & {
  name: IconName;
  size?: string;
  color?: IconColor;
  title?: string;
  className?: string;
  boldHover?: boolean;
  style?: React.CSSProperties;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
  onMouseDown?: React.MouseEventHandler<HTMLSpanElement>;
  rotate?: boolean;
  absolute?: boolean;
};

export const Icon = ({
  name,
  size,
  color,
  title,
  className,
  boldHover,
  style: addStyle,
  onClick,
  onMouseDown,
  rotate,
  absolute,
}: IconProps) => (
  <span
    title={title}
    className={styles(
      'icon',
      `icon-${name}`,
      color && `${color}`,
      boldHover && 'bold-on-hover',
      onClick && 'cursor-pointer',
      rotate && 'rotate',
      absolute && 'absolute',
      className,
    )}
    onClick={onClick}
    onMouseDown={onMouseDown}
    style={{
      ...addStyle,
      fontSize: size,
    }}
  />
);

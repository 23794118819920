import { ModelType, VarType } from 'app/Private/CustomVariables/CustomVariables.types';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { CustomSelect, DateSelector, Input } from 'shared/io';

import styleIdentifiers from '../clientForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ClientCustomVariableForm() {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const availableCustomVariables = company.custom_variables_attributes?.filter(
    (el) => el.model_type === ModelType.DEBTOR,
  );
  const { register, setValue } = useFormContext();

  const accurateInput = (customVarKey, varType) => {
    switch (varType) {
      case VarType.BOOLEAN:
        return (
          <Controller
            defaultValue=""
            name={`custom_variables.${customVarKey}`}
            render={() => (
              <CustomSelect
                selectClassName={styles('input')}
                keyText="description"
                keyValue="value"
                items={[
                  {
                    description: t(i18nKeys.YES),
                    value: 'true',
                  },
                  {
                    description: t(i18nKeys.NO),
                    value: 'false',
                  },
                ]}
                withBorder
                name={`custom_variables.${customVarKey}`}
                label={customVarKey.replaceAll('_', ' ')}
              />
            )}
          />
        );
      case VarType.DATE:
        return (
          <DateSelector
            name={`custom_variables.${customVarKey}`}
            className={styles('input')}
            noMinDate
            withBorder
            placeholder=" "
            label={customVarKey.replaceAll('_', ' ')}
            handleChange={(value) => {
              setValue(`custom_variables.${customVarKey}`, value);
            }}
          />
        );
      default:
        return (
          <Input
            register={register(`custom_variables.${customVarKey}`)}
            withBorder
            label={customVarKey.replaceAll('_', ' ')}
            className={styles('input')}
            type={varType === VarType.NUMBER ? 'number' : 'text'}
            step="0.01"
            noMargin
          />
        );
    }
  };

  const renderInput = (customVar) => {
    const varType = customVar.var_type;
    return (
      <div key={customVar.column_name} className={styles('col-12')}>
        {accurateInput(customVar.column_name, varType)}
      </div>
    );
  };

  return (
    <>
      {availableCustomVariables!
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((customVar) => renderInput(customVar))}
    </>
  );
}

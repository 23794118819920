import React, { useState } from 'react';
import { i18nKeys, useTranslation } from 'locales/index';
import { isEmpty } from 'remeda';

import { FileButton, Group, Pill, Stack, Text } from '@mantine/core';
import { Link, RichTextEditor } from '@mantine/tiptap';
import { IconPaperclip } from '@tabler/icons-react';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';

type RichTextAreaProps = {
  content?: string;
  onChange: (content: string) => void;
  style?: React.CSSProperties;
  onFileChange?: (file: File[]) => void;
};

export const RichTextArea = ({ content, onChange, onFileChange, ...props }: RichTextAreaProps) => {
  const { t } = useTranslation();

  const [files, setFiles] = useState<File[]>([]);

  const editorInstance = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
    ],
    content,
    onUpdate({ editor }) {
      onChange(editor.getHTML());
    },
  });

  const handleRemoveFile = (index: number) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    onFileChange?.(updatedFiles);
    setFiles(updatedFiles);
  };

  const handleFileChange = (newFiles: File[]) => {
    const updatedFiles = [...files, ...newFiles];
    onFileChange?.(updatedFiles);
    setFiles(updatedFiles);
  };

  return (
    <RichTextEditor editor={editorInstance} {...props}>
      <RichTextEditor.Toolbar sticky stickyOffset={60}>
        <Stack>
          <Group>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Control
                bg={isEmpty(files) ? '' : 'blue.1'} // TODO: @Thib branding L64 & 69
                aria-label={t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS_LABEL)}
                title={t(i18nKeys.EXTERNAL_MAIL.ATTACHMENTS_LABEL)}
              >
                <FileButton onChange={handleFileChange} accept="image/*,.pdf" multiple>
                  {(fileButtonProps) => (
                    <IconPaperclip stroke={1.5} size={16} {...fileButtonProps} />
                  )}
                </FileButton>
              </RichTextEditor.Control>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Undo />
              <RichTextEditor.Redo />
            </RichTextEditor.ControlsGroup>
          </Group>

          {!isEmpty(files) && (
            <Group>
              <Pill.Group>
                {files.map((file, index) => (
                  <Pill key={index} onRemove={() => handleRemoveFile(index)} withRemoveButton>
                    {file.name}
                  </Pill>
                ))}
              </Pill.Group>
            </Group>
          )}
        </Stack>
      </RichTextEditor.Toolbar>
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};

import { queryClientV5 } from 'init/Providers';

export type IntegrationFormProps<T extends any> = {
  onSubmit: (values: T) => void;
  initialValues?: T;
  onboarding?: boolean;
};

export enum IntegrationFormFieldsName {
  ISSUE_DATE_LIMIT = 'tp_accounting_issue_date_limit',
  DEBTOR_UPDATE = 'tp_accounting_debtor_update',
  AUTOMATIC_SYNC = 'tp_accounting_automatic_sync',
}

export enum DigitealIntegrationStatus {
  ACTIVATED = 'activated',
  IN_PROCESS = 'in_process',
  ACTIVABLE = 'activable',
}

export const INTEGRATION_UPDATE_CALLBACK = () => {
  queryClientV5.invalidateQueries({ queryKey: ['company-configuration'] });
};

import { i18nKeys, TranslateFunction } from 'locales';
import { CompanyConfiguration } from 'shared/hooks';

import { Image } from '@mantine/core';

import { IconName } from '../Icon';

import { getIntegrationRights, isEnabledIntegration } from './components/Integration.utils';
import ChiftLogo from './images/chift.png';
import CompanyWebLogo from './images/companyweb.png';
import DBasicsLogo from './images/dbasics.png';
import { Integration, IntegrationCard, IntegrationCategory } from './Integrations.constants';

export const isAccountingIntegration = (integration: Integration): boolean => {
  switch (integration) {
    case Integration.YUKI:
    case Integration.HORUS:
    case Integration.TEAMLEADER:
    case Integration.EXACT:
    case Integration.BILLIT:
    case Integration.DBASICS:
      return true;
    default:
      return false;
  }
};

export const getIntegrationCard = (
  integration: Integration,
  company: CompanyConfiguration,
  t: TranslateFunction,
): IntegrationCard => {
  const title = Integration[integration];
  const base = {
    title,
    icon: IconName[integration],
    description: t(i18nKeys.SETTINGS.INTEGRATIONS[integration].DESCRIPTION),
  };

  switch (title) {
    case Integration.TEAMLEADER:
      return {
        ...base,
        category: IntegrationCategory.ERP,
        ...getIntegrationRights(title, company),
      };
    case Integration.DIGITEAL:
      return {
        ...base,
        icon: IconName.CREDIT_CARD,
        category: IntegrationCategory.BANKING,
        ...getIntegrationRights(title, company),
      };
    case Integration.CODABOX:
    case Integration.PONTO:
      return {
        ...base,
        category: IntegrationCategory.BANKING,
        ...getIntegrationRights(title, company),
      };
    case Integration.COMPANYWEB:
      return {
        ...base,
        icon: <Image src={CompanyWebLogo} style={{ filter: 'grayscale(1)' }} />,
        category: IntegrationCategory.MONITORING,
        ...getIntegrationRights(title, company),
      };
    case Integration.CHIFT:
      return {
        ...base,
        icon: <Image src={ChiftLogo} style={{ filter: 'grayscale(1)' }} />,
        category: IntegrationCategory.ACCOUNTING,
        ...getIntegrationRights(title, company),
      };
    case Integration.DBASICS:
      return {
        ...base,
        icon: <Image src={DBasicsLogo} style={{ filter: 'grayscale(1)' }} />,
        category: IntegrationCategory.ACCOUNTING,
        ...getIntegrationRights(title, company),
      };
    default:
      return {
        ...base,
        category: IntegrationCategory.ACCOUNTING,
        ...getIntegrationRights(title, company),
      };
  }
};

export const getIntegrationsCards = (
  company: CompanyConfiguration,
  t: TranslateFunction,
): IntegrationCard[] =>
  Object.keys(Integration)
    .map((key: string) => getIntegrationCard(key as Integration, company, t))
    .filter(({ title }) => isEnabledIntegration(title));

export const getIntegrationsCategories = (t: TranslateFunction) => [
  {
    text: t(i18nKeys.SETTINGS.INTEGRATIONS.ALL),
    category: IntegrationCategory.ALL,
  },
  {
    text: t(i18nKeys.SETTINGS.INTEGRATIONS.ACCOUNTING),
    category: IntegrationCategory.ACCOUNTING,
  },
  {
    text: t(i18nKeys.SETTINGS.INTEGRATIONS.ERP),
    category: IntegrationCategory.ERP,
  },
  {
    text: t(i18nKeys.SETTINGS.INTEGRATIONS.BANKING),
    category: IntegrationCategory.BANKING,
  },
  {
    text: t(i18nKeys.DEBTOR.MONITORING.TAB_TITLE),
    category: IntegrationCategory.MONITORING,
  },
  {
    text: t(i18nKeys.SETTINGS.INTEGRATIONS.MY_INTEGRATIONS),
    category: IntegrationCategory.MY_INTEGRATIONS,
  },
];

import { useEffect, useState } from 'react';
import { TasksList } from 'app/Private/Tasks/TasksList/TasksList';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Monitoring } from 'modules/Debtor/monitoring';
import { useLocation } from 'react-router';
import { isEmpty, isNonNull, isNullish } from 'remeda';
import Card from 'shared/components/Card';
import Tabs from 'shared/components/Tabs';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { TabInfo } from './TabInfo/TabInfo';
import { ThirdPartyCaseTable } from './ThirdpartyCaseTable/ThirdpartyCaseTable';
import { BalanceTab } from './BalanceTab';
import { ClientDetailTabProps } from './ClientDetail.types';
import { ClientDetailTabsTimeline } from './ClientDetailTabsTimeline';

import styleIdentifiers from './ClientDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const ClientDetailTabs = ({ debtor, ...rest }: ClientDetailTabProps) => {
  const location = useLocation();

  const tabItemProps: ClientDetailTabProps = { debtor, ...rest };
  const tabItems = useBuildTabItems(tabItemProps);

  const [activeSlug, setActiveSlug] = useState(getSlugFromHash(location.hash) ?? tabItems[0].slug);

  useEffect(() => {
    const newTabSlug = getSlugFromHash(location.hash);

    if (isNullish(newTabSlug)) return;
    setActiveSlug(newTabSlug);
  }, [location.hash]);

  const handleTabChange = (index) => {
    const newSlug = tabItems[index].slug;
    setActiveSlug(newSlug);
    window.history.replaceState(null, '', `#${newSlug}`);
  };

  return (
    <>
      <Tabs
        className={styles('workflows__tabs')}
        tabIndex={tabItems.findIndex((item) => item.slug === activeSlug)}
        items={tabItems.map((e) => e.title)}
        noBody
        onChange={handleTabChange}
      />
      {tabItems.map(
        ({ title, children, slug }) =>
          activeSlug === slug && (
            <Card
              key={`tab-${title}`}
              noRadiusTop
              noPadding={slug.includes('/cases')}
              noHead
              noBorder
            >
              {children}
            </Card>
          ),
      )}
    </>
  );
};

function getSlugFromHash(hash: string) {
  if (isEmpty(hash)) return null;
  return hash.replace('#', '');
}

function useBuildTabItems(tabItemProps: ClientDetailTabProps) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const { debtor } = tabItemProps;

  const tabItems = [
    {
      title: <h3>{t(i18nKeys.CLIENT.GENERAL_INFO)}</h3>,
      slug: 'general-info',
      children: <TabInfo {...tabItemProps} />,
    },
    {
      title: <h3>{t(i18nKeys.DASHBOARD.BALANCE_DETAIL)}</h3>,
      slug: 'balance-detail',
      children: <BalanceTab {...tabItemProps} />,
    },
    {
      title: <h3>{t(i18nKeys.INVOICE.ACTIVITIES)}</h3>,
      slug: 'timeline',
      children: <ClientDetailTabsTimeline {...tabItemProps} />,
    },
  ];

  const isTasksModuleEnabledInPackage = company.package.can_use_tasks ?? false;
  if (isTasksModuleEnabledInPackage) {
    tabItems.push({
      title: <h3>{t(i18nKeys.NAV.TASKS)}</h3>,
      slug: 'tasks',
      children: <TasksList {...tabItemProps} />,
    });
  }

  const thirdPartyCaseCount = debtor.attributes.tp_cases_count ?? 0;
  if (thirdPartyCaseCount > 0) {
    tabItems.push({
      title: <h3>{t(i18nKeys.THIRD_PARTY_CASES.CURRENT_CASES)}</h3>,
      slug: 'thirdparty-case-table',
      children: <ThirdPartyCaseTable debtorId={debtor.id} />,
    });
  }

  const vatNumber = debtor.attributes.vat_number;
  tabItems.push({
    title: <h3>{t(i18nKeys.DEBTOR.MONITORING.TAB_TITLE)}</h3>,
    slug: 'monitoring',
    children: <Monitoring hasVatNumber={isNonNull(vatNumber) && !isEmpty(vatNumber)} />,
  });

  return tabItems;
}

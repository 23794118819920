import { useLayoutEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import AYSModal from 'shared/components/AYSModal';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Checkbox } from 'shared/io';
import { accountActions } from 'store/account/account.actions';
import { config } from 'store/constants';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { Integration } from '../../Integrations.constants';
import { DigitealIntegrationStatus, INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum DigitealFormFieldsName {
  ACCEPT_CGV = 'accept_cgv',
  ACCEPT_DIGITEAL_CGV = 'accept_digiteal_cgv',
}

export type DigitealFormFields = {
  [DigitealFormFieldsName.ACCEPT_CGV]: boolean;
  [DigitealFormFieldsName.ACCEPT_DIGITEAL_CGV]: boolean;
};

type DigitealFormProps = { onSubmit: () => void };

export const DigitealForm = ({ onSubmit }: DigitealFormProps) => {
  const { t, currentLang } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const [acceptCGV, setacceptCGV] = useState<boolean>();
  const [acceptDigitealCGV, setaccepDigitealtCGV] = useState<boolean>();

  const [digitealCGV, setDigitealCGV] = useState<any[]>();

  useLayoutEffect(() => {
    accountActions.getDigitealCGV({
      callback: setDigitealCGV,
    });
  }, []);

  const { isActive } = getIntegrationRights(Integration.DIGITEAL, company);

  const {
    digiteal_integration: { status },
  } = company;

  if (status === DigitealIntegrationStatus.IN_PROCESS) {
    return (
      <div className={styles('integration-form')}>
        <div>{t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)}</div>
        <div className={styles('in-process-info')}>
          <Icon name={IconName.INFO} />{' '}
          {t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.SIGNUP_TO_CONTINUE)}
        </div>
      </div>
    );
  }

  const setAvailability = () => {
    accountActions.setDigitealConfiguration({
      data: {
        use_digiteal_integration: !isActive,
      },
      callback: INTEGRATION_UPDATE_CALLBACK,
    });
  };

  if (status === DigitealIntegrationStatus.ACTIVATED) {
    return (
      <div className={styles('integration-form')}>
        <div>{t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)}</div>
        <div className={styles('button-wrapper')}>
          <Button
            onClick={setAvailability}
            label={t(
              isActive
                ? i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_UNAVAILABLE
                : i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.MAKE_AVAILABLE,
            )}
          />
        </div>
      </div>
    );
  }

  const submit = () => {
    if (acceptCGV && acceptDigitealCGV) {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.CONFIRMATION),
        children: (
          <AYSModal
            onConfirm={onSubmit}
            text={
              <>
                <p>{t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.AYS_TO_ACTIVATE)}</p>
                <p>{t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.EMAIL_TO_ACTIVATE)}</p>
              </>
            }
          />
        ),
      });
    } else {
      if (!acceptCGV) {
        setacceptCGV(false);
      }
      if (!acceptDigitealCGV) {
        setaccepDigitealtCGV(false);
      }
    }
  };

  return (
    <div className={styles('integration-form')}>
      <div>{t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.DESCRIPTION)}</div>
      <div>
        <Checkbox
          name={DigitealFormFieldsName.ACCEPT_CGV}
          checked={acceptCGV}
          onChange={(value: boolean) => setacceptCGV(value)}
          errorMessage={acceptCGV === false && i18nKeys.ERROR.FIELD_REQUIRED}
          label={
            <HTML
              className={styles('checkbox-label')}
              html={t(i18nKeys.SETTINGS.PACKAGE.ACCEPT_CGV, {
                link: config.terms[currentLang],
              })}
            />
          }
        />
        <Checkbox
          name={DigitealFormFieldsName.ACCEPT_DIGITEAL_CGV}
          checked={acceptDigitealCGV}
          onChange={(value: boolean) => setaccepDigitealtCGV(value)}
          errorMessage={acceptDigitealCGV === false && i18nKeys.ERROR.FIELD_REQUIRED}
          label={
            digitealCGV && (
              <HTML
                html={t(i18nKeys.SETTINGS.INTEGRATIONS.DIGITEAL.ACCEPT_CGV, {
                  link1: `<a href="${digitealCGV[0].url}" target="_blank">${digitealCGV[0].name}</a>`,
                  link2: `<a href="${digitealCGV[1].url}" target="_blank">${digitealCGV[1].name}</a>`,
                  link3: `<a href="${digitealCGV[2].url}" target="_blank">${digitealCGV[2].name}</a>`,
                })}
              />
            )
          }
        />
      </div>
      <div className={styles('button-wrapper')}>
        <Button onClick={submit} label={t(i18nKeys.ACTIVATE)} />
      </div>
    </div>
  );
};

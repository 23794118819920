import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useForm } from 'react-hook-form';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { DateSelector, Input, MaskInput, SwitchInput } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { DialogShowId, showDialog } from 'store/view/view.actions';

import { Integration } from '../../Integrations.constants';
import { IntegrationFormFieldsName, IntegrationFormProps } from '../Integration.constants';
import { isMaskFilled } from '../Integration.utils';
import { IntegrationForm } from '../IntegrationForm';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum YukiFormFieldsName {
  EXPORT_INTEGRATION = 'use_yuki_export_integration',
  IMPORT_INTEGRATION = 'use_yuki_import_integration',
  PAYMENT_EXPORT = 'use_yuki_payment_export',
  PAYMENT_MATCHING = 'use_yuki_payment_matching',
  ADMIN_KEY = 'yuki_admin_key',
  API_KEY = 'yuki_api_key',
  CASH_ACCOUNT = 'yuki_cash_account',
}

export type YukiFormFields = {
  [YukiFormFieldsName.EXPORT_INTEGRATION]: boolean;
  [YukiFormFieldsName.IMPORT_INTEGRATION]: boolean;
  [YukiFormFieldsName.PAYMENT_EXPORT]: boolean;
  [YukiFormFieldsName.PAYMENT_MATCHING]: boolean;
  [YukiFormFieldsName.ADMIN_KEY]?: string;
  [YukiFormFieldsName.API_KEY]?: string;
  [YukiFormFieldsName.CASH_ACCOUNT]?: string;
  [IntegrationFormFieldsName.AUTOMATIC_SYNC]: boolean;
  [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: Date | null;
  [IntegrationFormFieldsName.DEBTOR_UPDATE]: boolean;
};

export type YukiFormProps = IntegrationFormProps<YukiFormFields>;

export const YukiForm = ({ onSubmit, initialValues, onboarding }: YukiFormProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const [preventSubmit, setPreventSubmit] = useState<boolean>(false);

  const form = useForm<YukiFormFields>({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = form;

  const paymentExport = watch(YukiFormFieldsName.PAYMENT_EXPORT);
  const importIntegration = watch(YukiFormFieldsName.IMPORT_INTEGRATION);
  const debtorUpdate = watch(IntegrationFormFieldsName.DEBTOR_UPDATE);

  useEffect(() => {
    if (importIntegration) {
      setValue(
        IntegrationFormFieldsName.ISSUE_DATE_LIMIT,
        initialValues && initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          ? initialValues[IntegrationFormFieldsName.ISSUE_DATE_LIMIT]
          : new Date(),
      );
    }
  }, [importIntegration, initialValues, setValue]);

  const submit = (values: YukiFormFields) => {
    if (preventSubmit) {
      showDialog({
        id: DialogShowId.CONFIRM,
        title: t(i18nKeys.CONFIRM),
        children: (
          <AYSModal
            text={t(i18nKeys.FORM.INTEGRATION.AYS_TO_CHANGE_DATE)}
            onConfirm={() => {
              setPreventSubmit(false);
              onSubmit(values);
            }}
          />
        ),
      });
    } else {
      onSubmit(values);
    }
  };

  const {
    tp_accounting: {
      additional_parameters: { api_key, admin_key },
    },
  } = company;

  return (
    <IntegrationForm<YukiFormFields>
      integration={Integration.YUKI}
      onSubmit={handleSubmit(submit)}
      summary={i18nKeys.FORM.YUKI.SUMMARY}
      form={form}
    >
      <div className={styles('grid-row')}>
        <div className={styles('col-12')}>
          <Input
            defaultValue=""
            register={register(YukiFormFieldsName.API_KEY)}
            label={t(i18nKeys.FORM.YUKI.API_KEY)}
            type="text"
            placeholder={api_key ? `*****${api_key}` : undefined}
          />
        </div>
        <div className={styles('col-12')}>
          <Input
            defaultValue=""
            register={register(YukiFormFieldsName.ADMIN_KEY)}
            label={t(i18nKeys.FORM.YUKI.ADMIN_KEY)}
            type="text"
            placeholder={admin_key ? `*****${admin_key}` : undefined}
          />
        </div>
        <div>
          <Controller
            control={control}
            name={YukiFormFieldsName.IMPORT_INTEGRATION}
            render={({ field: { ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.IMPORT, {
                  text: Integration.YUKI,
                })}
              />
            )}
          />
          {importIntegration && (
            <>
              <div className={styles('col-12')}>
                <DateSelector
                  className={styles('issue-date')}
                  noMinDate
                  label={t(i18nKeys.FORM.INTEGRATION.INVOICE_ISSUE_DATE_TO_IMPORT)}
                  placeholder={t(i18nKeys.ISSUE_DATE)}
                  endPlaceholder={t(i18nKeys.FORM.TO)}
                  name={IntegrationFormFieldsName.ISSUE_DATE_LIMIT}
                  required
                  handleChange={(date: Date) => {
                    if (!onboarding) {
                      setPreventSubmit(true);
                    }
                    setValue(IntegrationFormFieldsName.ISSUE_DATE_LIMIT, date);
                  }}
                />
              </div>
              <div className={styles('col-12')}>
                <Controller
                  control={control}
                  name={IntegrationFormFieldsName.DEBTOR_UPDATE}
                  render={({ field: { ...values } }) => (
                    <SwitchInput
                      {...values}
                      inversed
                      className={styles('switch-button', 'issue-date')}
                      label={t(
                        debtorUpdate
                          ? i18nKeys.FORM.INTEGRATION.PRIORITIZE_INTEGRATION
                          : i18nKeys.FORM.INTEGRATION.PRIORITIZE_RECOVR,
                      )}
                    />
                  )}
                />
              </div>
            </>
          )}
          <Controller
            control={control}
            name={YukiFormFieldsName.EXPORT_INTEGRATION}
            render={({ field: { ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.INTEGRATION.EXPORT, {
                  text: Integration.YUKI,
                })}
              />
            )}
          />
          <Controller
            control={control}
            name={YukiFormFieldsName.PAYMENT_MATCHING}
            render={({ field: { ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={
                  <span>
                    {t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                      text: Integration.YUKI,
                    })}
                    <Tooltip
                      icon={IconName.INFO}
                      iconClassName={styles('icon')}
                      direction={DropdownDirection.TOP}
                    >
                      {t(i18nKeys.FORM.YUKI.BASED_ON)}
                    </Tooltip>
                  </span>
                }
              />
            )}
          />
          <Controller
            control={control}
            name={YukiFormFieldsName.PAYMENT_EXPORT}
            render={({ field: { ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                className={styles('switch-button')}
                label={t(i18nKeys.FORM.EXPORT_RECOVR_PAYMENTS, {
                  text: Integration.YUKI,
                })}
              />
            )}
          />
          {paymentExport && (
            <div>
              <Controller
                control={control}
                name={YukiFormFieldsName.CASH_ACCOUNT}
                rules={{ validate: { maskFilled: isMaskFilled } }}
                render={({ field: { value, ...values } }) => (
                  <MaskInput
                    {...values}
                    value={value || ''}
                    error={errors[YukiFormFieldsName.CASH_ACCOUNT]}
                    className={styles('cash_account_input')}
                    label={t(i18nKeys.FORM.YUKI.CASH_ACCOUNT)}
                    length={3}
                    guide
                    numberOnly
                    prefix="570"
                  />
                )}
              />
            </div>
          )}
          <Controller
            control={control}
            name={IntegrationFormFieldsName.AUTOMATIC_SYNC}
            render={({ field: { ...values } }) => (
              <SwitchInput
                {...values}
                inversed
                label={t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}
                className={styles('switch-button')}
              />
            )}
          />
        </div>
      </div>
    </IntegrationForm>
  );
};

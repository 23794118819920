import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { isDefined } from 'remeda';
import { getInvoiceFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { Invoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

export default function ManageInvoice({ match: { params } }: RouteComponentProps<{ id?: string }>) {
  // The store is optimistically typed as having its data always present, we need to tell it it can be undefined
  const invoice = useSelector((state: StoreState) => state.invoice.current?.data) as
    | Invoice
    | undefined;

  const isEditMode = isDefined(params.id);
  const isCreateMode = !isEditMode;

  useEffect(() => {
    if (isEditMode) invoiceActions.detail({ id: Number(params.id) });
  }, []);

  if (isCreateMode) {
    return <InvoiceForm submit={(data) => invoiceActions.add({ data })} />;
  }

  if (!isDefined(invoice)) return null;

  return (
    <InvoiceForm
      initialValues={getInvoiceFormValues(invoice)}
      invoice={invoice}
      submit={(data) =>
        invoiceActions.update({
          id: invoice.id,
          data,
        })
      }
    />
  );
}

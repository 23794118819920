import { SendingMediaNames, SendingMedium } from 'api/models';
import { i18nKeys, TranslateFunction } from 'locales';
import { CompanyConfiguration } from 'shared/hooks';

export const hasSendingMedium = (
  mediums: CompanyConfiguration['sending_media_attributes'],
  name: SendingMediaNames,
): boolean => mediums.some((e) => e.name === name);

export const getText = (name: string, t: TranslateFunction, toLowerCase = true) => {
  if (!name) {
    return '';
  }
  const text = t(i18nKeys.WILL_BE_SENT[name.toUpperCase()]) || '';
  return toLowerCase && text ? text.toLowerCase() : text;
};

export const getDescription = (
  values: CompanyConfiguration['sending_media_attributes'],
  t: TranslateFunction,
) =>
  values.reduce(
    (acc, el, i) =>
      i === 0 ? getText(el.name, t, false) : `${acc} ${t(i18nKeys.AND)} ${getText(el.name, t)}`,
    '',
  );

import { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button } from 'shared/io';
import { accountActions } from 'store/account/account.actions';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { YukiForm, YukiFormFields, YukiFormFieldsName } from './YukiForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Yuki = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const [isEditting, setIsEditting] = useState(false);

  const { isActive, canActivate, isConnected } = getIntegrationRights(Integration.YUKI, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  const handleSubmit = (data: YukiFormFields) => {
    accountActions.updateYukiSettings({
      data,
      callback: () => {
        INTEGRATION_UPDATE_CALLBACK();
        setIsEditting(false);
      },
    });
  };

  if (!company.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const {
    tp_accounting: {
      additional_parameters,
      automatic_sync,
      issue_date_limit,
      use_export,
      use_import,
      debtor_update,
      use_payment_export,
      use_payment_matching,
    },
  } = company;

  return (
    <article className={styles('yuki')}>
      <IntegrationHeader
        name={Integration.YUKI}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />

      {!isEditting && isActive ? (
        <>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>{t(i18nKeys.FORM.YUKI.API_KEY)}:</div>
            <div className={styles('value')}>*****{additional_parameters?.api_key}</div>
          </div>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>{t(i18nKeys.FORM.YUKI.ADMIN_KEY)}:</div>
            <div className={styles('value')}>*****{additional_parameters?.admin_key}</div>
          </div>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>{t(i18nKeys.FORM.YUKI.IMPORT_ON_RECOVR)}:</div>
            <div className={styles('value')}>{t(use_import ? i18nKeys.YES : i18nKeys.NO)}</div>
          </div>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>{t(i18nKeys.FORM.YUKI.EXPORT_ON_YUKI)}:</div>
            <div className={styles('value')}>{t(use_export ? i18nKeys.YES : i18nKeys.NO)}</div>
          </div>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>
              {t(i18nKeys.FORM.USE_PAYMENT_MATCHING, {
                text: Integration.YUKI,
              })}
              :
            </div>
            <div className={styles('value')}>
              {t(use_payment_matching ? i18nKeys.YES : i18nKeys.NO)}
            </div>
          </div>
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>
              {t(i18nKeys.FORM.EXPORT_RECOVR_PAYMENTS, {
                text: Integration.YUKI,
              })}
              :
            </div>
            <div className={styles('value')}>
              {t(use_payment_export ? i18nKeys.YES : i18nKeys.NO)}
            </div>
          </div>
          {use_payment_export && (
            <div className={styles('key-text')}>
              <div className={styles('attribute')}>{t(i18nKeys.FORM.YUKI.CASH_ACCOUNT)}:</div>
              <div className={styles('value')}>{additional_parameters?.cash_account}</div>
            </div>
          )}
          <div className={styles('key-text')}>
            <div className={styles('attribute')}>
              {t(i18nKeys.FORM.INTEGRATION.AUTOMATIC_SYNC)}:
            </div>
            <div className={styles('value')}>{t(automatic_sync ? i18nKeys.YES : i18nKeys.NO)}</div>
          </div>
          <div className={styles('button-wrapper')}>
            <Button label={t(i18nKeys.EDIT)} onClick={() => setIsEditting((current) => !current)} />
          </div>
        </>
      ) : (
        <YukiForm
          onSubmit={handleSubmit}
          initialValues={{
            [YukiFormFieldsName.EXPORT_INTEGRATION]: use_export,
            [YukiFormFieldsName.IMPORT_INTEGRATION]: use_import,
            [YukiFormFieldsName.PAYMENT_EXPORT]: use_payment_export,
            [YukiFormFieldsName.PAYMENT_MATCHING]: use_payment_matching,
            [YukiFormFieldsName.CASH_ACCOUNT]: additional_parameters?.cash_account,
            [IntegrationFormFieldsName.DEBTOR_UPDATE]: debtor_update,
            [IntegrationFormFieldsName.AUTOMATIC_SYNC]: automatic_sync,
            [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: issue_date_limit,
          }}
        />
      )}
    </article>
  );
};

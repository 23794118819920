import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, CustomSelect, DateSelector, Input, Slider, Textarea } from 'shared/io';
import { currencySymbol } from 'shared/utils/normalization';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './encodePaymentForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  currency: string;
  totalAmount: number;
  amountWithoutFees?: number;
};

export default function EncodePaymentForm({
  onSubmit,
  currency,
  totalAmount,
  amountWithoutFees,
}: Props) {
  const { t } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const { company } = useLoadCompanyConfiguration();
  const form = useForm();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    unregister,
    watch,
  } = form;

  useEffect(() => {
    register('received_at', { required: true });
    return () => {
      unregister('received_at');
    };
  }, []);

  useEffect(() => {
    setValue('amount', 0);
  }, []);

  const formatPercent = (value) => `${value}%`;

  const changeRangeAmount = (value) => {
    setValue('amount', (Math.round(((totalAmount * value) / 100) * 100) / 100).toFixed(2));
  };

  const changeAmount = (value) => {
    setValue('amount', Number(value).toFixed(2));
  };

  const amount = watch('amount');

  return (
    <FormProvider {...form}>
      <form className={styles('EncodePaymentForm')} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles('head')}>{t(i18nKeys.SHARED.ENCODE_PAYMENT.TITLE)}</div>
        <div className={styles('content')}>
          <div className={styles('grid-row')}>
            <div className={styles('col-12')}>
              <div className={styles('with-border-input')}>
                <div className={styles('label-input')}>
                  {t(i18nKeys.AMOUNT_CURRENCY, {
                    text: currencySymbol(currency, company),
                  })}
                </div>
                <div className={styles('slider-input')}>
                  <Slider
                    currentValue={(+amount / totalAmount) * 100}
                    maxVal={100}
                    step={5}
                    renderValue={formatPercent}
                    onSelect={changeRangeAmount}
                  />
                  <Input
                    style={{ marginLeft: '10px' }}
                    register={register('amount', { required: true })}
                    type="number"
                    min="0"
                    max={totalAmount}
                    required
                    step="0.01"
                    noMargin
                    placeholder={currencySymbol(currency, company)}
                  />
                </div>
                {errors.amount && 'ERROR.FIELD_REQUIRED'}
                <div className={styles('buttons-container')}>
                  {(amountWithoutFees && amountWithoutFees != totalAmount && (
                    <div
                      className={styles('label-action', 'blue')}
                      onClick={() => {
                        changeAmount(amountWithoutFees);
                      }}
                    >
                      Total sans frais de retard
                    </div>
                  )) ||
                    null}
                  <div
                    className={styles('label-action')}
                    onClick={() => {
                      changeAmount(totalAmount);
                    }}
                  >
                    Total
                  </div>
                </div>
              </div>
            </div>
            <div className={styles('col-6')}>
              <Controller
                defaultValue=""
                rules={{ required: true }}
                name="received_at"
                render={() => (
                  <DateSelector
                    name="received_at"
                    handleChange={(value) =>
                      setValue('received_at', value, { shouldValidate: true })
                    }
                    label={t(i18nKeys.FORM.PAYMENT_DATE)}
                    noMinDate
                    withBorder
                  />
                )}
              />
            </div>
            <div className={styles('col-6')}>
              <Controller
                rules={{ required: true }}
                name="modality"
                defaultValue=""
                render={() => (
                  <CustomSelect
                    name="modality"
                    keyText="description"
                    keyValue="value"
                    label={t(i18nKeys.MODALITY)}
                    withBorder
                    items={constants.payment_modalities}
                  />
                )}
              />
            </div>
            <div className={styles('col-12')}>
              <Textarea register={register('notes')} withBorder label={t(i18nKeys.NOTES)} />
            </div>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button noShadow noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}

import { PropsWithChildren } from 'react';
import { DueDateDelay, PlanType, WorkflowStepsAttributes } from 'api/models';
import { TaskFormColleague, TaskFormType } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { Controller, useFormContext } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { CustomSelect, Input, Textarea } from 'shared/io';

import { getWorkflowStepRights } from '../WorkflowForm.utils';

import styleIdentifiers from './WorkflowFormStep.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepTaskProps = {
  planType: PlanType;
  step?: Partial<WorkflowStepsAttributes>;
};

export const WorkflowFormStepTask = ({
  planType,
  step,
  children,
}: PropsWithChildren<WorkflowFormStepTaskProps>) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { canAddTask, canEditTask } = getWorkflowStepRights(company, planType);

  const form = useFormContext<WorkflowStepsAttributes>();

  const {
    register,
    formState: { errors },
  } = form;

  register('step_type', { required: true, value: 'task_generation' });

  const dueDateDescription = (days: number) => {
    switch (days) {
      case 0:
        return i18nKeys.CREATION_DATE;
      case 999:
        return i18nKeys.NEXT_STEP_DATE;
      default:
        return i18nKeys.N_DAYS_AFTER_CREATION;
    }
  };

  const dueDateDelays = Object.values(DueDateDelay).map((e) => ({
    description: t(dueDateDescription(e), { days: e }),
    value: e.toString(),
  }));

  const isFormDisabled = step ? !canEditTask : !canAddTask;

  return (
    <>
      <div className={styles('head')}>{t(step ? i18nKeys.EDIT_TASK : i18nKeys.ADD_TASK)}</div>
      <div className={styles('content')}>
        {isFormDisabled && <p className={styles('not-included')}>{t(i18nKeys.NOT_INCLUDED)}</p>}
        {children}
        <Input
          register={register('task_attributes.title', {
            required: true,
          })}
          errorMessage={get(errors, 'task_attributes.title')}
          label={t(i18nKeys.TASK.ATTRIBUTES.TITLE)}
          disabled={isFormDisabled}
          withBorder
        />
        <Textarea
          register={register('task_attributes.description')}
          className={styles('input')}
          label={t(i18nKeys.DESCRIPTION)}
          disabled={isFormDisabled}
          withBorder
        />
        <TaskFormType name="task_attributes.task_type_id" disabled={isFormDisabled} />
        <TaskFormColleague name="task_attributes.user_id" disabled={isFormDisabled} />
        <Controller
          name="task_attributes.due_date_delay"
          rules={{ required: true }}
          render={() => (
            <CustomSelect
              label={t(i18nKeys.DUE_DATE)}
              keyText="description"
              keyValue="value"
              name="task_attributes.due_date_delay"
              withBorder
              items={dueDateDelays}
              notAllowed={isFormDisabled}
            />
          )}
        />
        <Controller
          name="task_attributes.mandatory"
          rules={{ required: true }}
          render={() => (
            <CustomSelect
              label={t(i18nKeys.TASK.ATTRIBUTES.MANDATORY)}
              keyText="description"
              keyValue="value"
              name="task_attributes.mandatory"
              withBorder
              notAllowed={isFormDisabled}
              items={[
                { description: t(i18nKeys.YES), value: 'true' },
                { description: t(i18nKeys.NO), value: 'false' },
              ]}
            />
          )}
        />
      </div>
    </>
  );
};

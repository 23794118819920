import { AxiosInstance } from 'axios';
import dayjs from 'dayjs';
import { PaginationSchema } from 'types/pagination';
import { z } from 'zod';

import { useQuery } from '@tanstack/react-query';

import {
  addResourceNameToQueryResult,
  ApiError,
  useAxiosInstance,
} from '../../../shared/hooks/utils';
import { SentMediumSchema } from '../types';

const ReminderItemSchema = z.object({
  id: z.number(),
  sentAt: z.string().datetime().transform(dayjs),
  debtorFullName: z.string(),
  reference: z.string(),
  reminderType: z.string(),
  invoiceCount: z.number(),
  sentMedia: z.array(SentMediumSchema),
});

export const RemindersSchema = z.object({
  data: z.array(ReminderItemSchema),
  pagination: PaginationSchema,
});

export type ReminderItem = z.infer<typeof ReminderItemSchema>;
export type Reminders = z.infer<typeof RemindersSchema>;

type LoadRemindersVariables = {
  page: number;
  pageLimit: number;
};

export async function loadRemindersQueryFn(axiosInstance: Promise<AxiosInstance>, { queryKey }) {
  const instance = await axiosInstance;

  const { data } = await instance.get('/payment_requests', { params: queryKey[1] });
  return RemindersSchema.parse(data);
}

export const useLoadReminders = (variables: LoadRemindersVariables) => {
  const axiosInstance = useAxiosInstance();

  const queryResult = useQuery<Reminders, ApiError>({
    queryKey: ['reminders', variables],
    queryFn: (context) => loadRemindersQueryFn(axiosInstance, context),
    placeholderData: {
      data: [],
      pagination: {
        totalObjects: 0,
        totalPages: 0,
        pageLimit: 0,
      },
    },
  });

  return addResourceNameToQueryResult<Reminders, unknown, 'reminderIndex'>(
    'reminderIndex',
    queryResult,
  );
};

import { useState } from 'react';
import { PaymentPlanDetail } from 'app/Private/PaymentPlans';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'qs';
import { useSelector } from 'react-redux';
import CustomTableViews from 'shared/components/CustomTable/CustomTableViews';
import PostponeForm from 'shared/forms/PostponeForm';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { PostponableInvoice } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import ToHandleDetail from '../ToHandleDetail';

import styleIdentifiers from './imminent-reminders.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ImminentReminders() {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const invoicesData = useSelector((state: StoreState) => state.invoice.postponable);
  const [selectedInvoices, setSelectedInvoices] = useState<PostponableInvoice[]>([]);
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);
  const [actionsDisable, setActionsDisable] = useState({
    cancel: true,
    confirm: true,
  });

  const handleFilters = (filters) => {
    const newFilters = {
      ...filters,
    };

    // need to be linked
    if (
      (newFilters.total_tvac_operator && !newFilters.total_tvac) ||
      (!newFilters.total_tvac_operator && newFilters.total_tvac)
    )
      return false;

    // need to be linked
    if (
      (newFilters.remaining_balance_operator && !newFilters.remaining_balance) ||
      (!newFilters.remaining_balance_operator && newFilters.remaining_balance)
    )
      return false;

    return newFilters;
  };

  const askDurationToPostpone = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.POSTPONE),
      children: (
        <PostponeForm
          invoices={areAllItemsAcrossPagesSelected ? 'all' : selectedInvoices}
          onSubmit={postponeSelectedInvoices}
          close={() => dialogHide(DialogShowId.CUSTOM)}
        />
      ),
    });
  };

  const onChangeSelection = (value: Array<unknown>) => {
    setSelectedInvoices(value as Array<PostponableInvoice>);

    if (value.length === 0) {
      setActionsDisable({
        cancel: true,
        confirm: true,
      });
    } else if (!company.automatic_reminders) {
      let isCancelDisabled = true;
      let isConfirmDisabled = true;
      for (const item of [...value] as Array<PostponableInvoice>) {
        if (item.attributes.force_reminder) {
          isCancelDisabled = false;
        } else {
          isConfirmDisabled = false;
        }
      }

      setActionsDisable({
        cancel: isCancelDisabled,
        confirm: isConfirmDisabled,
      });
    }
  };

  const postponeSelectedInvoices = ({ days_delayed }) => {
    invoiceActions.actionsPostponeMultiple({
      data: {
        days_delayed,
        invoices: areAllItemsAcrossPagesSelected
          ? undefined
          : selectedInvoices.map((item) => ({
              id: item.id,
              payment_plan_id: item.attributes.payment_plan_id,
            })),
        filter_params: queryParamParser(qs.parse(location.search)),
        view_id: location.hash.slice(1),
      },
      callback: () => {
        reloadCustomView(invoiceActions.postponableRes);
        dialogHide(DialogShowId.CUSTOM);
        sideMenuHide();
        setActionsDisable({
          cancel: true,
          confirm: true,
        });
      },
    });
  };

  const forceReminder = (force_reminder) => () => {
    invoiceActions.actionsForceReminder({
      data: {
        force_reminder,
        invoices: areAllItemsAcrossPagesSelected
          ? undefined
          : selectedInvoices.map((item) => ({
              id: item.id,
              payment_plan_id: item.attributes.payment_plan_id,
            })),
        filter_params: queryParamParser(qs.parse(location.search)),
        view_id: location.hash.slice(1),
      },
      callback: () => {
        reloadCustomView(invoiceActions.postponableRes);
        sideMenuHide();
        setActionsDisable({
          cancel: true,
          confirm: true,
        });
      },
    });
  };

  const showInvoice = (item) => () => {
    const children = item.attributes.payment_plan_id ? (
      <PaymentPlanDetail planId={item.attributes.payment_plan_id} inModal />
    ) : (
      <ToHandleDetail id={item.id} modal />
    );

    showDialog({
      id: DialogShowId.INVOICE,
      size: DialogShowSize.LARGE,
      children,
    });
  };

  return (
    <div className={styles('Actions')}>
      <CustomTableViews
        title={t(i18nKeys.NAV.IMINENT_REMINDERS)}
        callbackAction={invoiceActions.postponableRes}
        onClickRow={showInvoice}
        isLoading={!invoicesData.loaded}
        pagination={invoicesData.metadata?.pagination}
        handleFilters={handleFilters}
        items={invoicesData.pages || []}
        currentPage={invoicesData.currentPage}
        onChangeSelectionCallback={onChangeSelection}
        onChangeSelectItemsAcrossAllPagesCallback={setAreAllItemsAcrossPagesSelected}
        tableName="postponable_invoices"
        actions={
          <div className={styles('button-wrapper', !company.automatic_reminders && 'manual')}>
            <Button
              disabled={selectedInvoices.length === 0}
              onClick={askDurationToPostpone}
              label={t(i18nKeys.POSTPONE)}
              noMargin
            />
            {!company.automatic_reminders && (
              <>
                <Button
                  disabled={actionsDisable.cancel}
                  onClick={forceReminder(false)}
                  color={ButtonColor.GREY}
                  label={t(i18nKeys.INVOICING.IMPENDING_REMINDERS.CANCEL_REMINDER)}
                  noMargin
                />
                <Button
                  disabled={actionsDisable.confirm}
                  onClick={forceReminder(true)}
                  color={ButtonColor.MAIN}
                  label={t(i18nKeys.INVOICE.SEND_REMINDER)}
                  noMargin
                />
              </>
            )}
            {/* Same actions but in a dropdown for the mobile version */}
            <Dropdown
              sideMenuInMobile
              className={styles('actions-dropdown')}
              sideMenuTitle={t(i18nKeys.FORM.ACTIONS)}
              selectorClass={styles(
                actionsDisable.cancel &&
                  actionsDisable.confirm &&
                  selectedInvoices.length === 0 &&
                  'disabled',
              )}
              selectorContent={
                <Button color={ButtonColor.WHITE} label={t(i18nKeys.FORM.ACTIONS)} noMargin />
              }
            >
              <div className={styles('dropdown-item')} onClick={askDurationToPostpone}>
                {t(i18nKeys.POSTPONE)}
              </div>
              {!company.automatic_reminders && !actionsDisable.confirm && (
                <div className={styles('dropdown-item')} onClick={forceReminder(true)}>
                  {t(i18nKeys.INVOICE.SEND_REMINDER)}
                </div>
              )}
              {!company.automatic_reminders && !actionsDisable.cancel && (
                <div className={styles('dropdown-item')} onClick={forceReminder(false)}>
                  {t(i18nKeys.INVOICING.IMPENDING_REMINDERS.CANCEL_REMINDER)}
                </div>
              )}
            </Dropdown>
          </div>
        }
      />
    </div>
  );
}

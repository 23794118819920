import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomSelect } from 'shared/io';

import { InvoiceFormFieldValues, InvoiceFormSharedProps } from '../InvoiceForm.types';

import styleIdentifiers from '../InvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type BillingLogFieldProps = InvoiceFormSharedProps;

export const BillingLogField = ({ company }: BillingLogFieldProps) => {
  const { t } = useTranslation();

  const { control } = useFormContext<InvoiceFormFieldValues>();

  const {
    invoice_customization: { billing_logs = [] },
  } = company;

  return (
    <>
      <Controller
        name="billing_log_id"
        control={control}
        rules={{ required: true }}
        render={() => (
          <CustomSelect
            items={billing_logs}
            name="billing_log_id"
            placeholder={t(i18nKeys.INVOICING.CREATE.BOOK)}
            className={styles('invoice-select-input')}
            selectClassName={styles('input', 'invoice-custom-select')}
            valueClassName={styles('value')}
            errorClassName={styles('error')}
            keyText="prefix"
            keyValue="id"
            noBorder
            noMargin
            noArrow
          />
        )}
      />
      {!billing_logs.length && (
        <Link className={styles('link-to-billing')} to="/settings/billing">
          <Icon name={IconName.INFO} /> {t(i18nKeys.FORM.INVOICING.NO_BILLING_LOG)}
        </Link>
      )}
    </>
  );
};

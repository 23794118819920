import { useEffect, useState } from 'react';
import { PlanType, StepType, WorkflowStepsAttributes } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Input } from 'shared/io';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';

import { getWorkflowStepRights } from '../WorkflowForm.utils';

import { WorkflowFormStepReminder } from './WorkflowFormStepReminder';
import { WorkflowFormStepTask } from './WorkflowFormStepTask';

import styleIdentifiers from './WorkflowFormStep.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepProps = {
  planType: PlanType;
  templates: StepType[];
  onSubmit: SubmitHandler<WorkflowStepsAttributes>;
  step?: Partial<WorkflowStepsAttributes>;
};

const TYPES = {
  ...StepType,
  send_reminder: 'send_reminder',
};

export const WorkflowFormStep = ({
  planType,
  templates,
  onSubmit,
  step,
}: WorkflowFormStepProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const form = useForm<WorkflowStepsAttributes>({
    shouldUnregister: true,
    defaultValues: step,
  });

  const [type, setType] = useState<keyof typeof TYPES | undefined>(
    !templates.length && !step?.step_type ? StepType.task_generation : step?.step_type,
  );

  const { canAddTask, canAddReminder, canEditTask, canEditReminder } = getWorkflowStepRights(
    company,
    planType,
  );

  const {
    watch,
    register,
    handleSubmit,
    formState: { errors, isDirty },
  } = form;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  register('id');

  if (!type) {
    return (
      <div className={styles('workflow-step-form')}>
        <div className={styles('head')}>{t(i18nKeys.PLAN.ADD_STEP)}</div>
        <div className={styles('content')}>
          {!canAddReminder && <p className={styles('not-included')}>{t(i18nKeys.NOT_INCLUDED)}</p>}
          <button
            className={styles('action')}
            type="button"
            onClick={() => setType('send_reminder')}
          >
            <Icon name={IconName.SEND} />
            {t(i18nKeys.FORM.RECOVER_STEP.TYPE.SEND_REMINDER)}
          </button>
          {!canAddTask && <p className={styles('not-included')}>{t(i18nKeys.NOT_INCLUDED)}</p>}
          <button
            className={styles('action')}
            type="button"
            onClick={() => setType('task_generation')}
          >
            <Icon name={IconName.COMMENT} />
            {t(i18nKeys.FORM.RECOVER_STEP.TYPE.CREATE_TASK)}
          </button>
        </div>
      </div>
    );
  }

  const canSubmit = () => {
    switch (watch('step_type')) {
      case StepType.task_generation:
        return step ? canEditTask : canAddTask;
      case StepType.preventive_reminder:
      case StepType.first_reminder:
      case StepType.intermediate_reminder:
      case StepType.last_reminder:
        return step ? canEditReminder : canAddReminder;
      default:
        return false;
    }
  };

  return (
    <FormProvider {...form}>
      <form className={styles('workflow-step-form')} onSubmit={handleSubmit(onSubmit)}>
        {type === StepType.task_generation ? (
          <WorkflowFormStepTask planType={planType} step={step}>
            <Input
              type="number"
              register={register('delay', { required: true, min: 0 })}
              label={t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION)}
              errorMessage={get(errors, 'delay')}
              disabled={step ? !canEditTask : !canAddTask}
              withBorder
            />
          </WorkflowFormStepTask>
        ) : (
          <WorkflowFormStepReminder planType={planType} availableTemplates={templates} step={step}>
            <Input
              type="number"
              register={register('delay', { required: true, min: 0 })}
              label={t(i18nKeys.DELAY_WITH_PREVIOUS_ACTION)}
              errorMessage={get(errors, 'delay')}
              disabled={step ? !canEditReminder : !canAddReminder}
              withBorder
            />
          </WorkflowFormStepReminder>
        )}
        <div className={styles('button-wrapper')}>
          <Button type="submit" label={t(i18nKeys.SAVE)} disabled={!canSubmit()} noMargin />
        </div>
      </form>
    </FormProvider>
  );
};

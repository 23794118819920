import { useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { accountActions } from 'store/account/account.actions';

export const useSynchroniseIntegration = () => {
  const { company, refetchCompany } = useLoadCompanyConfiguration();
  const queryClient = useQueryClient();

  const {
    tp_accounting: { can_synchronise_import, last_import_date, manual_import_possible_from },
  } = company;

  const canSynchronize = useMemo(() => {
    if (manual_import_possible_from) {
      return new Date(manual_import_possible_from).getTime() < new Date().getTime();
    }

    return !!can_synchronise_import;
  }, [can_synchronise_import, manual_import_possible_from]);

  const synchronize = () => {
    if (canSynchronize) {
      accountActions.syncIntegration({
        actionFailure: () => {
          refetchCompany();
        },
        callback: () => {
          queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
        },
      });
    }
  };

  return {
    synchronize,
    canSynchronize,
    lastImportDate: last_import_date,
    nextPossibleSynchronizeDate: manual_import_possible_from,
  };
};

import { useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { omit } from 'lodash-es';
import Card from 'shared/components/Card';
import {
  CGV,
  CompanyEdit,
  CustomPreferencesFieldsName,
  getDefaultCgvValues,
  SalePreferencesFieldsName,
} from 'shared/forms/PreferencesForm';
import { CompanyConfiguration, useLoadCompanyConfiguration } from 'shared/hooks';
import { reducerState } from 'shared/utils/view';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';

import { StepProps } from '../OnBoarding.types';

import SetupDebtCollectionForm from './SetupDebtCollectionForm';

import styleIdentifiers from './SetupDebtCollection.module.scss';

const styles = classNames.bind(styleIdentifiers);

const title = {
  1: i18nKeys.SALE_CONDITION,
  2: i18nKeys.FORM.PREFERENCES.CUSTOM_RECOVERY,
  3: i18nKeys.FORM.PREFERENCES.PREFERENCES_POSTAL_SEND,
  4: i18nKeys.GENERAL_CONDITIONS,
};

export default function SetupDebtCollection({ onValidStep }: StepProps) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  
  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useReducer(reducerState, {});

  const addDebtCollectionSettings = (values: CompanyEdit) => {
    onBoardingActions.setupDebtCollectionSettings({
      data: {
        ...omit(values, [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT]),
        [CGV]: values[CGV].map((e) => omit(e, SalePreferencesFieldsName.PDF_BLOB)),
      },
      callback: () => {
        const files = values[CGV].filter((e) => e[SalePreferencesFieldsName.PDF_BLOB]);

        if (!files.length) {
          return onValidStep();
        }

        return onBoardingActions.uploadTerms({
          ...files[0],
          callback: () => onValidStep(),
        });
      },
      actionFailure: (response) => {
        onValidStep(response.data.next_step);
      },
    });
  };

  const onSubmitStep = (values) => {
    setFormValues(values);
    if (step === 4) {
      addDebtCollectionSettings({ ...formValues, ...values });
    } else {
      setStep((step + 1) % 5);
    }
  };

  const defaultValues = {
    ...company,
    ...formValues,
  } as CompanyConfiguration;

  return (
    <div className={styles('setup-debt-collection')}>
      <div className={styles('steps')}>
        <Card title={t(title[step])}>
          <SetupDebtCollectionForm
            step={step}
            previousStep={() => setStep(step - 1)}
            nextStep={onSubmitStep}
            defaultValues={
              {
                ...defaultValues,
                ...(!defaultValues[CGV].length
                  ? {
                      [CGV]: [getDefaultCgvValues(true)],
                    }
                  : undefined),
              } as CompanyConfiguration
            }
          />
        </Card>
      </div>
    </div>
  );
}

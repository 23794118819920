import classNames from 'classnames/bind';
import countries from 'i18n-iso-countries';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, get, useFormContext } from 'react-hook-form';
import { CustomSelect, Input } from 'shared/io';

import styleIdentifiers from './ClientAddressForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ClientAddressFormProps = {
  name?: string;
  required?: boolean;
};

export default function ClientAddressForm({
  name = 'address_attributes',
  required = false,
}: ClientAddressFormProps) {
  const { t, currentLang } = useTranslation();
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const countriesForSelect = Object.entries(countries.getNames(currentLang)).map(
    ([value, description]) => ({ value, description }),
  );

  return (
    <div className={styles('address-form')}>
      <div className="col-12">
        <Controller
          defaultValue=""
          rules={{ required }}
          name={`${name}.country_code`}
          render={() => (
            <CustomSelect
              selectClassName={styles('input')}
              keyText="description"
              keyValue="value"
              withBorder
              name={`${name}.country_code`}
              filter
              items={countriesForSelect}
              label={t(i18nKeys.FORM.ADDRESS.COUNTRY)}
            />
          )}
        />
      </div>
      <div className={styles('col-6', 'street-name')}>
        <Input
          errorMessage={get(errors, `${name}.street_name`)}
          className={styles('input')}
          withBorder
          register={register(`${name}.street_name`, { required })}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.STREET)}
        />
      </div>
      <div className={styles('col-3')}>
        <Input
          className={styles('input')}
          withBorder
          register={register(`${name}.street_number`)}
          type="text"
          label={t(i18nKeys.NUMBER)}
        />
      </div>
      <div className={styles('col-3')}>
        <Input
          className={styles('input')}
          withBorder
          register={register(`${name}.street_box`)}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.BOX)}
        />
      </div>
      <div className="col-6">
        <Input
          errorMessage={get(errors, `${name}.zip_code`)}
          className={styles('input')}
          withBorder
          register={register(`${name}.zip_code`, { required })}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.POSTAL_CODE)}
        />
      </div>
      <div className="col-6">
        <Input
          errorMessage={get(errors, `${name}.city`)}
          className={styles('input')}
          withBorder
          register={register(`${name}.city`)}
          type="text"
          label={t(i18nKeys.FORM.ADDRESS.CITY)}
        />
      </div>
      <div className={styles('col-12')}>
        <Input
          errorMessage={get(errors, `${name}.additional_line_1`)}
          className={styles('input')}
          withBorder
          maxLength={35}
          register={register(`${name}.additional_line_1`, {
            maxLength: 35,
          })}
          type="text"
          label={t(i18nKeys.ADDITIONAL_LINE_1)}
        />
      </div>
      <div className={styles('col-12')}>
        <Input
          errorMessage={get(errors, `${name}.additional_line_2`)}
          className={styles('input')}
          withBorder
          register={register(`${name}.additional_line_2`, {
            maxLength: 35,
          })}
          type="text"
          label={t(i18nKeys.ADDITIONAL_LINE_2)}
        />
      </div>
    </div>
  );
}

import { treatActivity } from 'shared/serializer';
import {
  baseReducerListPage,
  handlePageRequest,
  handlePageResponse,
  handleUpdatePaginationResponse,
} from 'store/reducers';
import { ReduxAction } from 'types/redux';
import { AccountState } from 'types/store/account-state';

import { accountConstants as events } from './account.actions';

export const initialState: AccountState = {
  activity: baseReducerListPage,
  activityDetail: null,
};

const reducer = (state: AccountState = initialState, action: ReduxAction) => {
  switch (action.type) {
    case events.activityPage.request:
      return handlePageRequest(state, action, 'activity');
    case events.activityPage.result:
      return handlePageResponse(state, action, 'activity', treatActivity);
    case events.activityDetail.request:
      return handlePageRequest(state, action, 'activityDetail');
    case events.activityDetail.result:
      return handlePageResponse(state, action, 'activityDetail', treatActivity);
    case events.activitySeen.result:
      const tmp = handleUpdatePaginationResponse(state, action, 'activity', treatActivity);
      return tmp;
    default:
      return state;
  }
};

export default reducer;

import { useState } from 'react';
import { jotaiStore } from 'init/jotai';
import { i18nKeys, translationStateAtom } from 'locales/index';
import { useHistory } from 'react-router';
import { useProfile } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { Id } from 'types/index';

import {
  Badge,
  Card,
  Combobox,
  Group,
  Pagination,
  Stack,
  Table,
  Text,
  useCombobox,
} from '@mantine/core';
import { useSessionStorage } from '@mantine/hooks';
import { IconChevronDown } from '@tabler/icons-react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { ReminderItem, useLoadReminders } from '../api';
import { SentMediumBadge } from '../components';

const columnHelper = createColumnHelper<ReminderItem>();

const { t } = jotaiStore.get(translationStateAtom);

const columns = [
  columnHelper.accessor('reference', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.REFERENCE),
    cell: (reference) => {
      const row = reference.row.original;

      const shouldShowBadge = row.invoiceCount > 1;
      return (
        <Group>
          <Text>{reference.getValue()}</Text>
          {shouldShowBadge && <Badge variant="light">{row.invoiceCount}</Badge>}
        </Group>
      );
    },
  }),
  columnHelper.accessor('sentAt', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.SENT_AT),
    cell: (sentAt) => sentAt.getValue().format('DD/MM/YYYY'),
  }),
  columnHelper.accessor('debtorFullName', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.DEBTOR),
  }),
  columnHelper.accessor('reminderType', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.REMINDER_TYPE),
  }),
  columnHelper.accessor('sentMedia', {
    header: t(i18nKeys.REMINDER_LIST.HEADERS.SENT_MEDIA),
    cell: (sentMedia) =>
      sentMedia
        .getValue()
        .map((sentMedium, index) => <SentMediumBadge key={index} sentMedium={sentMedium} />),
  }),
];

interface ReminderProps {}

export const ReminderList = ({}: ReminderProps) => {
  const history = useHistory();
  const profile = useProfile();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageLimit, setPageLimit] = useSessionStorage({
    key: 'remindersIndexPageLimit',
    defaultValue: profile.preferences.itemsPerPage,
  });
  const { reminderIndex } = useLoadReminders({ page: currentPage, pageLimit });

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const options = [10, 20, 50, 100].map((item) => (
    <Combobox.Option value={item.toString()} key={item}>
      {item}
    </Combobox.Option>
  ));

  const table = useReactTable({
    data: reminderIndex!.data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const handleClickRow = (event: React.MouseEvent, rowId: Id) => {
    if (event.ctrlKey || event.metaKey) {
      window.open(`/reminders/${rowId}`, '_blank');
    } else {
      history.push(`/reminders/${rowId}`);
    }
  };

  return (
    <Stack h="100%">
      <PageTitle>Reminders</PageTitle>
      <Card radius={8} p={0}>
        <Group style={{ overflowY: 'auto' }}>
          <Table stickyHeader>
            <Table.Thead bg="blue.1">
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.Th key={header.id} c="blue.6" fw={400}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </Table.Th>
                  ))}
                </Table.Tr>
              ))}
            </Table.Thead>
            <Table.Tbody>
              {table.getRowModel().rows.map((row) => (
                <Table.Tr key={row.id} onClick={(event) => handleClickRow(event, row.original.id)}>
                  {row.getVisibleCells().map((cell) => (
                    <Table.Td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Td>
                  ))}
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Group>
        <Group
          justify="space-between"
          p="sm"
          style={(theme) => ({
            border: `1px solid ${theme.colors.gray[2]}`,
          })}
        >
          <Combobox
            store={combobox}
            width={250}
            position="bottom-start"
            withArrow
            onOptionSubmit={(val) => {
              setPageLimit(Number(val));
              combobox.closeDropdown();
            }}
          >
            <Combobox.Target>
              <Group
                onClick={() => combobox.toggleDropdown()}
                style={{ cursor: 'pointer' }}
                gap={4}
              >
                <Text size="sm">{pageLimit} per page</Text>
                <IconChevronDown stroke={1.5} size={16} />
              </Group>
            </Combobox.Target>

            <Combobox.Dropdown>
              <Combobox.Options>{options}</Combobox.Options>
            </Combobox.Dropdown>
          </Combobox>
          <Pagination
            value={currentPage}
            onChange={(page) => setCurrentPage(page)}
            total={reminderIndex!.pagination.totalPages}
            size="xs"
          />
        </Group>
      </Card>
    </Stack>
  );
};

import { useEffect, useState } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router-dom';
import { isNonNull } from 'remeda';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { getQueryParams, setQueryParams } from 'shared/utils/url';
import { sideMenuHide } from 'store/view/view.actions';

import { Card, SimpleGrid, Tabs } from '@mantine/core';

import { isEnabledIntegration } from './components/Integration.utils';
import {
  Integration,
  INTEGRATION_ROUTE,
  IntegrationCard,
  IntegrationCategory,
  IntegrationQueryParams,
} from './Integrations.constants';
import {
  getIntegrationCard,
  getIntegrationsCards,
  getIntegrationsCategories,
} from './Integrations.utils';
import { IntegrationsCard } from './IntegrationsCard';

type IntegrationsProps = {
  isOnBoarding?: boolean;
  onIntegrationSelect?: (integration: IntegrationCard) => void;
};

export const Integrations = ({ isOnBoarding = false, onIntegrationSelect }: IntegrationsProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { company } = useLoadCompanyConfiguration();

  const [currentCategory, setCurrentCategory] = useState(IntegrationCategory.ALL);

  const queryIntegration = getQueryParams<string>(IntegrationQueryParams.NAME);
  const queryCategory = getQueryParams<IntegrationCategory>(IntegrationQueryParams.CATEGORY);

  useEffect(() => {
    if (queryIntegration && onIntegrationSelect) {
      const integration = queryIntegration.toUpperCase() as Integration;

      if (isEnabledIntegration(Integration[integration])) {
        onIntegrationSelect(getIntegrationCard(integration, company, t));
      }
    }
  }, [queryIntegration, company, onIntegrationSelect, t]);

  useEffect(() => {
    if (
      queryCategory &&
      queryCategory !== currentCategory &&
      Object.keys(IntegrationCategory).includes(queryCategory)
    ) {
      setCurrentCategory(queryCategory);
    }
  }, []);

  useEffect(() => {
    setQueryParams(IntegrationQueryParams.CATEGORY, currentCategory);
  }, [currentCategory]);

  const onClickCard = (integration: IntegrationCard) => {
    if (onIntegrationSelect) {
      onIntegrationSelect(integration);
    } else {
      history.push(INTEGRATION_ROUTE[integration.title]);
    }
  };

  const setCategory = (newCategory: IntegrationCategory) => {
    setCurrentCategory(newCategory);
    sideMenuHide();
  };

  const categories = getIntegrationsCategories(t).filter((e) =>
    isOnBoarding ? e.category !== IntegrationCategory.BANKING : true,
  );

  const integrationList = getIntegrationsCards(company, t)
    .filter((e) => (isOnBoarding ? e.category !== IntegrationCategory.BANKING : true))
    .filter((e) => (company.isFrenchClient ? e.title !== Integration.CODABOX : true))
    .filter(
      (e) =>
        currentCategory === IntegrationCategory.ALL ||
        e.category === currentCategory ||
        (currentCategory === IntegrationCategory.MY_INTEGRATIONS && e.isActive),
    );

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.INTEGRATIONS.TITLE)}</PageTitle>
      <Card radius="md" shadow="sm">
        <Tabs
          orientation="vertical"
          value={currentCategory}
          onChange={(category) => {
            if (isNonNull(category)) setCategory(category as IntegrationCategory);
          }}
          variant="pills"
          radius="xl"
          pb="xl"
        >
          {categories.map(({ text, category }) => (
            <Tabs.Tab key={category} value={category} onClick={() => setCategory(category)}>
              {text}
            </Tabs.Tab>
          ))}
        </Tabs>
        <SimpleGrid cols={2}>
          {integrationList.map((integration) => (
            <IntegrationsCard
              key={integration.title}
              integration={integration}
              onClick={onClickCard}
            />
          ))}
        </SimpleGrid>
      </Card>
    </>
  );
};

import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Card from 'shared/components/Card';
import Integrations, {
  ExactForm,
  ExactFormFields,
  HorusForm,
  HorusFormFields,
  Integration,
  IntegrationCard,
  isEnabledIntegration,
  TeamleaderForm,
  TeamleaderFormFields,
  YukiForm,
  YukiFormFields,
} from 'shared/components/Integrations';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button } from 'shared/io';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { useQueryClient } from '@tanstack/react-query';

import { StepProps } from '../OnBoarding.types';

import styleIdentifiers from './Integrations.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function IntegrationsOnBoarding({ onValidStep }: StepProps) {
  const { t } = useTranslation();
  const client = useQueryClient();
  const { refetchCompany } = useLoadCompanyConfiguration();

  const callback = async () => {
    const { error, isError, isSuccess } = await refetchCompany();

    dialogHide(DialogShowId.CUSTOM);
    client.invalidateQueries({ queryKey: ['profile'] });

    if (isSuccess) onValidStep();
    else if (isError) {
      onValidStep(error.response?.data.next_step);
    }
  };

  const skipStep = () => {
    onBoardingActions.yuki({
      callback,
      data: {
        skip: true,
      },
    });
  };

  const yukiSubmit = (data: YukiFormFields) => {
    onBoardingActions.yuki({
      callback,
      data,
    });
  };

  const horusSubmit = (data: HorusFormFields) => {
    onBoardingActions.horus({
      callback,
      data,
    });
  };

  const teamleaderSubmit = (data: TeamleaderFormFields) => {
    onBoardingActions.teamleader({
      callback,
      data,
    });
  };

  const exactSubmit = (data: ExactFormFields) => {
    onBoardingActions.exact({
      callback,
      data,
    });
  };

  // const billitSubmit = (data: BillitFormFields) => {
  //   onBoardingActions.billit({
  //     callback,
  //     data,
  //   });
  // };

  const integrationsProps = {
    onboarding: true,
  };

  const integrations = {
    ...(isEnabledIntegration(Integration.HORUS)
      ? {
          [Integration.HORUS]: <HorusForm {...integrationsProps} onSubmit={horusSubmit} />,
        }
      : undefined),
    ...(isEnabledIntegration(Integration.YUKI)
      ? {
          [Integration.YUKI]: <YukiForm {...integrationsProps} onSubmit={yukiSubmit} />,
        }
      : undefined),
    ...(isEnabledIntegration(Integration.TEAMLEADER)
      ? {
          [Integration.TEAMLEADER]: (
            <TeamleaderForm {...integrationsProps} onSubmit={teamleaderSubmit} />
          ),
        }
      : undefined),
    ...(isEnabledIntegration(Integration.EXACT)
      ? {
          [Integration.EXACT]: <ExactForm {...integrationsProps} onSubmit={exactSubmit} />,
        }
      : undefined),
    // ...(isEnabledIntegration(Integration.BILLIT)
    //   ? {
    //       [Integration.BILLIT]: <BillitForm {...integrationsProps} onSubmit={billitSubmit} />,
    //     }
    //   : undefined),
  };

  const onIntegrationSelect = ({ title }: IntegrationCard) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      keepMountOnExit: true,
      title,
      children: integrations[title],
    });
  };

  return (
    <div className={styles('integrations')}>
      <div className={styles('steps')}>
        <Card noHead>
          <Integrations isOnBoarding onIntegrationSelect={onIntegrationSelect} />
          <div className={styles('button-wrapper')}>
            <Button
              onClick={skipStep}
              type="submit"
              className={styles('button')}
              label={t(i18nKeys.ONBOARDING[5].CONFIGURATION_DONE)}
            />
          </div>
        </Card>
      </div>
    </div>
  );
}

import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useForm } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Input } from 'shared/io';

import styleIdentifiers from '../IntegrationForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum PontoFormFieldsName {
  CLIENT_ID = 'client_id',
  CLIENT_SECRET = 'client_secret',
}

export type PontoFormFields = {
  [PontoFormFieldsName.CLIENT_ID]: string;
  [PontoFormFieldsName.CLIENT_SECRET]: string;
};

type PontoFormProps = {
  onSubmit: (values: PontoFormFields) => void;
};

export const PontoForm = ({ onSubmit }: PontoFormProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { handleSubmit, register } = useForm<PontoFormFields>();

  const {
    ponto_integration: { ponto_client_secret, ponto_client_id },
  } = company;

  return (
    <form className={styles('integration-form')} onSubmit={handleSubmit(onSubmit)}>
      <div>{t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SUMMARY)}</div>
      <Input
        type="text"
        label={t(i18nKeys.SETTINGS.CLIENT_ID)}
        register={register(PontoFormFieldsName.CLIENT_ID, { required: true })}
        placeholder={ponto_client_secret ? `*****${ponto_client_secret}` : undefined}
      />
      <Input
        type="text"
        label={t(i18nKeys.SETTINGS.INTEGRATIONS.PONTO.SECRET_CODE)}
        placeholder={ponto_client_id ? `*****${ponto_client_id}` : undefined}
        register={register(PontoFormFieldsName.CLIENT_SECRET, {
          required: true,
        })}
      />
      <div className={styles('button-wrapper')}>
        <Button label={t(i18nKeys.SAVE)} type="submit" />
      </div>
    </form>
  );
};

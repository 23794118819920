import { upperFirst } from 'lodash';

import { useQuery, UseQueryResult } from '@tanstack/react-query';

// TODO: This function runs on every render as it stands. It can be memoized, but only based
// on the contents of the query result, not the query result object itself, which is not referentially stable

export type UseResourceQueryResult<TData, TError, ResourceName extends string> = {
  [K in 'data' as `${ResourceName}`]: TData | undefined;
} & {
  [K in 'error' as `${ResourceName}${Capitalize<K>}`]: TError | null;
} & {
  [K in 'isFetching' as `is${Capitalize<ResourceName>}Fetching`]: boolean;
} & {
  [K in 'isRefetching' as `is${Capitalize<ResourceName>}Refetching`]: boolean;
} & {
  [K in 'isLoading' as `is${Capitalize<ResourceName>}Loading`]: boolean;
} & {
  [K in 'refetch' as `${K}${Capitalize<ResourceName>}`]: UseQueryResult<TData, TError>['refetch'];
};

export function addResourceNameToQueryResult<TData, TError, ResourceName extends string>(
  resourceName: ResourceName,
  queryResult: ReturnType<typeof useQuery<TData>>,
): UseResourceQueryResult<TData, TError, ResourceName> {
  const resourceNameCapitalised = upperFirst(resourceName);

  return {
    [resourceName]: queryResult.data,
    [`${resourceName}Error`]: queryResult.error,
    [`is${resourceNameCapitalised}Fetching`]: queryResult.isFetching,
    [`is${resourceNameCapitalised}Refetching`]: queryResult.isRefetching,
    [`is${resourceNameCapitalised}Loading`]: queryResult.isLoading,
    [`refetch${resourceNameCapitalised}`]: queryResult.refetch,
  } as UseResourceQueryResult<TData, TError, ResourceName>;
}

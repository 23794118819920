import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RecoveryPlanV2 } from 'shared/hooks';
import { Button } from 'shared/io';
import { RecoveryPlanShort } from 'types/recovery-plan';

import styleIdentifiers from './ChooseRecoveryPlanModal.module.scss';

const styles = classNames.bind(styleIdentifiers);
type Props = {
  className?: string;
  onClick: (planId: RecoveryPlanV2['id'] | null) => void;
  recoverPlans: RecoveryPlanShort[];
};

export default function ChooseRecoveryPlanModal({ className, recoverPlans, onClick }: Props) {
  const { t } = useTranslation();
  const click = (planId) => () => {
    onClick(planId);
  };

  return (
    <div className={styles('choose-recovery-plan-modal', className)}>
      {recoverPlans!.map((plan) => (
        <div className={styles('plan')} key={plan.id}>
          {plan.name}
          <Button label={t(i18nKeys.CHOOSE)} noMargin onClick={click(plan.id)} />
        </div>
      ))}
    </div>
  );
}

import { CSSProperties, PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { NumericFormat } from 'react-number-format';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { currencySymbol } from 'shared/utils/normalization';
import { Debtor } from 'types/store/client-state';

import { IconName } from '../Icon';
import Tooltip from '../Tooltip';

import styleIdentifiers from './amount.module.scss';

const styles = classNames.bind(styleIdentifiers);

type AmountProps = {
  tooltip?: boolean;
  direction?: DropdownDirection;
  style?: CSSProperties;
  value?: string | number;
  className?: string;
  suffix?: string;
  prefix?: string;
  debtor?: Debtor;
  localizedValue?: string | number;
};

export default function Amount({
  tooltip = true,
  direction = DropdownDirection.RIGHT,
  style,
  value,
  className,
  suffix,
  prefix,
  debtor,
  localizedValue,
  children,
}: PropsWithChildren<AmountProps>) {
  const { company } = useLoadCompanyConfiguration();
  const useSuffix = !localizedValue?.toString() && suffix ? suffix : undefined;
  const fromCurrencyItem = debtor || company;

  if (Number(value) === 0) {
    return (
      <span className={styles('Amount', className)} style={style}>
        <NumericFormat
          value={value}
          displayType="text"
          prefix={prefix}
          suffix={` ${currencySymbol(useSuffix, fromCurrencyItem)}`}
          decimalSeparator="."
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator=" "
        />
      </span>
    );
  }

  if (children) {
    return (
      <span className={styles('Amount', className)} style={style}>
        <NumericFormat
          value={Number(value).toString()}
          displayType="text"
          prefix={prefix}
          suffix={` ${currencySymbol(useSuffix, fromCurrencyItem)}`}
          decimalSeparator="."
          fixedDecimalScale
          decimalScale={2}
          thousandSeparator=" "
        />
        {tooltip ? (
          <Tooltip
            icon={IconName.CURRENCY}
            direction={direction}
            iconClassName={styles('icon-tooltip')}
            contentClassName={styles('subject-tooltip')}
          >
            {children}
          </Tooltip>
        ) : (
          { children }
        )}
      </span>
    );
  }

  return (
    <span className={styles('Amount', className)} style={style}>
      <NumericFormat
        value={value}
        displayType="text"
        prefix={prefix}
        suffix={` ${currencySymbol(useSuffix, fromCurrencyItem)}`}
        decimalSeparator="."
        fixedDecimalScale
        decimalScale={2}
        thousandSeparator=" "
      />
      {localizedValue &&
        fromCurrencyItem?.currency !== suffix &&
        (tooltip ? (
          <Tooltip
            icon={IconName.CURRENCY}
            direction={direction}
            iconClassName={styles('icon-tooltip')}
            contentClassName={styles('subject-tooltip')}
          >
            <NumericFormat
              value={localizedValue}
              displayType="text"
              suffix={` ${currencySymbol(suffix, fromCurrencyItem)}`}
              decimalSeparator="."
              fixedDecimalScale
              decimalScale={2}
              thousandSeparator=" "
            />
          </Tooltip>
        ) : (
          <NumericFormat
            value={localizedValue}
            displayType="text"
            prefix="("
            suffix={` ${currencySymbol(suffix, fromCurrencyItem)})`}
            decimalSeparator="."
            fixedDecimalScale
            decimalScale={2}
            thousandSeparator=" "
            style={{ marginLeft: '10px' }}
          />
        ))}
    </span>
  );
}

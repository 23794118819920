import { Route, Switch } from 'react-router';

import { ReminderList } from './pages/RemindersIndex';
import { Reminder } from './pages';

interface RemindersProps {}

export const Reminders = ({}: RemindersProps) => (
  <Switch>
    <Route path="/reminders/:id" component={Reminder} />
    <Route path="/reminders" component={ReminderList} />
  </Switch>
);

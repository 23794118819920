import { useState } from 'react';
import { ModelType } from 'app/Private/CustomVariables/CustomVariables.types';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { isEmpty } from 'remeda';
import { ExportCsv } from 'shared/components/ExportCsv';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { useGetConstants } from 'shared/utils/selectors';
import { appActions } from 'store/app/app.actions';

interface ExportDebtorCsvProps {
  overrideDefaultSelection?: Array<string>;
}

export const ExportDebtorCsv = ({ overrideDefaultSelection = [] }: ExportDebtorCsvProps) => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const constants = useGetConstants();
  const customVariables = company.custom_variables ?? [];

  const filteredCustomVariables = customVariables
    .filter((customVar) => customVar.model_type === ModelType.DEBTOR)
    .map((customVar) => ({
      description: customVar.name,
      value: customVar.column_name,
    }));

  const [{ exportFields, wrappers }] = useState({
    exportFields: constants.debtor_export_columns.debtor_fields.concat(
      constants.debtor_export_columns.debtor_financial_fields,
      filteredCustomVariables,
    ),
    wrappers: [
      {
        name: 'selectDebtorInfo',
        fields: constants.debtor_export_columns.debtor_fields,
        text: t(i18nKeys.COMMON.CLIENT),
      },
      {
        name: 'selectFinancialdata',
        fields: constants.debtor_export_columns.debtor_financial_fields,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.FINANCIAL_DATA),
      },
      {
        name: 'selectCustomVariable',
        fields: filteredCustomVariables,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.CUSTOM_DATA),
      },
    ],
  });

  const downloadCsv = (data) => {
    appActions.export({
      data: {
        ...data,
        ...(data.export_with_filters === true ? qs.parse(location.search) : {}),
        view_id: isEmpty(location.hash.slice(1)) ? undefined : location.hash.slice(1),
        resource_type: 'debtor',
        type: 'csv',
      },
    });
  };

  return (
    <ExportCsv
      downloadCsv={downloadCsv}
      allFields={exportFields}
      wrappers={wrappers}
      shouldHideFilterOption={!isEmpty(overrideDefaultSelection)}
      selectAllLabel={t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_DEBTOR)}
      selectFilteredLabel={t('INVOICING.EXPORT_CSV.EXPORT_FILTERED_DEBTOR')}
      overrideDefaultSelection={overrideDefaultSelection}
    />
  );
};

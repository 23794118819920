import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Checkbox } from 'shared/io';

import { CompanyEdit, CustomPreferencesFieldsName } from '../PreferencesForm.constants';

export const PaymentPlanPartialForm = () => {
  const { t } = useTranslation();

  const { watch, register } = useFormContext<CompanyEdit>();

  const { company } = useLoadCompanyConfiguration();

  const {
    package: { can_use_advanced_payment_plans, can_use_payment_plans },
  } = company;

  const disabled = !can_use_payment_plans || !can_use_advanced_payment_plans;

  return (
    <Checkbox
      register={register(CustomPreferencesFieldsName.ALLOW_PAYMENT_PLANS)}
      watch={watch}
      disabled={disabled}
      label={
        <span>
          {t(i18nKeys.FORM.PREFERENCES.ALLOW_PAYMENT_PLANS)}{' '}
          <Tooltip
            icon={IconName.INFO}
            size="14px"
            text={
              disabled
                ? t(i18nKeys.NOT_INCLUDED)
                : t(i18nKeys.FORM.PREFERENCES.TOOLTIP.ALLOW_PAYMENT_PLANS)
            }
          />
        </span>
      }
    />
  );
};

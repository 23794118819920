import { useResetAccountingIntegration } from 'api';
import { i18nKeys, useTranslation } from 'locales';
import { useQueryClient } from 'react-query';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components';
import { Button, ButtonColor } from 'shared/io';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { INTEGRATIONS_ROUTE } from '../Integrations.constants';

export const ResetButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { mutate: resetConnection } = useResetAccountingIntegration({
    mutation: {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
        history.push(INTEGRATIONS_ROUTE);
      },
    },
  });
  const handleClick = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRM),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(i18nKeys.FORM.INTEGRATION.RESET_ACCOUNTING_INTEGRATION)}
          confirmButtonColor={ButtonColor.RED}
          confirmButtonText={t(i18nKeys.RESET)}
          onConfirm={() => resetConnection()}
          onCancel={() => {
            dialogHide(DialogShowId.CONFIRM);
          }}
        />
      ),
    });
  };

  return (
    <Button color={ButtonColor.RED} noMargin onClick={handleClick}>
      {t(i18nKeys.RESET)}
    </Button>
  );
};

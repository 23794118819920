import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { accountActions } from 'store/account/account.actions';
import { onSubmitFunction } from 'types/react-hook-form';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { DigitealForm } from './DigitealForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Digiteal = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { canActivate, isActive } = getIntegrationRights(Integration.DIGITEAL, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const handleSubmit: onSubmitFunction = () => {
    accountActions.setDigitealConfiguration({
      data: {
        activate: true,
        use_digiteal_integration: true,
      },
      callback: INTEGRATION_UPDATE_CALLBACK,
    });
  };

  return (
    <article className={styles('digiteal')}>
      <IntegrationHeader name={Integration.DIGITEAL} goBack={goBack} />
      <DigitealForm onSubmit={handleSubmit} />
    </article>
  );
};

import React, { useEffect, useState } from 'react';
import { useUploadDebtorCSV, useUploadInvoiceCSV } from 'api';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import papaparse from 'papaparse';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Checkbox, CustomSelect } from 'shared/io';
import { useGetConstants } from 'shared/utils/selectors';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import { Box, Group, Pagination, ScrollAreaAutosize, Stack } from '@mantine/core';

import styleIdentifiers from './ImportCsvForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  file: File;
  update?: boolean;
  debtor?: boolean;
};

type CurrentFile = {
  index: number;
  data: any[];
  headerIndex: number;
  nonEmptyColumn: number[];
  importedResource: number;
  importError: number;
  error?: any;
};

type Attributes = {
  value: string;
  description: string;
  required: boolean;
};

export default function ImportCsvForm({ file, update, debtor }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const constants = useGetConstants();
  const { company } = useLoadCompanyConfiguration();
  const { custom_variables_attributes: customVariables } = company;

  const [currentFile, setCurrentFile] = useState<CurrentFile>({
    index: -1,
    data: [],
    headerIndex: 0,
    nonEmptyColumn: [],
    importError: 0,
    importedResource: 0,
  });

  const [pageNumber, setPageNumber] = useState<number>(0);
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [shouldForceBalanceUpdate, setShouldForceBalanceUpdate] = useState(false);
  const form = useForm({
    mode: 'onBlur',
  });

  const { mutate: uploadDebtorCsv } = useUploadDebtorCSV();
  const { mutate: uploadInvoiceCsv } = useUploadInvoiceCSV();

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil((currentFile.data.length - 1) / ITEMS_PER_PAGE);
  const paginationPageNumber = pageNumber + 1;

  useEffect(() => {
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      let data;

      try {
        data = decodeURIComponent(escape((event as any).target.result));
      } catch (e) {
        data = (event as any).target.result;
      }

      const csvData: any = papaparse.parse(data, {
        skipEmptyLines: 'greedy',
        error: () => {},
      });

      setData({
        index: 0,
        ...csvData,
        nonEmptyColumn: searchNonEmptyColumn(csvData.data, 0),
        headerIndex: 0,
        importedResource: -1,
        importError: -1,
      });

      const attributes = attributesAvailable.slice();

      csvData.data[0].forEach((headerText, index) => {
        const attrIndex = attributes.findIndex((attribute) => attribute.description === headerText);
        if (attrIndex !== -1) {
          onChooseAttr(attributes[attrIndex], index);
          attributes.splice(attrIndex, 1);
        }
      });
    };

    reader.readAsText(file, 'ISO-8859-1');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const requiredFields = update
    ? []
    : (debtor ? constants.debtor_import_columns : constants.invoice_import_columns).required_fields;

  const flatRequiredField = requiredFields.flat();

  const { getValues, setValue } = form;

  const setColKeys = (newColKeys) => {
    newColKeys.forEach((value, i) => {
      setValue(`colkeys[${i}]`, value);
    });
  };

  const filteredCustomVariables = (customVariables || [])
    .filter((customVar) => customVar.model_type === (debtor ? 'debtor' : 'invoice'))
    .map((customVar) => ({
      description: customVar.name,
      value: customVar.column_name,
    }));

  const fieldsUnsorted = (
    debtor ? constants.debtor_import_columns.fields : constants.invoice_import_columns.fields
  ).concat(filteredCustomVariables || []);

  const sortFieldList = (arr) =>
    arr.sort((a, b) => {
      const desc1 = a.description.toLowerCase();
      const desc2 = b.description.toLowerCase();

      if (a.required !== b.required) return a.required ? -1 : 1;

      if (desc1 > desc2) return 1;
      if (desc2 > desc1) return -1;
      return 0;
    });

  const [attributesAvailable, setAttributesAvailable] = useState<Attributes[]>(
    sortFieldList(
      fieldsUnsorted.map((item) => ({
        ...item,
        required: flatRequiredField.indexOf(item.value) !== -1,
      })),
    ),
  );

  const searchNonEmptyColumn = (arr: any[], headerIndex: number) => {
    const nonEmptyColumn: number[] = [];
    for (let column = 0; column < arr[0].length; column++) {
      for (let row = 0; row < arr.length; row++) {
        if (row !== headerIndex && arr[row][column]) {
          nonEmptyColumn.push(column);
          break;
        }
      }
    }
    return nonEmptyColumn;
  };

  const setData = ({
    data,
    headerIndex,
    nonEmptyColumn,
    importedResource,
    importError,
    error,
  }: any) => {
    setCurrentFile({
      index: currentFile.index,
      data: data || currentFile.data,
      headerIndex: headerIndex === 0 ? 0 : headerIndex || currentFile.headerIndex || 0,
      nonEmptyColumn: nonEmptyColumn || currentFile.nonEmptyColumn,
      importedResource: importedResource || currentFile.importedResource,
      importError: importError || currentFile.importError,
      error,
    });
  };

  const removeRow = (indexRowToDelete: number) => {
    const { data } = currentFile;
    data.splice(indexRowToDelete, 1);

    setData({
      data,
      headerIndex:
        indexRowToDelete <= currentFile.headerIndex
          ? currentFile.headerIndex - 1
          : currentFile.headerIndex,
      nonEmptyColumn: searchNonEmptyColumn(data, 0),
    });
  };

  const pageNumberTable = (arr: any[]) => {
    const beginIndex = pageNumber * 10;
    return arr.slice(
      beginIndex,
      beginIndex +
        (currentFile.headerIndex >= beginIndex && currentFile.headerIndex < beginIndex + 10
          ? 11
          : 10),
    );
  };

  const colKeys = (getValues().colkeys || []) as Array<any>;

  const onChooseAttr = (value: Attributes, col: number) => {
    const newColKeys = [...colKeys];
    newColKeys[col] = value;

    const newAttributesAvailable = attributesAvailable;

    // Si l'ancien attribut était requis, vérifier qu'il y a au moins un autre requis
    if (colKeys[col] && flatRequiredField.indexOf(colKeys[col].value) !== -1) {
      const requiredGroup = requiredFields.find(
        (group) => group.indexOf(colKeys[col].value) !== -1,
      );
      if (
        !requiredGroup!.some((field) =>
          colKeys.some(
            (colKey) => colKey && colKeys[col].value !== colKey.value && colKey.value === field,
          ),
        )
      ) {
        requiredGroup!.forEach((attr) => {
          const att = newAttributesAvailable.find((attribute) => attribute.value === attr);
          if (att) att.required = true;
        });
      }
    }
    // si l'élément est dans un groupe de requis alors enlever ceux qui ne sont plus requis
    if (value?.required) {
      const requiredGroup = requiredFields.find((group) => group.indexOf(value.value) !== -1);
      requiredGroup!.forEach((field) => {
        const fieldIndex = newAttributesAvailable.findIndex((attr) => attr.value === field);
        if (fieldIndex !== -1) newAttributesAvailable[fieldIndex].required = false;
      });
    }
    setAttributesAvailable(newAttributesAvailable.sort((a) => (a.required ? 1 : -1)));
    setColKeys(newColKeys);

    return true;
  };

  const dropDownItem = (item) => {
    const isDisabled = colKeys.find((value) => value === item.value) && 'disabled';
    return (
      item && (
        <div
          onClick={(e) => {
            if (isDisabled) e.stopPropagation();
          }}
          className={styles('dropdown-item', isDisabled, item.value.required && 'required')}
        >
          {item.text}
        </div>
      )
    );
  };

  const canValidate = () =>
    !attributesAvailable.some((attr) =>
      attr.required ? !colKeys.find((col) => col && col.value === attr.value) : false,
    );

  const goToValidatePage = (modelType: string) => {
    dialogHide(DialogShowId.CUSTOM);
    history.push(`/${modelType}/to-confirm`);
  };

  const parsedData = currentFile.data
    .filter((row, rowIndex) => rowIndex !== currentFile.headerIndex)
    .map((row) => {
      const value = {};
      colKeys.forEach((colKey, colIndex) => {
        if (colKey) {
          value[colKey.value] = row[colIndex];
        }
      });
      return value;
    });

  const getHasBankingIntegration = () => {
    if (company.codabox_integration.active) return true;
    if (company.ponto_integration.active) return true;
    if (company.digiteal_integration.status === 'activated') return true;

    return false;
  };

  const sendData = () => {
    if (canValidate()) {
      setIsUploading(true);
      if (debtor) {
        const debtors = parsedData;
        uploadDebtorCsv(
          { data: { debtors } },
          {
            onError: () => setIsUploading(false),
            onSuccess: () => goToValidatePage('client'),
          },
        );
      } else {
        const invoices = parsedData;
        uploadInvoiceCsv(
          { data: { invoices, force_balance_update: shouldForceBalanceUpdate } },
          {
            onError: () => setIsUploading(false),
            onSuccess: () => goToValidatePage('invoices'),
          },
        );
      }
    } else {
      setData({
        error: t(i18nKeys.INVOICING.IMPORT.SELECT_ALL_REQUIRED_FIELDS),
      });
    }
  };

  const isRemainingBalancePresent = colKeys.some((colKey) => colKey?.value === 'remaining_balance');
  const hasBankingIntegration = getHasBankingIntegration();

  const shouldShowForceBalanceUpdateCheckbox = isRemainingBalancePresent && hasBankingIntegration;

  return (
    <Stack w="fit-content" maw="100%" m="auto">
      <Group justify="space-between">
        <HTML
          html={t(
            i18nKeys.INVOICING.IMPORT[
              update && company.package.can_use_custom_variables ? 'EDIT_SUMMARY' : 'SUMMARY'
            ],
          )}
        />
        <Stack>
          <Button
            noMargin
            color={ButtonColor.MAIN}
            disabledClick
            disabled={!canValidate()}
            label={t(i18nKeys.SUBMIT)}
            onClick={sendData}
            isLoading={isUploading}
          />
          {shouldShowForceBalanceUpdateCheckbox && (
            <Checkbox
              noMargin
              checked={shouldForceBalanceUpdate}
              label={t(i18nKeys.FORM.INVOICING.FORCE_BALANCE_UPDATE)}
              onChange={(val) => setShouldForceBalanceUpdate(val)}
            />
          )}
        </Stack>
      </Group>
      {currentFile.error && <div className={styles('error-line')}>{currentFile.error}</div>}
      <ScrollAreaAutosize>
        <FormProvider {...form}>
          {currentFile.data.length > 0 && (
            <table className={styles('csv-list')}>
              <thead>
                <tr className={styles('no-border')}>
                  <th />
                  {currentFile.data[currentFile.headerIndex].map((cell, i) => (
                    <th key={i}>
                      <div>{cell}</div>
                    </th>
                  ))}
                </tr>
                <tr className={styles('no-border')}>
                  <th />
                  {currentFile.data[currentFile.headerIndex].map((cell, i) => (
                    <th key={i}>
                      <Box p={4}>
                        <Controller
                          defaultValue=""
                          name={`colkeys[${i}]`}
                          render={() => (
                            <CustomSelect
                              name={`colkeys[${i}]`}
                              onValueChanged={(value: any) => {
                                if (value) onChooseAttr(value, i);
                              }}
                              noMargin
                              className={styles('select-key')}
                              items={attributesAvailable}
                              noBorder
                              filter
                              itemsClassName={styles('items')}
                              itemClassName={styles('item-fix')}
                              itemRendering={dropDownItem}
                              size="small"
                              keyText="description"
                              placeholder={t('INVOICING.IMPORT.MATCH_WITH')}
                            />
                          )}
                        />
                      </Box>
                    </th>
                  ))}
                </tr>
                <tr>
                  <th />
                  {currentFile.data[currentFile.headerIndex].map((cell, i) => (
                    <th key={i}>
                      <div className={styles('empty-col')}>
                        {currentFile.nonEmptyColumn &&
                          currentFile.nonEmptyColumn.indexOf(i) === -1 &&
                          '(colonne vide)'}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {pageNumberTable(currentFile.data).map(
                  (line, i) =>
                    i !== currentFile.headerIndex && (
                      <React.Fragment key={i}>
                        <tr>
                          <td>
                            <div>
                              <Icon
                                name={IconName.TRASH_SIMPLE}
                                color={IconColor.GREY}
                                onClick={() => removeRow(pageNumber * 10 + i)}
                                boldHover
                              />
                            </div>
                          </td>
                          {line.map((cell, cellIndex) => (
                            <td key={cellIndex}>
                              <div className={styles('value-cell')}>{cell}</div>
                            </td>
                          ))}
                        </tr>
                      </React.Fragment>
                    ),
                )}
              </tbody>
            </table>
          )}
        </FormProvider>
      </ScrollAreaAutosize>
      <Group justify="space-between">
        <div>
          {currentFile.data.length > 0 && (
            <div>
              {currentFile.data.length - 1}{' '}
              {currentFile.data.length < 2
                ? t(i18nKeys.INVOICING.IMPORT.LINE)
                : t(i18nKeys.INVOICING.IMPORT.LINES)}
            </div>
          )}
          {currentFile.importedResource !== -1 && (
            <div>
              {currentFile.importedResource}{' '}
              {debtor
                ? t(i18nKeys.CLIENT.SUCCESSFUL_IMPORT)
                : t(i18nKeys.INVOICE.SUCCESSFUL_IMPORT)}
            </div>
          )}
          {currentFile.importError !== -1 && (
            <div>
              {currentFile.importError}{' '}
              {debtor ? t(i18nKeys.CLIENT.NOT_IMPORTED) : t(i18nKeys.INVOICE.NOT_IMPORTED)}
            </div>
          )}
        </div>
        <Pagination
          total={totalPages}
          onChange={(pageIndex) => setPageNumber(pageIndex)}
          value={paginationPageNumber}
          size="sm"
        />
      </Group>
    </Stack>
  );
}

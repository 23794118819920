import { Reducer, useEffect, useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, get } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { loadLightUsersQueryFn } from 'shared/hooks/use-load-light-users';
import { useAxiosInstance } from 'shared/hooks/utils';
import { CustomSelect, DateSelector, Input, IntervalFields, RadioButton } from 'shared/io';
import { filterToText } from 'shared/serializer';
import apiService from 'shared/service/api.service';
import { useFilterForm } from 'shared/utils/hooks';
import { reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { onSubmitFunction } from 'types/react-hook-form';
import { StoreState } from 'types/storeTypes';
import { TableFilter } from 'types/views';

import { useQueryClient } from '@tanstack/react-query';

import styleIdentifiers from './TableFilters.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
  tableName: string;
  filtersAvailable: TableFilter[];
  initialFiltersName: any;
  setFiltersName: Function;
};

type State = Reducer<
  {
    filtersSearched: TableFilter[];
    listData: { [key: string]: any };
    currentFilter?: TableFilter;
    operator: string;
    searchText: string;
    filters: any;
    filterCategories: any;
  },
  any
>;

const serializer = {
  recovery_plan_id: ['name', 'id'],
  user_id: ['email', 'id'],
  billing_log_id: ['prefix', 'id'],
};

export function TableFilters({
  onSubmit,
  initialValues,
  filterObject,
  filtersAvailable,
  initialFiltersName,
  setFiltersName,
  tableName,
}: Props) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const [filtersName, _setFiltersName] = useState(initialFiltersName);
  const { company } = useLoadCompanyConfiguration();
  const axiosInstance = useAxiosInstance();

  const [
    { filtersSearched, listData, currentFilter, operator, searchText, filters, filterCategories },
    setState,
  ] = useReducer<State>(reducerState, {
    filtersSearched: [],
    listData: {},
    operator: 'value',
    searchText: '',
    filters: initialValues,
    filterCategories: {
      most_used: 2,
      task_data: 4,
      invoice_data: 6,
      debtor_data: 8,
      date_data: 10,
      recovery_data: 12,
      custom_data: 14,
    },
  });

  if (!company?.package.can_use_custom_variables || tableName === 'tasks') {
    delete filterCategories.custom_data;
  }

  if (['tasks', 'debtors'].includes(tableName)) {
    delete filterCategories.invoice_data;
    if (tableName === 'tasks') {
      delete filterCategories.debtor_data;
      delete filterCategories.recovery_data;
    }
  }

  if (tableName !== 'tasks') delete filterCategories.task_data;

  const onSetFilters = (_filters) => {
    setState({ filters: _filters });
    onSubmit(_filters);
  };

  const { form, submit, resetForm } = useFilterForm({
    onSubmit: onSetFilters,
    initialValues,
    fieldToRegister: [],
    shouldUnregister: false,
  });

  useEffect(() => {
    if (filterObject) {
      filterObject.reset = () => {
        resetForm();
      };
    }
  }, [filterObject]);

  useEffect(() => {
    if (currentFilter) {
      form.setValue(`${currentFilter.name}_handler`, operator === 'value' ? '' : operator);
      if (operator !== 'value') {
        ['', 'min_', 'max_'].forEach((filterPrefix) => {
          form.setValue(filterPrefix + currentFilter.name, '');
        });
      }
      submit();
    }
  }, [operator]);

  const { register, watch, setValue, control } = form;

  const formData = watch();

  useEffect(() => {
    setState({
      filtersSearched: filtersAvailable.filter(
        (filter) => filter.name_translated.toLowerCase().indexOf(searchText?.toLowerCase()) !== -1,
      ),
    });
  }, [searchText]);

  const getData = (filter: TableFilter) => async () => {
    if (filter.name === 'user_id') {
      const res = await queryClient.fetchQuery({
        queryKey: ['users', { loadInactiveUsers: false }],
        queryFn: (context) => loadLightUsersQueryFn(axiosInstance, context),
      });

      const serialized = res.map((user) => ({
        description: user.name.full,
        value: user.id,
      }));

      setState({
        listData: {
          ...listData,
          [filter.name]: serialized,
        },
      });
      return;
    }

    appActions.customUrl({
      raw: true,
      url: `${apiService.baseUrl}/private_api/${filter.api_url}`,
      noLoading: true,
      method: 'GET',
      callback: ({ data }) => {
        setState({
          listData: {
            ...listData,
            [filter.name]: data.map((item) => {
              const newItem = removeAttributes(item);
              if (serializer[filter.name]) {
                newItem.description = newItem[serializer[filter.name][0]];
                newItem.value = newItem[serializer[filter.name][1]];
              } else {
                newItem.description =
                  newItem.type === 'light_invoice' ? newItem.reference : newItem.name;
                newItem.value = newItem.id;
              }
              return newItem;
            }),
          },
        });
      },
    });
  };

  const selectSubmit = (filter: TableFilter) => (e) => {
    // eslint-disable-next-line
    if (Boolean(filter.constant_name)) {
      const values = {};
      get(constants, filter.constant_name).forEach(({ value, description }) => {
        values[value] = description;
      });

      setFiltersName(filter.name, values);
      _setFiltersName({
        ...filtersName,
        [filter.name]: values,
      });
    }

    if (filter.is_api) {
      const values = {};

      listData[filter.name].forEach((item) => {
        values[item.id] = item.description;
      });

      setFiltersName(filter.name, values);
      _setFiltersName({
        ...filtersName,
        [filter.name]: values,
      });
    }

    submit(e);
  };

  const showFilter = (_currentFilter: TableFilter) => () => {
    if (company?.package.can_use_all_filters || _currentFilter.category === 'most_used') {
      setState({
        currentFilter: _currentFilter,
        operator: initialValues[`${_currentFilter.name}_handler`] || 'value',
      });
    }
  };

  const setOperator = (_operator) => {
    setState({
      operator: _operator,
    });
  };

  const removeCurrentFilter = () => {
    setState({ currentFilter: null });
  };

  const setSearchInput = (value) => {
    setState({
      searchText: value,
    });
  };

  return form ? (
    <div className={styles('table-filters')}>
      <div className={styles('filters-list')}>
        <div className={styles('search-filter')}>
          <Input
            value={searchText}
            onValueChanged={setSearchInput}
            noMargin
            type="text"
            label={t(i18nKeys.FORM.RESEARCH)}
          />
        </div>
        <div className={styles('container-fields')}>
          {Object.keys(filterCategories).map((filterCategory) => (
            <div
              key={filterCategory}
              className={styles('category-title')}
              style={{ order: filterCategories[filterCategory] - 1 }}
            >
              {t(`VIEWS.FILTERS.CATEGORY.${filterCategory.toUpperCase()}`)}
            </div>
          ))}
          {filtersSearched.map((filter) => (
            <div
              className={styles(
                'filter-item',
                (filters[filter.name] ||
                  filters[`${filter.name}_handler`] ||
                  filters[`min_${filter.name}`] ||
                  filters[`max_${filter.name}`]) &&
                  'active',
                !company?.package.can_use_all_filters &&
                  filter.category !== 'most_used' &&
                  'inactive',
              )}
              key={filter.id}
              style={{ order: filterCategories[filter.category] || 12 }}
              onClick={showFilter(filter)}
              title={
                !company?.package.can_use_all_filters && filter.category !== 'most_used'
                  ? t(i18nKeys.NOT_INCLUDED)
                  : ''
              }
            >
              <div>
                <div>{filter.name_translated}</div>
                <div className={styles('filter-value')}>
                  {filter.name === 'status' && filters.status
                    ? (Array.isArray(filters.status) ? filters.status : filters.status.split(','))
                        .map(
                          (status) =>
                            constants.statuses.find((el) => el.value === status)?.description,
                        )
                        .join(', ')
                    : filterToText(
                        filter.filter_type,
                        filtersName[filter.name]
                          ? (filters[filter.name] || []).map(
                              (value) => filtersName[filter.name][value],
                            )
                          : filters[filter.name],
                        filters[`${filter.name}_handler`],
                        filters[`${filter.name}_operator`],
                        filters[`min_${filter.name}`],
                        filters[`max_${filter.name}`],
                      )}
                </div>
              </div>
              <Icon name={IconName.MINIMAL_RIGHT} />
            </div>
          ))}
        </div>
      </div>
      <FormProvider {...form}>
        <div className={styles('filter-edition', currentFilter && 'show')}>
          {currentFilter && (
            <div className={styles('filter-container')}>
              <div className={styles('current-filter-head')} onClick={removeCurrentFilter}>
                <Icon name={IconName.MINIMAL_LEFT} /> {t(i18nKeys.BACK)}
              </div>
              <h3>{currentFilter.name_translated}</h3>
              <Controller
                control={control}
                name="operator"
                render={({ field: { ref, ...values } }) => (
                  <RadioButton
                    {...values}
                    value={operator}
                    onChange={setOperator}
                    className={styles('radio-button')}
                    items={[{ value: 'value', label: t(i18nKeys.VALUE) }]}
                  />
                )}
              />
              {operator === 'value' && (
                <div className={styles('input-container')}>
                  {(() => {
                    switch (currentFilter.filter_type) {
                      case 'string':
                        return (
                          <Input
                            register={register(currentFilter.name)}
                            noMargin
                            withBorder
                            shadow
                            type="text"
                            placeholder={currentFilter.name_translated}
                            onValueChanged={submit}
                          />
                        );
                      case 'select':
                        return (
                          <Controller
                            defaultValue=""
                            name={currentFilter.name}
                            render={() => (
                              <CustomSelect
                                selectClassName={styles('large')}
                                removeAll={t(i18nKeys.CLIENT.FILTER.ALL)}
                                keyText="description"
                                keyValue="value"
                                size="small"
                                filter
                                multiple
                                placeholderFilter={t(i18nKeys.FORM.RESEARCH)}
                                name={currentFilter.name}
                                noMargin
                                noBorder
                                withBorder
                                shadow
                                load={currentFilter.is_api ? getData(currentFilter) : undefined}
                                items={(() => {
                                  if (currentFilter.is_api)
                                    return listData[currentFilter.name] || [];

                                  if (currentFilter.constant_name) {
                                    if (currentFilter.constant_name === 'available_languages')
                                      return AVAILABLE_LANGUAGES;
                                    return get(constants, currentFilter.constant_name);
                                  }

                                  return [];
                                })()}
                                onValueChanged={selectSubmit(currentFilter)}
                              />
                            )}
                          />
                        );
                      case 'date':
                        return (
                          <DateSelector
                            className={styles('date-selector')}
                            name={`${currentFilter.name}_start`}
                            endName={`${currentFilter.name}_end`}
                            placeholder={t(i18nKeys.OF)}
                            endPlaceholder={t(i18nKeys.FORM.TO)}
                            withBorder
                            shadow
                            noMinDate
                            forcePlacement
                            handleChange={(value) => {
                              setValue(`${currentFilter.name}_start`, value);
                              submit();
                            }}
                            handleEndChange={(value) => {
                              setValue(`${currentFilter.name}_end`, value);
                              submit();
                            }}
                          />
                        );
                      case 'number':
                        return (
                          <IntervalFields
                            register={register}
                            className={styles('spacing')}
                            nameFrom={`min_${currentFilter.name}`}
                            nameTo={`max_${currentFilter.name}`}
                            label=""
                            placeholderTo={t(i18nKeys.FORM.MAX)}
                            placeholderFrom={t(i18nKeys.FORM.MIN)}
                            onChange={submit}
                          />
                        );
                      case 'boolean':
                        return (
                          <Controller
                            defaultValue=""
                            name={currentFilter.name}
                            render={() => (
                              <CustomSelect
                                selectClassName={styles('large')}
                                removeAll={t(i18nKeys.CLIENT.FILTER.ALL)}
                                keyText="description"
                                keyValue="value"
                                size="small"
                                name={currentFilter.name}
                                noMargin
                                noBorder
                                withBorder
                                shadow
                                items={[
                                  {
                                    description: t(i18nKeys.YES),
                                    value: 'true',
                                  },
                                  {
                                    description: t(i18nKeys.NO),
                                    value: 'false',
                                  },
                                ]}
                                onValueChanged={submit}
                              />
                            )}
                          />
                        );
                      default:
                        return null;
                    }
                  })()}
                </div>
              )}
              <div>
                <Controller
                  control={control}
                  name="operator"
                  render={({ field: { ref, ...values } }) => (
                    <RadioButton
                      {...values}
                      value={operator}
                      onChange={setOperator}
                      className={styles('radio-button')}
                      noMargin
                      items={
                        currentFilter?.name === 'missing_data'
                          ? []
                          : [
                              {
                                value: 'null',
                                label: t(i18nKeys.VIEWS.FILTERS.EMPTY_DATA),
                              },
                              {
                                value: 'not_null',
                                label: t(i18nKeys.VIEWS.FILTERS.FILL_DATA),
                              },
                            ]
                      }
                    />
                  )}
                />
              </div>
            </div>
          )}
        </div>
      </FormProvider>
    </div>
  ) : null;
}

import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { NumericFormat } from 'react-number-format';
import Card from 'shared/components/Card';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { formattedDate } from 'shared/utils/view';
import { OnClickFunction } from 'types/html-type';

import { DisplayType, VarType } from './CustomVariables.types';

import styleIdentifiers from './customVariables.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  data: Object;
  invoiceView?: boolean;
  handleSettings?: OnClickFunction;
  cardTitle?: string;
  marginLeft?: boolean;
};

export default function CustomVariables({
  data,
  invoiceView,
  handleSettings,
  cardTitle,
  marginLeft,
}: Props) {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const availableCustomVariables = company.custom_variables;
  const keys = Object.keys(data);
  const threePart = Math.ceil(keys.length / 3);
  const firstPart = keys.splice(-threePart);
  const secondPart = keys.splice(-threePart);
  const thirdPart = keys;
  const title = t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE) + (cardTitle || '');
  const icon =
    typeof handleSettings === 'undefined' ? (
      ''
    ) : (
      <Icon name={IconName.PENCIL} className={styles('icon')} onClick={handleSettings} />
    );

  const renderValue = (customVariableValue, varType, display) => {
    if (customVariableValue?.toString()) {
      switch (varType) {
        case VarType.BOOLEAN:
          return t(i18nKeys[customVariableValue.toString() === 'true' ? 'YES' : 'NO']);
        case VarType.DATE:
          return formattedDate(customVariableValue, currentLang);
        default:
          switch (display) {
            case DisplayType.MONEY:
              return (
                <NumericFormat
                  value={customVariableValue}
                  displayType="text"
                  suffix="€"
                  decimalSeparator="."
                  fixedDecimalScale
                  decimalScale={2}
                  thousandSeparator=" "
                />
              );
            case DisplayType.PERCENTAGE:
              return (
                <NumericFormat
                  value={customVariableValue}
                  suffix="%"
                  decimalScale={2}
                  displayType="text"
                />
              );
            default:
              return customVariableValue;
          }
      }
    } else {
      return 'N/A';
    }
  };

  const renderCustomVariable = (customVariableName) => {
    const customVar = (availableCustomVariables ?? []).find(
      (el) => el.column_name === customVariableName,
    );

    if (customVar == null) return null;

    const { var_type, display } = customVar;

    return (
      <div key={customVariableName} className={styles('subtitle-value')}>
        <span className={styles('subtitle')}>{customVariableName.replaceAll('_', ' ')}</span>
        <div className={styles('value')}>
          {renderValue(data[customVariableName], var_type, display)}
        </div>
      </div>
    );
  };

  const content = (
    <div className={styles('info-wrapper')}>
      <div className={styles('left')}>{firstPart.map((key) => renderCustomVariable(key))}</div>
      <div className={styles('right')}>{secondPart.map((key) => renderCustomVariable(key))}</div>
      <div className={styles('right')}>{thirdPart.map((key) => renderCustomVariable(key))}</div>
    </div>
  );

  if (company?.package.can_use_custom_variables) {
    return invoiceView ? (
      <Card
        title={title}
        infosRight={icon}
        className={styles('custom-variables', marginLeft && 'margin-left')}
      >
        {content}
      </Card>
    ) : (
      <div className={styles('custom-variables', 'custom-box')}>
        <h3>
          {title}
          {icon}
        </h3>
        {content}
      </div>
    );
  }
  return <></>;
}

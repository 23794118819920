import { CreateWorkflowBody, PlanType, StepType } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useFormContext } from 'react-hook-form';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { WorkflowStepsTarget } from '../../Workflows.constants';
import { getWorkflowRights, isAfterDueDate, isBeforeDueDate } from '../WorkflowForm.utils';

import { WorkflowFormStepsItemDelay } from './WorkflowFormStepsItemDelay';
import { WorkflowFormStepsItemDetail } from './WorkflowFormStepsItemDetail';

import styleIdentifiers from './WorkflowFormSteps.module.scss';

const styles = classNames.bind(styleIdentifiers);

type WorkflowFormStepsItemProps = {
  planType: PlanType;
  name: WorkflowStepsTarget;
  index: number;
  edit: () => void;
  remove: () => void;
};

export const WorkflowFormStepsItem = ({
  planType,
  name,
  index,
  edit,
  remove,
}: WorkflowFormStepsItemProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { canEditStep, canDeleteStep } = getWorkflowRights(company, planType);

  const { watch, setValue } = useFormContext<CreateWorkflowBody>();

  const { id, delay, step_type, _destroy } = watch(`${name}.${index}`);

  const isFirstReminder = step_type === StepType.first_reminder;

  const handleRemove = (i) => (id ? setValue(`${name}.${i}._destroy`, true) : remove());

  if (_destroy) return <></>;

  return (
    <>
      {isAfterDueDate(name) && (
        <WorkflowFormStepsItemDelay
          onClick={canEditStep ? edit : undefined}
          delay={delay}
          top
          after
        />
      )}
      <WorkflowFormStepsItemDetail name={name} index={index}>
        <button
          type="button"
          className={styles('opacity')}
          title={t(i18nKeys.EDIT)}
          disabled={!canEditStep}
          onClick={canEditStep ? edit : undefined}
        >
          <Icon name={IconName.PENCIL} />
        </button>
        {!isFirstReminder && (
          <button
            type="button"
            className={styles('red', 'opacity')}
            title={t(i18nKeys.DELETE)}
            disabled={!canDeleteStep}
            onClick={() => (canDeleteStep ? handleRemove(index) : undefined)}
          >
            <Icon name={IconName.TRASH_SIMPLE} />
          </button>
        )}
      </WorkflowFormStepsItemDetail>
      {isBeforeDueDate(name) && (
        <WorkflowFormStepsItemDelay
          onClick={canEditStep ? edit : undefined}
          delay={delay}
          bottom
          before
        />
      )}
    </>
  );
};

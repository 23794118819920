import { Reducer, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'qs';
import { useSelector } from 'react-redux';
import CustomTable from 'shared/components/CustomTable';
import ListResModal from 'shared/components/DeleteModal';
import { Button } from 'shared/io';
import { useRefresh } from 'shared/utils/hooks';
import { reducerState } from 'shared/utils/view';
import { transactionActions } from 'store/transaction/transaction.actions';
import { DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { StoreState } from 'types/storeTypes';

import HistoryListItem from './HistoryListItem';
import PaymentsFiltersForm from './PaymentsFiltersForm';

import styleIdentifiers from './history.module.scss';

const styles = classNames.bind(styleIdentifiers);
const EnhancedTable = CustomTable(HistoryListItem, PaymentsFiltersForm);

type State = Reducer<
  {
    selectAll: boolean;
    selectedItems: any[];
  },
  any
>;

export default function History() {
  const { t } = useTranslation();
  const paymentHistory = useSelector((state: StoreState) => state.transaction.paymentHistory!);
  const [{ selectedItems, selectAll: isAllSelected }, setState] = useReducer<State>(reducerState, {
    selectAll: false,
    selectedItems: [],
  });
  const { refresh, doRefresh } = useRefresh();

  const TABLE_HEADERS = [
    {
      key: 'transaction_id',
      title: t(i18nKeys.BANK_ACCOUNT.HISTORY.TRANSACTION_NUMBER),
      width: 70,
    },
    {
      title: t(i18nKeys.COMMON.BANK_ACCOUNT),
      width: 140,
    },
    {
      key: 'counterparty_name',
      title: t(i18nKeys.BANK_ACCOUNT.HISTORY.SENDER),
    },
    {
      key: 'value_date',
      title: t(i18nKeys.DATE),
    },
    {
      key: 'remaining_balance',
      title: t(i18nKeys.AMOUNT),
    },
    {
      title: t(i18nKeys.COMMUNICATION),
      width: 400,
    },
    {
      title: '',
      width: 60,
    },
  ];

  const handleFilters = (filters) => {
    const newFilters = {
      ...filters,
      bank_account_ids: filters.bank_account_ids?.length > 0 ? filters.bank_account_ids : undefined,
    };

    // need to be linked
    if ((newFilters.operator && !newFilters.amount) || (!newFilters.operator && newFilters.amount))
      return false;

    return newFilters;
  };

  const handleQueryParams = (queryParams) => {
    queryParams.bank_account_ids = queryParams.bank_account_ids
      ? queryParams.bank_account_ids.split(',')
      : undefined;
    return queryParams;
  };

  const batchActions = (action: string) => () => {
    let data = {} as any;
    if (isAllSelected) data = qs.parse(location.search);

    transactionActions[action]({
      data: {
        ...data,
        feedback: true,
        payment_ids: isAllSelected ? [] : selectedItems.map((payment) => payment.id),
      },
      callback: (res) => {
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.MEDIUM,
          title: t(i18nKeys.RESULT),
          children: <ListResModal data={res.data} />,
        });

        transactionActions.paymentHistoryPage(
          handleQueryParams(qs.parse(location.search.slice(1))),
        );

        setState({
          selectAll: false,
          selectedItems: [],
        });

        doRefresh();
      },
    });
  };

  const onCheckBoxChange = (selectedItems: any[]) => {
    setState({ selectedItems });
  };

  const onSelectAll = (value: boolean) => {
    setState({
      selectAll: value,
    });
  };

  return (
    <div className={styles('Listing')}>
      <EnhancedTable
        title={t(i18nKeys.NAV.PAYMENT_BOOK)}
        loadFunc={transactionActions.paymentHistoryPage}
        loading={paymentHistory.loading}
        orderField={(field) => field}
        handleLoadData={handleQueryParams}
        loaded={paymentHistory.loaded}
        pagination={paymentHistory.metadata?.pagination}
        headers={TABLE_HEADERS}
        handleFilters={handleFilters}
        items={paymentHistory.pages}
        itemProps={{ type: 'journal' }}
        setSelectAll={onSelectAll}
        showShadow
        resetSelection={refresh}
        onCheckBoxChange={onCheckBoxChange}
        actions={
          (selectedItems.length > 0 || isAllSelected) && (
            <div className={styles('actions')}>
              <Button
                noMargin
                label={t(i18nKeys.BANK_ACCOUNT.HISTORY.UNIGNORED_PAYMENT)}
                onClick={batchActions('unignorePayments')}
              />
              <Button
                noMargin
                label={t(i18nKeys.BANK_ACCOUNT.RECONCILIATION.IGNORE_PAYMENTS)}
                onClick={batchActions('ignorePayments')}
              />
            </div>
          )
        }
      />
    </div>
  );
}

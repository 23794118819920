import { PropsWithChildren } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { Button, ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';

import { ActionIcon, Group, Text } from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons-react';

import { Integration } from '../Integrations.constants';
import { isAccountingIntegration } from '../Integrations.utils';

import { useSynchroniseIntegration } from './Integration.hooks';
import { ResetButton } from './ResetButton';

import styleIdentifiers from './IntegrationHeader.module.scss';

const styles = classNames.bind(styleIdentifiers);

type IntegrationHeaderProps = {
  name: Integration;
  goBack: () => void;
  className?: string;
  isActive?: boolean;
  isConnected?: boolean;
};

export const IntegrationHeader = ({
  name,
  goBack,
  children,
  isActive = false,
  isConnected = false,
  ...props
}: PropsWithChildren<IntegrationHeaderProps>) => {
  const { t, currentLang } = useTranslation();
  const history = useHistory();

  const { synchronize, canSynchronize, nextPossibleSynchronizeDate, lastImportDate } =
    useSynchroniseIntegration();

  return (
    <header className={styles('header')} {...props}>
      <Group mb="xl">
        <ActionIcon
          onClick={() => history.push('/settings/integrations')}
          size="md"
          variant="light"
        >
          <IconArrowLeft stroke={1.5} />
        </ActionIcon>
        <Text size="lg" fw="bold">
          {name}
        </Text>
      </Group>
      <div className={styles('synchronize')}>
        {isActive && (
          <>
            {lastImportDate && (
              <p>
                {t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                  date: formattedDate(lastImportDate, currentLang, 'LLL'),
                })}
              </p>
            )}
            <Tooltip icon={IconName.INFO} iconClassName={styles('icon')}>
              {t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION)}
              {!canSynchronize && nextPossibleSynchronizeDate && (
                <span className={styles('info')}>
                  {t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                    date: formattedDate(nextPossibleSynchronizeDate, currentLang, 'LLL'),
                  })}
                </span>
              )}
            </Tooltip>
            <Button
              disabled={!canSynchronize}
              onClick={synchronize}
              iconRight={IconName.SYNC}
              label={t(i18nKeys.SYNCHRONIZE)}
              noMargin
              color={ButtonColor.WHITE}
            />
          </>
        )}
        {isAccountingIntegration(name) && isConnected ? <ResetButton /> : null}
      </div>
    </header>
  );
};

import { CompanyConfiguration } from 'shared/hooks';
import apiService from 'shared/service/api.service';
import { isConnectorActive } from 'shared/utils/connector';
import { config } from 'store/constants';

import { ENABLED_INTEGRATIONS, Integration } from '../Integrations.constants';

const { baseUrl, tenant } = apiService;
const {
  exact: { client_id: exactClientId },
  billit: { client_id: billitClientId, base_url: billitBaseUrl },
} = config;

const publicUrl = baseUrl.replace('backend', 'public.backend');

export type TimeoutType = ReturnType<typeof setTimeout>;

export const isEnabledIntegration = (integration: Integration) =>
  ENABLED_INTEGRATIONS.includes(integration);

export const getIntegrationRights = (integration: Integration, company: CompanyConfiguration) => {
  switch (integration) {
    case Integration.CODABOX:
      return {
        canActivate:
          !isConnectorActive() &&
          isEnabledIntegration(integration) &&
          company?.codabox_integration?.can_activate,
        isActive: company?.codabox_integration?.active,
      };

    case Integration.PONTO:
      return {
        canActivate:
          !isConnectorActive() &&
          isEnabledIntegration(integration) &&
          company?.ponto_integration?.can_activate,
        isActive: company?.ponto_integration?.active,
      };
    case Integration.DIGITEAL:
      return {
        canActivate: isEnabledIntegration(integration) && company?.package?.can_use_online_payment,
        isActive: company?.digiteal_integration?.use_integration,
      };
    case Integration.COMPANYWEB:
      return {
        canActivate: true,
        isActive:
          company.company_web_credentials.login != null ||
          company.company_web_credentials.password != null,
      };
    case Integration.CHIFT:
      return {
        canActivate:
          !isConnectorActive() && (company?.tp_accounting?.active?.includes(integration) ?? false),
        // Have we logged into the service?
        isConnected: company?.tp_accounting?.integration_in_use?.includes(integration) ?? false,
        // Have minimum required config steps been taken, if any? (Most commonly choosing a company)
        isConfigure: company?.tp_accounting?.can_configure?.includes(integration) ?? false,
        // Have we checked the checkboxes that actually cause the imports to happen?
        isActive: company?.tp_accounting?.active?.includes(integration) ?? false,
      };
    default:
      return {
        canActivate:
          !isConnectorActive() &&
          isEnabledIntegration(integration) &&
          (company?.tp_accounting?.active?.includes(integration) ||
            company?.tp_accounting?.active?.length === 0),
        // Have we logged into the service?
        isConnected: company?.tp_accounting?.integration_in_use?.includes(integration) ?? false,
        // Have minimum required config steps been taken, if any? (Most commonly choosing a company)
        isConfigure: company?.tp_accounting?.can_configure?.includes(integration) ?? false,
        // Have we checked the checkboxes that actually cause the imports to happen?
        isActive: company?.tp_accounting?.active?.includes(integration) ?? false,
      };
  }
};

export const getTeamleaderConnectUrl = () =>
  `https://app.teamleader.eu/oauth2/authorize?client_id=53efcfb7aaa8e786191ba73fbfcab285&response_type=code&state=${tenant}&redirect_uri=${publicUrl}/private_api/teamleader_callbacks/authenticate`;

export const getHorusConnectUrl = (currentLang: string) =>
  `https://my-horus.com/${
    currentLang === 'nl' ? currentLang : 'fr'
  }/api/oauth2/authorize?client_id=ck_sEzBr3cObz5fAJo&response_type=code&state=${tenant}&redirect_uri=${publicUrl}/api/horus_callbacks/authenticate`;

export const getExactConnectUrl = () =>
  `https://start.exactonline.be/api/oauth2/auth?client_id=${exactClientId}&redirect_uri=${publicUrl}/private_api/exact_callbacks/authenticate?tenant=${tenant}&response_type=code&force_login=0`;

export const getBillitConnectUrl = (currentLang: string) =>
  `${billitBaseUrl}Account/Logon?client_id=${billitClientId}&state=${tenant}&redirect_uri=${publicUrl}/private_api/billit_callbacks/authenticate`;

export const isMaskFilled = (value) => (value?.indexOf('_') !== -1 ? 'ERROR.FIELD_REQUIRED' : true);

import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { RouteChildrenProps } from 'react-router';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { accountActions } from 'store/account/account.actions';

import { Integration, INTEGRATIONS_ROUTE } from '../../Integrations.constants';
import { INTEGRATION_UPDATE_CALLBACK, IntegrationFormFieldsName } from '../Integration.constants';
import { getIntegrationRights } from '../Integration.utils';
import { IntegrationHeader } from '../IntegrationHeader';

import { ExactForm, ExactFormFields, ExactFormFieldsName } from './ExactForm';

import styleIdentifiers from '../Integration.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const Exact = ({ history: { goBack, replace } }: RouteChildrenProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { isActive, canActivate, isConnected } = getIntegrationRights(Integration.EXACT, company);

  useEffect(() => {
    if (!canActivate && !isActive) {
      replace(INTEGRATIONS_ROUTE);
    }
  }, [canActivate, isActive, replace]);

  if (!company?.tp_accounting) {
    return (
      <div className={styles('loading')}>
        <div>{t(i18nKeys.LOADING)}</div>
      </div>
    );
  }

  const handleSubmit = (data: ExactFormFields) => {
    accountActions.updateExactSettings({
      data,
      callback: INTEGRATION_UPDATE_CALLBACK,
    });
  };

  const {
    tp_accounting: {
      use_import,
      use_payment_matching,
      additional_parameters,
      issue_date_limit,
      debtor_update,
      automatic_sync,
    },
  } = company;

  return (
    <article className={styles('exact')}>
      <IntegrationHeader
        name={Integration.EXACT}
        goBack={goBack}
        isActive={isActive}
        isConnected={isConnected}
      />
      <ExactForm
        onSubmit={handleSubmit}
        initialValues={{
          [ExactFormFieldsName.IMPORT_INTEGRATION]: use_import,
          [ExactFormFieldsName.REFERENCE_FIELD]: additional_parameters?.reference_field || '',
          [ExactFormFieldsName.PAYMENT_MATCHING]: use_payment_matching,
          [IntegrationFormFieldsName.ISSUE_DATE_LIMIT]: issue_date_limit,
          [IntegrationFormFieldsName.DEBTOR_UPDATE]: debtor_update,
          [IntegrationFormFieldsName.AUTOMATIC_SYNC]: automatic_sync,
        }}
      />
    </article>
  );
};

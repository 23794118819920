import { useEffect } from 'react';
import classNames from 'classnames';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import CustomTable from 'shared/components/CustomTable';
import RecovrLogoLoader from 'shared/components/Loader';
import {
  CreateExternalMailboxVariables,
  useCreateExternalMailbox,
  useLoadCompanyConfiguration,
  useLoadExternalMailboxes,
} from 'shared/hooks';
import { Button } from 'shared/io';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';

import { notifications } from '@mantine/notifications';

import { ExternalMailboxSettingsForm } from './ExternalMailboxSettingsForm';
import { ExternalMailboxSettingsItem } from './ExternalMailboxSettingsItem';

import styleIdentifiers from './ExternalMailboxSettings.module.scss';

const styles = classNames.bind(styleIdentifiers);

const ExternalMailboxSettingsCustomTable = CustomTable(ExternalMailboxSettingsItem);

export const ExternalMailboxSettings = () => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();
  const canAddMailbox = company.package.can_add_mailbox;
  const canUseExternalMails = company.package.can_use_external_mails;

  const { externalMailboxes, isExternalMailboxesLoading, externalMailboxesError } =
    useLoadExternalMailboxes();

  const { createExternalMailbox } = useCreateExternalMailbox();

  const queryParams = qs.parse(location.search);
  const messages = queryParams.message as string;

  useEffect(() => {
    if (messages) {
      notifications.show({
        title: t(i18nKeys.SUCCESS),
        color: 'green',
        message: messages,
      });
    }
  }, [messages, t]);

  if (isExternalMailboxesLoading && !externalMailboxes) return <RecovrLogoLoader />;

  if (externalMailboxesError || !externalMailboxes) return <span>Error</span>;

  const createExternalMailboxConfiguration = () =>
    sideMenuShow({
      unmount: true,
      content: (
        <ExternalMailboxSettingsForm
          onSubmit={(data) => {
            const temp = { ...data, is_microsoft: false } as Extract<
              CreateExternalMailboxVariables,
              { is_microsoft: false }
            >;
            createExternalMailbox(temp, { onSuccess: sideMenuHide });
          }}
        />
      ),
    });

  const TABLE_HEADERS = [
    {
      key: 'id',
      title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ID),
    },
    {
      key: 'host',
      title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.HOST),
    },
    {
      key: 'port',
      title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.PORT),
    },
    {
      key: 'ssl',
      title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.SSL),
    },
    {
      key: 'login',
      title: t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LOGIN),
    },
    {
      title: t(i18nKeys.FORM.ACTIONS),
      width: 100,
    },
  ];

  const tooltipMessage = canUseExternalMails
    ? i18nKeys.SETTINGS.EXTERNAL_MAILBOX.LIMIT_EXCEEDED
    : i18nKeys.NOT_INCLUDED;

  return (
    <ExternalMailboxSettingsCustomTable
      className={styles('Mailbox-settings')}
      title={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.TITLE)}
      headers={TABLE_HEADERS}
      items={externalMailboxes.data || []}
      pagination={externalMailboxes.metadata.pagination}
      itemProps={{ externalMailboxes }}
      loading={isExternalMailboxesLoading}
      loaded={!isExternalMailboxesLoading}
      notSortable
      noCheckbox
      actions={
        <Button
          label={t(i18nKeys.SETTINGS.EXTERNAL_MAILBOX.ADD)}
          onClick={createExternalMailboxConfiguration}
          noMargin
          title={canAddMailbox ? '' : t(tooltipMessage)}
          disabled={!canAddMailbox}
        />
      }
    />
  );
};

import { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import HTML from 'shared/components/HTML/HTML';
import {
  CompanyEdit,
  CustomRecoveryPartialForm,
  GeneralConditionsPartialSendTos,
  PostalPreferencesPartialForm,
  SalePreferencesPartialForm,
  SalePreferencesPartialFormUpload,
} from 'shared/forms/PreferencesForm';
import { CompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor } from 'shared/io';
import { OnClickFunction } from 'types/html-type';

import styleIdentifiers from './SetupDebtCollectionForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  step: number;
  previousStep: OnClickFunction;
  nextStep: Function;
  defaultValues: CompanyConfiguration;
};

export default function SetupDebtCollectionForm({
  defaultValues,
  step,
  previousStep,
  nextStep,
}: Props) {
  const { t } = useTranslation();
  const nodeRef = useRef(null);

  const form = useForm<CompanyEdit>({
    defaultValues,
  });

  const { getValues, reset, trigger } = form;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const submit = async () => {
    const isFormValid = await trigger();
    if (isFormValid) {
      nextStep(getValues());
    }
  };

  return (
    <div className={styles('setup-debt-collection-form')}>
      <SwitchTransition>
        <CSSTransition
          key={step}
          timeout={500}
          nodeRef={nodeRef}
          classNames={{
            appear: styles('form-animation-appear'),
            appearActive: styles('form-animation-appear-active'),
            appearDone: styles('form-animation-appear-done'),
            enter: styles('form-animation-enter'),
            enterActive: styles('form-animation-enter-active'),
            enterDone: styles('form-animation-enter-done'),
            exit: styles('form-animation-exit'),
            exitActive: styles('form-animation-exit-active'),
            exitDone: styles('form-animation-exit-done'),
          }}
        >
          <FormProvider {...form}>
            <div ref={nodeRef}>
              <div className={styles('description-test', `step${step}`)}>
                <HTML html={t(i18nKeys.ONBOARDING[3][`DESCRIPTION_STEP${step}`])} />
                {step === 1 && <HTML html={t(i18nKeys.ONBOARDING[3].DESCRIPTION_STEP1_2)} />}
              </div>
              {step === 1 && <SalePreferencesPartialForm onboarding />}
              {step === 2 && (
                <CustomRecoveryPartialForm
                  onboarding
                  onlinePayment={defaultValues.package.can_use_online_payment}
                />
              )}
              {step === 3 && <PostalPreferencesPartialForm />}
              {step === 4 && (
                <>
                  <SalePreferencesPartialFormUpload onboarding />
                  <div>
                    <GeneralConditionsPartialSendTos />
                  </div>
                </>
              )}
            </div>
            <div className={styles('button-wrapper')}>
              {step >= 2 && (
                <Button
                  color={ButtonColor.GREY}
                  onClick={previousStep}
                  label={t(i18nKeys.PREVIOUS)}
                />
              )}
              <Button
                onClick={submit}
                label={step === 4 ? t(i18nKeys.ONBOARDING.NEXT_STEP) : t(i18nKeys.NEXT)}
              />
            </div>
          </FormProvider>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
}

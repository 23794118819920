import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import AddressBlock from 'shared/components/AddressBlock';
import Card from 'shared/components/Card';
import { Icon, IconColor, IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { dialogHide, DialogShowId } from 'store/view/view.actions';
import { OnClickFunction } from 'types/html-type';
import { Debtor } from 'types/store/client-state';
import { Invoice } from 'types/store/invoice-state';

import styleIdentifiers from './debtorInfo.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  imported?: boolean;
  invoiceData?: Invoice;
  clientData: Debtor;
  reloadInfo?: OnClickFunction;
  label?: string;
};

export default function DebtorInfo({
  imported,
  invoiceData,
  clientData,
  reloadInfo,
  label,
}: Props) {
  const { t } = useTranslation();
  const history = useHistory();

  const seeClient = (debtorId) => () => {
    dialogHide(DialogShowId.INVOICE);
    history.push(`/clients/${debtorId}`);
  };
  const editClient = (debtor) => () => {
    editClient({
      client: debtor,
      callback: reloadInfo,
    });
  };

  return (
    <Card
      title={label || `${t(i18nKeys.COMMON.CLIENT)} - ${clientData!.full_name}`}
      infosRight={
        clientData!.paused && (
          <Icon
            name={IconName.PAUSE}
            color={IconColor.DARK}
            size="18px"
            title={t(i18nKeys.RECOVERY_IN_PAUSE)}
          />
        )
      }
      className={styles('box', 'client-info', 'className')}
      actionHead={imported ? undefined : seeClient(clientData!.id)}
    >
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>
          {t(i18nKeys.ADDRESS)}{' '}
          {(invoiceData ? invoiceData.address_missing : !clientData.address_attributes) && (
            <Tooltip
              className={styles('action-drop')}
              contentClassName={styles('warning')}
              item={
                <div onClick={editClient(clientData)} className={styles('circle')}>
                  !
                </div>
              }
            >
              {t(i18nKeys.INCOMPLETE_ADRESS)}
            </Tooltip>
          )}
        </span>
        <div className={styles('value', 'medium')}>
          <AddressBlock address={clientData!.address_attributes} className={styles('address')} />
        </div>
      </div>
      {clientData!.phones_attributes.length > 0 && (
        <div className={styles('item', 'block')}>
          <span className={styles('subtitle')}>{t(i18nKeys.PHONE)}</span>
          <a
            href={`tel:${
              clientData!.phones_attributes[clientData!.phones_attributes.length - 1].number
            }`}
            className={styles('value', 'medium')}
          >
            {clientData!.phones_attributes[clientData!.phones_attributes.length - 1].number}
          </a>
        </div>
      )}
      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.CONTACT)}</span>
        <span className={styles('value', 'medium')}>{clientData!.full_name}</span>
      </div>

      <div className={styles('item', 'block')}>
        <span className={styles('subtitle')}>{t(i18nKeys.EMAIL)}</span>
        {(invoiceData ? invoiceData.email_missing : !clientData.emails[0]) ? (
          <div className={styles('error')} onClick={editClient(clientData!)}>
            {t(i18nKeys.MISSING_EMAIL)}
          </div>
        ) : (
          <a href={`mailto:${clientData!.emails[0]}`} className={styles('value', 'medium')}>
            {clientData!.emails[0]}
          </a>
        )}
      </div>
      {clientData!.account_manager && (
        <div className={styles('item', 'block')}>
          <span className={styles('subtitle')}>{t(i18nKeys.ACCOUNT_MANAGER)}</span>
          <span className={styles('value', 'medium')}>{clientData!.account_manager.email}</span>
        </div>
      )}
    </Card>
  );
}

import Payment from 'payment';
import { chunk, join, map, pipe } from 'remeda';
import { AVAILABLE_CURRENCIES } from 'shared/constants';
import { CompanyConfiguration, useProfile } from 'shared/hooks';
import { Locale } from 'types';
import { Currency } from 'types/currency';
import { Debtor } from 'types/store/client-state';

import { captureMessage } from '@sentry/react';

export function extractNumber(value = '') {
  return value.replace(/\D+/g, '');
}

/**
 * @deprecated The currency should only:
 * - be part of a localizedAmount object
 * - be assumed to be EUR in other cases
 */
export function currencySymbol(
  currencySuffix: string | undefined = undefined,
  fromCurrencyItem: CompanyConfiguration | Debtor | null = null,
) {
  const currency = currencySuffix ?? fromCurrencyItem?.currency ?? 'EUR';

  return AVAILABLE_CURRENCIES.find((c) => c.value === currency)?.symbol ?? '€'; // TODO: BETTER TYPING AROUND CURRENCY
}

export function formatCreditCardNumber(value) {
  if (!value) {
    return value;
  }

  const issuer = Payment.fns.cardType(value);
  const clearValue = extractNumber(value);

  let nextValue;

  switch (issuer) {
    case 'amex':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        15,
      )}`;
      break;
    case 'dinersclub':
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 10)} ${clearValue.slice(
        10,
        14,
      )}`;
      break;
    default:
      nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(4, 8)} ${clearValue.slice(
        8,
        12,
      )} ${clearValue.slice(12, 19)}`;
      break;
  }

  return nextValue.trim();
}

/**
 * @deprecated Use `useSafeLocalizedCurrencyFormatter` instead
 */
export const formatAmount = (
  value,
  _decimal = ',',
  _thousands = '.',
  _decimalCount = 2,
  currencySymbolStr = '€',
  locale: Locale = 'fr',
) => {
  const options: Intl.NumberFormatOptions = {};

  const currencyFromSymbol =
    AVAILABLE_CURRENCIES.find((c) => c.symbol === currencySymbolStr)?.value ?? 'EUR';

  options.style = 'currency';
  options.currency = currencyFromSymbol;

  const formatter = Intl.NumberFormat(locale, options);

  return formatter.format(value);
};

// Value should be string but the input arg is typed as any in a few of the callsites
// Caused an issue when undefined was passed in
export function formatCommunication(value: unknown) {
  if (typeof value !== 'string') return '';

  const isFrenchCommunication = value.startsWith('RF');

  if (isFrenchCommunication) {
    // prettier-ignore
    return pipe(
      value,
      () => value.split(''),
      chunk(4),
      map(join('')),
      join(' '),
    );
  }

  const clearValue = extractNumber(value);

  let output = `+++${clearValue.slice(0, 3)}`;

  if (clearValue.length > 3) {
    output += `/${clearValue.slice(3, 7)}`;
  }

  if (clearValue.length > 7) {
    output += `/${clearValue.slice(7, 12)}`;
  }

  if (clearValue.length >= 12) {
    output += '+++';
  }

  return output;
}

export function formatBankAccount(value) {
  if (!value) return '';

  const clearValue = value.replace(/\s+/g, '').toUpperCase();

  let output = `${clearValue.slice(0, 4)}`;

  if (clearValue.length > 4) {
    output += ` ${clearValue.slice(4, 8)}`;
  }
  if (clearValue.length > 8) {
    output += ` ${clearValue.slice(8, 12)}`;
  }
  if (clearValue.length > 12) {
    output += ` ${clearValue.slice(12, 16)}`;
  }
  if (clearValue.length > 16) {
    output += ` ${clearValue.slice(16)}`;
  }

  return output;
}

// See https://recovr.sentry.io/issues/4697076958/
// The problem is most likely a compatibility issue with the more recent `notation: 'compact'` option
function supportsCompactNotation() {
  try {
    new Intl.NumberFormat('en', { notation: 'compact' }).format(1000);
    return true;
  } catch (e) {
    captureMessage(
      'Intl.NumberFormat does not support compact notation, falling back to long notation',
    );
    return false;
  }
}

// Formatter that uses the compact notation if available without breaking on older browsers.
// If compact is not available, the long format will be used. I consider this acceptable given the
// small amount of people likely to be affected.
export function useSafeLocalizedCompactCurrencyFormatter(stripCurrency: boolean = false) {
  const { locale } = useProfile();

  const options: Intl.NumberFormatOptions = {};

  if (!stripCurrency) {
    options.style = 'currency';
    options.currency = 'EUR';
  }

  if (supportsCompactNotation()) {
    options.notation = 'compact';
  }

  return new Intl.NumberFormat(locale, options);
}

/**
 * Invoke the hook to get the formatter, then call the .format method on the formatter
 * @param stripCurrency boolean
 * @returns Intl.NumberFormat
 */
export function useLocalizedCurrencyFormatter(stripCurrency: boolean = false, currency?: Currency) {
  const profile = useProfile();

  const locale: Locale = profile?.locale ?? 'fr';

  const options: Intl.NumberFormatOptions = {};

  if (!stripCurrency) {
    options.style = 'currency';
    options.currency = currency ?? 'EUR';
  }

  return new Intl.NumberFormat(locale, options);
}

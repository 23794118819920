import { useCallback, useState } from 'react';
import { SendingMediaNames } from 'api/models';
import ImportOriginalInvoice from 'app/Private/Invoices/ToHandleDetail/ImportOriginalInvoice';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useSelector } from 'react-redux';
import { ThirdPartyType, useLoadCompanyConfiguration, useLoadThirdParties } from 'shared/hooks';
import { Dropdown } from 'shared/io';
import { treatInvoice } from 'shared/serializer';
import { formattedDate } from 'shared/utils/view';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { Id } from 'types';
import { GroupedInvoiceProps } from 'types/invoiceActions';
import { Invoice, InvoiceActions as InvoiceActionsType } from 'types/store/invoice-state';
import { StoreState } from 'types/storeTypes';

import { modals } from '@mantine/modals';

import AYSModal from '../AYSModal';
import { hasSendingMedium } from '../SendingMedium';

import AdditionalReminder from './AdditionalReminder';
import ChooseInvoicesForm from './ChooseInvoicesForm';
import { ChooseThirdPartyTypeForm } from './ChooseThirdPartyTypeForm';
import ConfirmBailiffForm from './ConfirmBailiffForm';
import FormalNotice from './FormalNotice';
import { GcollectModal } from './GcollectModal';
import SelectThirdPartyForm from './SelectThirdPartyForm';

import styleIdentifiers from './invoiceActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

type InvoiceActionsDropdownProps = {
  className?: string;
  invoice: Invoice;
  item: React.ReactElement;
  reload: Function;
  topElement?: string;
  selectorClass?: string;
  // This component is used on each row of the index of invoices to handle and we don't want to fire all
  // those requests off at once, so we optionally defer it until the user clicks on the dropdown
  shouldDeferRequest?: boolean;
};

export const InvoiceActionsDropdown = ({
  reload,
  invoice,
  className,
  item,
  topElement,
  selectorClass,
  shouldDeferRequest: _shouldDeferRequest = false,
}: InvoiceActionsDropdownProps) => {
  const { t, currentLang } = useTranslation();
  const [shouldDeferRequest, setShouldDeferRequest] = useState(_shouldDeferRequest);
  const enableRequest = () => setShouldDeferRequest(false);
  const { company } = useLoadCompanyConfiguration();
  const constants = useSelector((state: StoreState) => state.app.constants);

  const { thirdParties } = useLoadThirdParties({
    id: invoice.id,
    enabled: !shouldDeferRequest,
  });

  const additionalReminderAction = useCallback(
    (fullInvoice: Invoice) => {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.LATE_FEES),
        children: (
          <AYSModal
            text={t(i18nKeys.ADD_LATE_FEES)}
            cancelButtonText={t(i18nKeys.NO)}
            confirmButtonText={t(i18nKeys.YES)}
            onCancel={() => {
              additionalReminderOptions({ add_late_fees: false, fullInvoice });
            }}
            onConfirm={() => {
              additionalReminderOptions({ add_late_fees: true, fullInvoice });
            }}
          />
        ),
      });
    },
    [invoice],
  );

  // Sentry 18Z: Apparently CustomTable can pass a nullish invoice since it is typed as any
  if (!invoice) return null;

  const callbackAction = () => {
    sideMenuHide();
    reload?.();
  };

  const isInactive = (type: InvoiceActionsType) => !invoice.actions.includes(type);

  const sendOriginalInvoice = () => {
    callbackAction();
    dialogHide(DialogShowId.CUSTOM);
  };

  const confirmThirdParty = (additionalInvoices) => (value) => {
    dialogHide(DialogShowId.CUSTOM);
    invoiceActions.actionsThirdParty({
      id: invoice.id,
      callback: callbackAction,
      data: {
        third_party_company_id: value.third_party_company_id,
        additional_invoices: additionalInvoices,
      },
    });
  };

  const openImportOriginalInvoiceModal = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      keepMountOnExit: true,
      title: t(i18nKeys.INVOICE_IMPORT),
      children: (
        <ImportOriginalInvoice onEditOriginalFile={sendOriginalInvoice} invoice={invoice} />
      ),
    });
  };

  const canSendByPost = !(
    !constants.external_services.postgreen.active &&
    hasSendingMedium(company.sending_media_attributes, SendingMediaNames.post)
  );

  const selectGroupedInvoices = ({ type, onSubmit, text, data = {} }: GroupedInvoiceProps) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(text || i18nKeys.FORM.CHOOSE_INVOICES.TITLE),
      children: (
        <ChooseInvoicesForm
          getData={(callback) => {
            invoiceActions.getGroupedInvoices({
              id: invoice.id,
              data: {
                debtor_id: invoice.debtor_id,
                status: 'late_unpaid',
                group_type: type,
                ...data,
              },
              callback,
            });
          }}
          type={type}
          onSubmit={(submitData) => {
            onSubmit(submitData);
          }}
        />
      ),
    });
  };

  const additionalReminderOptions = ({ fullInvoice, ...lateFeesOptions }) => {
    selectGroupedInvoices({
      type: 'additional_reminder',
      data: lateFeesOptions,
      onSubmit: ({ invoiceActionData, ...items }) =>
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.LARGE,
          title: t(i18nKeys.FORM.ADDITIONAL_REMINDER.CONFIRM_SENDING_OPTIONS),
          children: (
            <AdditionalReminder
              items={{ ...items, ...lateFeesOptions }}
              onSubmit={(values) => {
                invoiceActions.actionsAdditionalReminder({
                  id: invoice.id,
                  data: {
                    ...values,
                    ...items,
                    ...lateFeesOptions,
                    template_id: values.template_id?.id || values.template_id,
                  },
                  callback: () => {
                    dialogHide(DialogShowId.CUSTOM);
                    callbackAction();
                  },
                });
              }}
              invoiceActionsData={invoiceActionData}
              initialValues={fullInvoice.sending_options_attributes}
              invoice={{
                ...fullInvoice,
                debtor_attributes: {
                  ...fullInvoice.debtor_attributes,
                  emails: [
                    ...fullInvoice.debtor_attributes.emails,
                    ...fullInvoice.sending_options_attributes.emails,
                  ],
                },
              }}
            />
          ),
        }),
    });
  };

  const additionalReminder = () => {
    if (isInactive('additional_reminder') || !canSendByPost) return;

    if (invoice.debtor_attributes) {
      additionalReminderAction(invoice);
    } else {
      invoiceActions.detail({
        id: invoice.id,
        callback: ({ data }) => {
          additionalReminderAction(treatInvoice(data));
        },
      });
    }
  };

  const editClient = (debtor) => {
    editClient({
      client: debtor,
      callback: callbackAction,
    });
  };

  const sendFormalNotice = (items) => (data) => {
    invoiceActions.actionsFormalNotice({
      data: {
        ...data,
        ...items,
      },
      id: invoice.id,
      callback: () => {
        dialogHide(DialogShowId.CUSTOM);
        callbackAction();
      },
    });
  };

  const openFormalNoticeModal = (selectedInvoice: Invoice) => {
    selectGroupedInvoices({
      type: 'formal_notice',
      onSubmit: ({ invoiceActionData, ...items }) =>
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.LARGE,
          keepMountOnExit: true,
          title: t(i18nKeys.FORM.FORMAL_NOTICE.CONFIRM),
          children: (
            <FormalNotice
              invoiceActionData={invoiceActionData}
              invoice={selectedInvoice}
              items={items}
              onSubmit={sendFormalNotice(items)}
            />
          ),
        }),
    });
  };

  const formalNotice = () => {
    if (isInactive('formal_notice') || !canSendByPost) return;
    sideMenuHide();

    if (invoice.type === 'actionable_invoice') {
      invoiceActions.detail({
        id: invoice.id,
        callback: ({ data }) => {
          openFormalNoticeModal(treatInvoice(data));
        },
      });
    } else {
      openFormalNoticeModal(invoice);
    }
  };

  const markAsToBeProcessed = () => {
    sideMenuHide();
    invoiceActions.actionsMarkAsToBeProccesed({
      id: invoice.id,
      callback: callbackAction,
    });
  };

  const openGcollectFlow = (
    data: { third_party_company_id: number },
    additionalInvoiceIds: Array<string>,
  ) => {
    dialogHide(DialogShowId.CUSTOM);
    modals.open({
      title: 'Gcollect',
      centered: true,
      children: (
        <GcollectModal
          gcollectId={data.third_party_company_id}
          invoiceIds={[invoice.id]}
          additionalInvoiceIds={additionalInvoiceIds.map(Number)}
        />
      ),
    });
  };

  const openCallCenterForm = (fullInvoice) => {
    sideMenuHide();

    selectGroupedInvoices({
      type: 'callcenter',
      onSubmit: ({ additional_invoices }) => {
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.SMALL,
          title: t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_CALLCENTER),
          children: (
            <SelectThirdPartyForm
              thirdParties={thirdParties!}
              editClient={() => {
                editClient(fullInvoice.debtor_attributes);
                dialogHide(DialogShowId.CUSTOM);
              }}
              invoice={fullInvoice}
              selectedThirdPartyType={ThirdPartyType.CALL_CENTER}
              onSubmit={confirmThirdParty(additional_invoices)}
            />
          ),
        });
      },
    });
  };

  const callCenter = () => {
    if (invoice.debtor_attributes) {
      openCallCenterForm(invoice);
    } else {
      invoiceActions.detail({
        id: invoice.id,
        callback: ({ data }) => {
          openCallCenterForm(treatInvoice(data));
        },
      });
    }
  };

  const lawyer = () => {
    if (!invoice.has_pdf) {
      openImportOriginalInvoiceModal();
    } else {
      sideMenuHide();
      selectGroupedInvoices({
        type: ThirdPartyType.LAWYER,
        onSubmit: ({ additional_invoices }) =>
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            keepMountOnExit: true,
            title: t(i18nKeys.FORM.LAWYER_TITLE),
            children: (
              <SelectThirdPartyForm
                invoice={invoice}
                selectedThirdPartyType={ThirdPartyType.LAWYER}
                thirdParties={thirdParties!}
                onSubmit={confirmThirdParty(additional_invoices)}
              />
            ),
          }),
      });
    }
  };

  const debtCollector = () => {
    if (!invoice.has_pdf) {
      openImportOriginalInvoiceModal();
    } else {
      sideMenuHide();
      selectGroupedInvoices({
        type: ThirdPartyType.DEBT_COLLECTOR,
        onSubmit: ({ additional_invoices = [] }) =>
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.SMALL,
            keepMountOnExit: true,
            title: t(i18nKeys.FORM.DEBT_COLLECTOR_TITLE),
            children: (
              <SelectThirdPartyForm
                invoice={invoice}
                selectedThirdPartyType={ThirdPartyType.DEBT_COLLECTOR}
                thirdParties={thirdParties!}
                onSubmit={confirmThirdParty(additional_invoices)}
                onSubmitGcollect={(data) => openGcollectFlow(data, additional_invoices)}
              />
            ),
          }),
      });
    }
  };

  const sendBailiff = (values: any) => {
    dialogHide(DialogShowId.CUSTOM);
    values.contested = !values.notContested;

    invoiceActions.actionsThirdParty({
      id: invoice.id,
      data: values,
      callback: callbackAction,
    });
  };

  const bailiff = () => {
    if (!invoice.has_pdf) {
      openImportOriginalInvoiceModal();
    } else {
      sideMenuHide();
      selectGroupedInvoices({
        type: 'bailiff',
        text: i18nKeys.FORM.CHOOSE_INVOICES.BAILIFF_TITLE,
        onSubmit: ({ additional_invoices }) =>
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            keepMountOnExit: true,
            title: t(i18nKeys.FORM.CONFIRM_BAILIFF.BAILIFF_CHOICE),
            children: (
              <SelectThirdPartyForm
                thirdParties={thirdParties!}
                selectedThirdPartyType={ThirdPartyType.BAILIFF}
                invoice={invoice}
                onSubmit={(chosenBailiff: { third_party_company_id: Id }) => {
                  showDialog({
                    id: DialogShowId.CUSTOM,
                    size: DialogShowSize.MEDIUM,
                    keepMountOnExit: true,
                    title: t(i18nKeys.FORM.CONFIRM_BAILIFF.IS_INVOICE_CONTESTABLE),
                    children: (
                      <ConfirmBailiffForm
                        bailiffId={chosenBailiff.third_party_company_id}
                        onSubmit={(values) =>
                          sendBailiff({
                            ...chosenBailiff,
                            ...values,
                            additional_invoices,
                          })
                        }
                      />
                    ),
                  });
                }}
              />
            ),
          }),
      });
    }
  };

  const selectThirdPartyType = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.FORM.CHOOSE_THIRD_PARTY_TYPE.TITLE),
      children: (
        <ChooseThirdPartyTypeForm
          inheritedThirdParties={thirdParties}
          invoices={[invoice]}
          onSubmit={(selectedThirdPartType) => {
            switch (selectedThirdPartType) {
              case ThirdPartyType.CALL_CENTER:
                callCenter();
                break;
              case ThirdPartyType.LAWYER:
                lawyer();
                break;
              case ThirdPartyType.BAILIFF:
                bailiff();
                break;
              case ThirdPartyType.DEBT_COLLECTOR:
                debtCollector();
                break;
              default:
                break;
            }
          }}
        />
      ),
    });
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown
        dropdownContentStyle={{ top: topElement }}
        selectorClass={selectorClass}
        contentClass={styles('content-class')}
        className={styles('InvoiceActions', className)}
        selectorContent={<div onClick={enableRequest}>{item}</div>}
        toggleContent
        sideMenuInMobile
        sideMenuTitle={t(i18nKeys.INVOICE.SELECT_ACTION)}
      >
        <>
          {/* ADDITIONAL REMINDER */}
          <div
            className={`dropdown-item ${styles(
              (isInactive('additional_reminder') || !canSendByPost) && 'disabled',
            )}`}
            onClick={additionalReminder}
          >
            <div className={styles('header')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_REMINDER)}
            </div>
            {!canSendByPost && (
              <div className={styles('warn-message')}>
                {t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                  date: formattedDate(
                    constants.external_services.postgreen.unavailable_until!,
                    currentLang,
                  ),
                })}
              </div>
            )}
          </div>
          {/* END ADDITIONAL REMINDER */}

          {/* FORMAL NOTICE */}
          <div
            className={`dropdown-item ${styles(
              (isInactive('formal_notice') || !canSendByPost) && 'disabled',
            )}`}
            onClick={isInactive('formal_notice') || !canSendByPost ? () => {} : formalNotice}
          >
            <div className={styles('header')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_FORMAL_NOTICE)}
            </div>
            <div className={styles('sub')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.FORMAL_NOTICE_SEND_BY_MAIL)}
            </div>
            {!canSendByPost && (
              <div className={styles('warn-message')}>
                {t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                  date: formattedDate(
                    constants.external_services.postgreen.unavailable_until!,
                    currentLang,
                  ),
                })}
              </div>
            )}
          </div>
          {/* END FORMAL NOTICE */}

          {/* THIRD PARTIES */}
          <div
            className={`dropdown-item ${styles(
              !thirdParties?.areAnyThirdPartiesAccessible && 'disabled',
            )}`}
            onClick={() => {
              if (!thirdParties?.areAnyThirdPartiesAccessible) return;
              selectThirdPartyType();
            }}
          >
            <div className={styles('header')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.SEND_TO_THIRD_PARTY)}
            </div>
            <div className={styles('sub')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.THIRD_PARTIES)}
            </div>
          </div>
          {/* END THIRD PARTIES */}

          {/* MARK TO BE PROCESSED */}
          <div
            className={`dropdown-item ${styles(
              isInactive('mark_as_to_be_processed') && 'disabled',
            )}`}
            onClick={isInactive('mark_as_to_be_processed') ? () => {} : markAsToBeProcessed}
          >
            <div className={styles('header')}>
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.TAKE_OUT_OF_PROCESS)}
            </div>
            <div className={styles('sub')}>
              {' '}
              {t(i18nKeys.FOLLOW_UP.INVOICE_TO_PROCESS.NO_MORE_AUTOMATIC_ACTIONS)}
            </div>
          </div>
          {/* END MARK TO BE PROCESSED */}
        </>
      </Dropdown>
    </div>
  );
};

import { useMemo } from 'react';
import { i18nKeys, useTranslation } from 'locales';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { IconBuildingBank, IconHistory, IconLayersLinked } from '@tabler/icons-react';

import { Menu } from '../types';

export function useBuildBankAccountMenu(): Menu {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  return useMemo<Menu>(() => {
    const items = [
      {
        path: '/transactions/reconciliation',
        label: t(i18nKeys.NAV.RECONCILIATION),
        Icon: IconLayersLinked,
      },
      {
        path: '/transactions/history',
        label: t(i18nKeys.NAV.PAYMENT_BOOK),
        Icon: IconHistory,
      },
    ];

    return {
      parent: null,
      label: t(i18nKeys.COMMON.BANK_ACCOUNT),
      Icon: IconBuildingBank,
      isHidden: company?.banking_method === 'no_bank',
      items,
    };
  }, [company, t]);
}

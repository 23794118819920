import { useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Checkbox, RadioButton } from 'shared/io';
import { useGetConstants } from 'shared/utils/selectors';
import { appActions } from 'store/app/app.actions';
import { dialogHide, DialogShowId } from 'store/view/view.actions';

import styleIdentifiers from './ExportInvoices.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function ExportInvoices() {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const constants = useGetConstants();
  const customVariables = company.custom_variables_attributes || [];

  const filteredCustomVariables = customVariables
    .filter((customVar) => customVar.model_type === 'invoice')
    .map((customVar) => ({
      description: customVar.name,
      value: customVar.column_name,
    }));

  const debtorCustomVariables = customVariables
    .filter((customVar) => customVar.model_type === 'debtor')
    .map((customVar) => ({
      description: customVar.name,
      value: `debtor_${customVar.column_name}`,
    }));

  const [checkboxWrappers, setCheckboxWrappers] = useState<any>({
    selectAll: false,
  });

  const [{ exportFields, wrappers }] = useState({
    exportFields: constants.invoice_export_columns.debtor_fields.concat(
      constants.invoice_export_columns.invoice_fields,
      filteredCustomVariables,
    ),
    wrappers: [
      {
        name: 'selectInvoice',
        fields: constants.invoice_export_columns.invoice_fields,
        text: t(i18nKeys.COMMON.INVOICE),
      },
      {
        name: 'selectDebtor',
        fields: constants.invoice_export_columns.debtor_fields,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.CLIENT_DATA),
      },
      {
        name: 'selectCustomVariable',
        fields: filteredCustomVariables,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.CUSTOM_DATA),
      },
      {
        name: 'selectDebtorCustomVariable',
        fields: debtorCustomVariables,
        text: t(i18nKeys.INVOICING.EXPORT_CSV.DEBTOR_CUSTOM_DATA),
      },
    ],
  });

  const form = useForm<{
    type: string;
    attributes: Record<string, boolean>;
    export_with_filters: boolean;
  }>({
    shouldUnregister: true,
    defaultValues: {
      export_with_filters: true,
      type: 'csv',
    },
  });

  const { getValues, register, watch, reset, control } = form;

  const resetForm = (fields, value) => {
    if (value !== undefined) {
      const formValues: any = getValues();
      const resetValues = {
        attributes: {},
      };
      for (const field of fields) {
        resetValues.attributes[field.value] = value;
      }
      reset({
        ...formValues,
        attributes: {
          ...formValues.attributes,
          ...resetValues.attributes,
        },
      } as any);
    }
  };

  const setCheckFields =
    (type: 'selectAll' | 'selectDebtor' | 'selectInvoice') => (value: boolean) => {
      resetForm(
        type === 'selectAll' ? exportFields : wrappers.find(({ name }) => name === type)!.fields,
        value,
      );

      const wrapperValue = {};

      wrappers.forEach(({ name }) => {
        wrapperValue[name] = type === 'selectAll' || type === name ? value : checkboxWrappers[name];
      });

      setCheckboxWrappers({
        selectAll: type === 'selectAll' ? value : checkboxWrappers.selectAll,
        ...wrapperValue,
      });
    };

  const type = watch('type');

  const submit = () => {
    dialogHide(DialogShowId.CUSTOM);
    const data = getValues();

    appActions.export({
      data: {
        ...data,
        ...(data.export_with_filters === true ? qs.parse(location.search) : {}),
        view_id: location.hash.slice(1),
        resource_type: 'invoice',
      },
      noLoading: true,
    });
  };

  return (
    <FormProvider {...form}>
      <div className={styles('export-invoices')}>
        <Controller
          control={control}
          name="export_with_filters"
          render={({ field: { ref, value, ...values } }) => (
            <RadioButton
              {...values}
              className={styles('export-mode-container')}
              items={[
                {
                  value: false,
                  label: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_ALL_INVOICE),
                },
                {
                  value: true,
                  label: t(i18nKeys.INVOICING.EXPORT_CSV.EXPORT_FILTERED_INVOICE),
                },
              ]}
            />
          )}
        />

        <Controller
          control={control}
          name="type"
          render={({ field: { ref, value, ...values } }) => (
            <RadioButton
              {...values}
              className={styles('export-mode-container')}
              items={[
                { value: 'pdf', label: 'PDF' },
                { value: 'ubl', label: 'UBL' },
                { value: 'csv', label: 'CSV' },
              ]}
            />
          )}
        />

        {type === 'csv' && (
          <>
            <div className={styles('summary')}>
              {t(i18nKeys.INVOICING.EXPORT_CSV.PLEASE_SELECT_FIELDS)}
            </div>

            <div>
              <Checkbox
                checked={checkboxWrappers.selectAll}
                onChange={setCheckFields('selectAll')}
                label={t(i18nKeys.FORM.SELECT_ALL)}
                name="selectAll"
              />
            </div>

            {wrappers.map(({ fields, name, text }) =>
              fields.length === 0 ? (
                ''
              ) : (
                <div key={name}>
                  <div className={styles('subtitle')}>
                    <Checkbox
                      checked={checkboxWrappers[name]}
                      noMargin
                      name={name}
                      onChange={() => setCheckFields(name as any)(!checkboxWrappers[name])}
                    />
                    {text}
                  </div>
                  <div className={styles('atttribute-list')}>
                    {fields.map((field) => (
                      <div key={field.value} className={styles('attribute-item')}>
                        <Checkbox
                          register={register(`attributes[${field.value}]` as any)}
                          watch={watch}
                          label={field.description}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ),
            )}
          </>
        )}

        <div className={styles('button-wrapper')}>
          <Button label={t(i18nKeys.DOWNLOAD)} onClick={submit} />
        </div>
      </div>
    </FormProvider>
  );
}

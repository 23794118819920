import { useEffect, useState } from 'react';
import { useCreateReason, useCreateTaskType, useGetReasons, useGetTaskTypes } from 'api';
import { CreateReasonBody, CreateTaskTypeBody } from 'api/models';
import { ReasonForm } from 'app/Private/Reasons/ReasonForm/ReasonForm';
import { TaskTypeForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import EditCustomVariable from 'shared/action-component/EditCustomVariable';
import { Icon, IconName } from 'shared/components/Icon';
import Tabs from 'shared/components/Tabs';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { appConstants } from 'store/app/app.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { CustomizationSettingsCustomVariables } from './CustomVariables/CustomizationSettingsCustomVariables';
import { CustomizationsSettingsReasons } from './Reasons/CustomizationsSettingsReasons';
import { CustomizationsSettingsTaskTypes } from './TaskTypes/CustomizationsSettingsTaskTypes';

import styleIdentifiers from './CustomizationsSettings.module.scss';

const styles = classNames.bind(styleIdentifiers);

export const CustomizationSettings = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { company } = useLoadCompanyConfiguration();

  const { can_use_tasks, can_use_custom_variables } = company.package;

  const dispatch = useDispatch();

  const { data: taskTypesListResponse, refetch } = useGetTaskTypes();
  const { refetch: refetchReasons } = useGetReasons();

  const { mutate: createTaskType } = useCreateTaskType();
  const { mutate: createReason } = useCreateReason();

  useEffect(() => {
    if (taskTypesListResponse) {
      const { data } = taskTypesListResponse;

      dispatch({
        type: appConstants.constants.result,
        status: 'fulfilled',
        payload: {
          task_types: data.map((e) => ({
            description: e.attributes.name,
            value: e.id,
          })),
        },
      });
    }
  }, [taskTypesListResponse, dispatch]);

  const tabItems = [
    {
      title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.CUSTOM_VARIABLES),
      path: '/settings/customization/custom-variables',
      active: can_use_custom_variables,
      children: <CustomizationSettingsCustomVariables />,
      action: () => {
        EditCustomVariable({
          customVariable: undefined,
        });
      },
    },
    {
      title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.TASK_TYPES),
      path: '/settings/customization/task-types',
      active: can_use_tasks,
      children: <CustomizationsSettingsTaskTypes />,
      action: () =>
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.SMALL,
          title: t(i18nKeys.ADD_TASK_TYPE),
          children: (
            <TaskTypeForm
              onCancel={() => dialogHide(DialogShowId.CUSTOM)}
              onSubmit={(data: CreateTaskTypeBody) =>
                createTaskType(
                  { data },
                  {
                    onSuccess: () => {
                      refetch();
                      dialogHide(DialogShowId.CUSTOM);
                    },
                  },
                )
              }
            />
          ),
        }),
    },
    {
      title: t(i18nKeys.SETTINGS.CUSTOMIZATION.TABS.REASONS),
      path: '/settings/customization/reasons',
      active: true,
      children: <CustomizationsSettingsReasons />,
      action: () =>
        showDialog({
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.SMALL,
          title: t(i18nKeys.ADD_REASON),
          children: (
            <ReasonForm
              onCancel={() => dialogHide(DialogShowId.CUSTOM)}
              onSubmit={(data: CreateReasonBody) =>
                createReason(
                  { data },
                  {
                    onSuccess: () => {
                      refetchReasons();
                      dialogHide(DialogShowId.CUSTOM);
                    },
                  },
                )
              }
            />
          ),
        }),
    },
  ];

  const [tabIndex, setTabIndex] = useState(tabItems.map((e) => e.path).indexOf(location.pathname));

  return (
    <div className={styles('customizations')}>
      <PageTitle>{t(i18nKeys.NAV.CUSTOMIZATION)}</PageTitle>
      <Tabs
        className={styles('customizations__tabs')}
        tabIndex={tabIndex}
        items={tabItems.map(({ active, action, title }, i) => (
          <div className={styles('tab-item')}>
            <h3>{title}</h3>
            {action && i === tabIndex && (
              <div
                className={styles('add-item-button')}
                title={!active ? t(i18nKeys.NOT_INCLUDED) : undefined}
              >
                <Icon
                  name={IconName.PLUS}
                  className={styles(!active && 'disabled')}
                  onClick={() => {
                    if (active) {
                      action();
                    }
                  }}
                />
              </div>
            )}
          </div>
        ))}
        noBody
        onChange={(e) => {
          setTabIndex(e);
          history.push(tabItems[e].path);
        }}
      />
      {tabItems[tabIndex].children}
    </div>
  );
};

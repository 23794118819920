import { ReactNode, Reducer, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { anyPass, clone, isEmpty, isNullish, omitBy } from 'remeda';
import { Icon, IconName } from 'shared/components/Icon';
import FiltersSideMenu from 'shared/forms/FiltersSideMenu';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { useIsMobile } from 'shared/hooks/utils';
import { Button, ButtonColor, Input } from 'shared/io';
import { treatTableViews } from 'shared/serializer';
import { getEnvIcon } from 'shared/utils/environment';
import { useGetConstants, useGetViewsByResource } from 'shared/utils/selectors';
import { formattedDate, queryParamParser, reducerState, removeAttributes } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuShow,
} from 'store/view/view.actions';
import { Id } from 'types';
import { Filters } from 'types/genericType';
import { ResourceType } from 'types/resource-types';
import { Pagination } from 'types/storeTypes';
import { TableFilter } from 'types/views';

import { Skeleton } from '@mantine/core';

import ChooseColumnModal from '../ChooseColumnModal';
import Tooltip from '../Tooltip';

import { TableFilters } from './TableFilters/TableFilters';
import AddNewView from './AddNewView';
import { quickSearchKey } from './CustomTable.utils';
import CustomTableTitle from './CustomTableTitle';
import TableFooter from './TableFooter';
import TableItem from './TableItem';

import styleIdentifiers from './customTable.module.scss';

const styles = classNames.bind(styleIdentifiers);

interface DisplayFilter {
  key: string;
  name: string;
  text: string;
  // For pairs of filters (min/max, start/end), this hack allows us to remove both at the same time when users delete the filter
  pairedKey?: string;
}

type Props = {
  actions?: ReactNode;
  actionsCol?: Function;
  callbackAction: Function;
  className?: string;
  currentPage?: number;
  handleFilters?: (newFilters: Filters<any>, currentFilters: Filters<any>) => false | Filters<any>;
  isLoading?: boolean;
  items: any[];
  loadDataGetView?: boolean;
  loadMore?: Function;
  maxHeight?: string;
  noCheckbox?: boolean;
  noFooter?: boolean;
  noMargin?: boolean;
  noResultMessage?: string;
  noShadow?: boolean;
  onChangeSelectionCallback?: (selectedItems: Array<unknown>) => void;
  onChangeSelectItemsAcrossAllPagesCallback?: (value: boolean) => void;
  onClickRow: Function;
  pagination?: Pagination;
  tableName: ResourceType;
  title?: string;
};

type CustomTableState = {
  filters: any;
  availableFilters: TableFilter[];
  filtersName: {
    [key in string]: any;
  };
};

const initState: (filters: any) => CustomTableState = (filters = {}) => ({
  filters: {
    ...filters,
    sort_by: '',
  },
  availableFilters: [],
  filtersName: {},
});

export default function CustomTableViews({
  actions,
  actionsCol,
  callbackAction,
  className,
  currentPage,
  handleFilters,
  isLoading,
  items,
  loadDataGetView = true,
  loadMore,
  maxHeight,
  noCheckbox,
  noFooter,
  noMargin,
  noResultMessage,
  noShadow,
  onChangeSelectionCallback,
  onChangeSelectItemsAcrossAllPagesCallback,
  onClickRow,
  pagination,
  tableName,
  title,
}: Props) {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();

  const history = useHistory();
  const location = useLocation();
  const profile = useProfile();

  const companyName = company.name;
  const constants = useGetConstants();
  const viewsByResource = useGetViewsByResource();
  const isMobile = useIsMobile();

  const [{ filters, availableFilters, filtersName }, setState] = useReducer<
    Reducer<CustomTableState, any>
  >(reducerState, initState(qs.parse(location.search, { arrayFormat: 'comma' })));
  const [timeoutDebounce, setTimeoutDebounce] = useState<NodeJS.Timeout>();
  const [areAllItemsAcrossPagesSelected, setAreAllItemsAcrossPagesSelected] = useState(false);
  const [selectedIds, setSelectedIds] = useState<Array<Id>>([]);

  const currentView = useMemo(
    () => viewsByResource[tableName]?.find((view) => view.id === (location.hash.slice(1) as any)),
    [viewsByResource, location, tableName],
  );

  const isRemindersView = currentView?.view_type === 'postponable_invoices';
  const isFetching = isLoading && !isEmpty(items);
  const selectedItems = useMemo(
    () => items.filter((item) => selectedIds.includes(item.id)),
    [selectedIds],
  );

  const onChangeSelectionCallbackMemo = useCallback((val) => onChangeSelectionCallback?.(val), []);
  const onChangeSelectItemsAcrossAllPagesCallbackMemo = useCallback(
    (val) => onChangeSelectItemsAcrossAllPagesCallback?.(val),
    [],
  );

  // =============== EFFECTS ======================

  useEffect(() => {
    if (!isEmpty(selectedIds)) setSelectedIds([]);
  }, [items]);

  useEffect(
    () => onChangeSelectionCallbackMemo?.(selectedItems),
    [selectedItems, onChangeSelectionCallbackMemo],
  );

  useEffect(() => {
    onChangeSelectItemsAcrossAllPagesCallbackMemo?.(areAllItemsAcrossPagesSelected);
  }, [areAllItemsAcrossPagesSelected, onChangeSelectItemsAcrossAllPagesCallbackMemo]);

  useEffect(() => {
    document.title = `${getEnvIcon()}${companyName} - ${title}`;

    return function cleanup() {
      document.title = `${getEnvIcon()}${companyName ?? 'Recovr'}`;
    };
  }, [title, companyName]);

  // Array.every() returns true on empty arrays
  const areAllItemsOnThisPageSelected =
    !isEmpty(items) && items.every((item) => selectedIds.includes(item.id));

  const filtersToUrl = (view) => {
    const newFilters = {};
    view.filter_fields.forEach((filter) => {
      if (
        filter.applied_filter.operator === 'null' ||
        filter.applied_filter.operator === 'not_null'
      ) {
        newFilters[`${filter.name}_handler`] = filter.applied_filter.operator;
      } else {
        if (filter.filter_type === 'date') {
          newFilters[`${filter.name}_start`] = filter.applied_filter.value[0];
          newFilters[`${filter.name}_end`] = filter.applied_filter.value[1];
        } else if (filter.filter_type === 'number') {
          newFilters[`min_${filter.name}`] = filter.applied_filter.value[0];
          newFilters[`max_${filter.name}`] = filter.applied_filter.value[1];
          delete newFilters[filter.name];
        } else {
          newFilters[filter.name] = filter.applied_filter.value;
        }
        if (filter.applied_filter.operator) {
          newFilters[`${filter.name}_operator`] = filter.applied_filter.operator;
        }
      }
    });
    return newFilters;
  };

  useEffect(() => {
    if (timeoutDebounce) {
      clearTimeout(timeoutDebounce);
    }
    if (currentView) {
      const timeout = setTimeout((): void => {
        loadData();
      }, 500);

      setTimeoutDebounce(timeout);
    }
  }, [location]);

  // This function executes on initial load to dispatch the page towards the right view
  // and upon closing the view settings modal to ensure that any changes are reflected
  const loadOrRefreshViews = () => {
    appActions.getViews({
      data: {
        view_type: tableName,
      },
      callback: ({ data }) => {
        const views = treatTableViews(data);

        const defaultViewId = views.find((view) => view.is_default)!.id;
        const favoriteViewId = views.find((view) => view.is_favorite)?.id;
        const currentViewId = location.hash.replace('#', '');
        const allViewIds = views.map((table) => table.id);

        const viewToNavigateToId = (() => {
          // we might have deleted the view we were on in the settings
          if (currentViewId !== '' && allViewIds.includes(currentViewId)) return currentViewId;
          if (favoriteViewId) return favoriteViewId;
          return defaultViewId;
        })();

        const view = views.find((_view) => _view.id === viewToNavigateToId);

        const urlFilters = Object.entries({
          ...filtersToUrl(view),
          ...Object.fromEntries(new URLSearchParams(location.search)),
        });

        history.push(
          `${location.pathname}${urlFilters.reduce(
            (acc, [key, value]) => `${acc}${key}=${value}&`,
            '?',
          )}${`#${viewToNavigateToId}`}`,
        );
      },
    });

    appActions.getFilters({
      data: {
        view: tableName,
      },
      callback: ({ data }) => {
        const newFilters = data.map((item) => removeAttributes(item));
        setState({
          availableFilters: newFilters,
        });
      },
    });
  };

  useEffect(loadOrRefreshViews, [location.hash]);

  useEffect(() => {
    if (!currentView) return;

    const newFilters = {
      ...filtersToUrl(currentView),
      ...Object.fromEntries(new URLSearchParams(location.search)),
    };

    setState({
      filters: newFilters,
    });

    appActions.getFiltersName({
      data: newFilters,
      callback: (newFiltersName) => {
        setState({
          filtersName: newFiltersName,
        });
      },
    });
  }, [currentView, history, tableName, viewsByResource]);

  // Meaning: these carry an id instead of the value that should
  // be displayed in the UI
  // Memoized because it is used in another hook's dependency array
  const apiFilters = [
    'user_id',
    'lawyer_company_id',
    'recovery_plan_id',
    'recovery_step_id',
    'default_invoice_template_id',
    'default_credit_note_template_id',
    'billing_log_id',
    'task_type_id',
    // probably missing: template_id
  ];

  const setPageLimit = (page_limit) => () => {
    applyFilters({ ...filters, page_limit });
  };

  const filtersToShow = useMemo(() => {
    if (availableFilters.length === 0) return [];

    // Some keys belong in pairs and are displayed together, this object keeps track of them so
    // they are not displayed twice when the second key of the pair is iterated over
    const double = {};
    const tempFiltersToShow: Array<DisplayFilter> = [];

    Object.keys(filters).forEach((key) => {
      if (filters[key] == null || ['sort_order', 'sort_by', 'page'].includes(key)) return;

      if (key.includes('_start') || key.includes('_end')) {
        const fieldName = key.substring(0, key.lastIndexOf('_'));
        if (double[fieldName]) return;

        double[fieldName] = true;

        tempFiltersToShow.push({
          key,
          pairedKey: key.includes('_start') ? `${fieldName}_end` : `${fieldName}_start`,
          name: availableFilters.find((filter) => filter.name === fieldName)!.name_translated,
          text: `${
            filters[`${fieldName}_start`] && filters[`${fieldName}_start`] !== 'null' // <- if this is true, change it on the backend
              ? formattedDate(filters[`${fieldName}_start`], currentLang)
              : '∞'
          } - ${
            filters[`${fieldName}_end`] && filters[`${fieldName}_end`] !== 'null'
              ? formattedDate(filters[`${fieldName}_end`], currentLang)
              : '∞'
          }`,
        });
      } else if (key.includes('_handler')) {
        if (filters[key] === 'null' || filters[key] === 'not_null') {
          const fieldName = key.substring(0, key.lastIndexOf('_'));
          const filter = availableFilters.find((_filter) => _filter.name === fieldName)!;

          tempFiltersToShow.push({
            key,
            name: filter.name_translated,
            text: `-${t(filters[key] === 'null' ? 'EMPTY' : 'FILL')}`,
          });
        }
      } else if (key.includes('min_') || key.includes('max_')) {
        const fieldName = key.substring(4);
        if (double[fieldName]) return;

        double[fieldName] = true;

        tempFiltersToShow.push({
          key,
          pairedKey: key.includes('min_') ? `max_${fieldName}` : `min_${fieldName}`,
          name: availableFilters.find((filter) => filter.name === fieldName)!.name_translated,
          text: `${
            filters[`min_${fieldName}`] && filters[`min_${fieldName}`] !== 'null'
              ? filters[`min_${fieldName}`]
              : '∞'
          } - ${
            filters[`max_${fieldName}`] && filters[`max_${fieldName}`] !== 'null'
              ? filters[`max_${fieldName}`]
              : '∞'
          }`,
        });
      } else if (key === 'page_limit') {
        setPageLimit(filters[key]);
      } else if (key === 'quick_search') {
        tempFiltersToShow.push({
          key,
          name: t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER),
          text: filters[key],
        });
      } else if (!key.includes('_operator')) {
        const filter = availableFilters.find((_filter) => _filter.name === key);

        if (isNullish(filter)) return;

        if (filter.filter_type === 'boolean') {
          tempFiltersToShow.push({
            key,
            name: filter.name_translated,
            text: t(filters[key] === 'true' ? i18nKeys.YES : i18nKeys.NO),
          });
        } else if (filter.filter_type === 'number' && filters.key != null) {
          tempFiltersToShow.push({
            key,
            name: filter.name_translated,
            text: `${filters[`${key}_operator`]} ${filters[key]}`,
          });
        } else if (filter.filter_type === 'select' || filter.filter_type === 'string') {
          let text = '';
          if (key === 'status') {
            text = (Array.isArray(filters[key]) ? filters[key] : filters[key].split(','))
              .map((status) => constants.statuses.find((el) => el.value === status)?.description)
              .join(', ');
          } else {
            text =
              apiFilters.includes(key) && !Array.isArray(filters[key])
                ? filters[key].split(',')
                : filters[key];
          }

          tempFiltersToShow.push({ key, name: filter.name_translated, text });
        }
      }
    });

    return tempFiltersToShow;
  }, [filters, apiFilters, availableFilters, constants, currentLang, setPageLimit, t]);

  if (currentView == null) return null;

  // ================= HANDLERS ====================

  const handleClickCheckbox = (e: Event, itemId: number) => {
    e.stopPropagation();

    if (selectedIds.includes(itemId)) {
      // @ts-ignore
      setSelectedIds((ids) => ids.filter((id) => id !== itemId));
      // @ts-ignore
    } else setSelectedIds((ids) => [...ids, itemId]);
  };

  const setColumn = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <ChooseColumnModal
          currentView={currentView!}
          tableName={tableName}
          callbackAction={callbackAction}
        />
      ),
    });
  };

  const setFiltersName = (key, values) => {
    setState({
      filtersName: {
        ...filtersName,
        [key]: values,
      },
    });
  };

  const emptyQuickSearchField = () => {
    const input = document.querySelector("[data-quick-search='1']") as any;
    if (input) {
      input.value = '';
    }
  };

  const resetFilters = () => {
    emptyQuickSearchField();
    filterObject.reset();
    applyFilters({});
  };

  const updateUrl = (_filters) => {
    history.push({
      pathname: location.pathname,
      search: qs.stringify(_filters, { arrayFormat: 'comma' }),
      hash: location.hash,
    });
  };

  const loadData = () => {
    if (loadDataGetView) {
      appActions.getView({
        // @ts-ignore redux saga action incorrect type
        id: currentView.id,
        data: queryParamParser(qs.parse(location.search)),
        callback: (data) => {
          callbackAction(data, 'fulfilled');
        },
      });
    }
  };

  const applyFilters = (values) => {
    let newFilters: false | Filters<any> = handleFilters ? handleFilters(values, filters) : values;

    if (!newFilters) return;

    newFilters = omitBy(newFilters, anyPass([isNullish, isEmpty]));

    newFilters.page = '1';
    newFilters.page_limit = values.page_limit || filters.page_limit;

    updateUrl(newFilters);

    setState({
      filters: newFilters,
    });
  };

  const setPage = (page) => {
    updateUrl({
      ...filters,
      page: Number(page.selected) + 1,
    });
  };

  const onClickSelectAllOnPage = () => {
    setAreAllItemsAcrossPagesSelected(false);

    const newSelection = areAllItemsOnThisPageSelected ? [] : items.map((item) => item.id);
    setSelectedIds(newSelection);
  };

  const setOrder = (field: string) => () => {
    if (field === 'actions') return;
    if (filters.sort_by !== field) {
      applyFilters({ ...filters, sort_by: field, sort_order: 'asc' });
    } else {
      applyFilters({
        ...filters,
        sort_order: filters.sort_order === 'asc' ? 'desc' : 'asc',
      });
    }
  };

  let filtersNumber = 0;
  // eslint-disable-next-line
  for (const key in filters) {
    if (
      key !== 'page' &&
      key !== 'page_limit' &&
      !key.includes('sort_') &&
      !key.includes('_operator') &&
      !key.includes('_end') &&
      !key.includes('_start') &&
      filters[key]
    )
      filtersNumber++;
  }

  // Egregious piece of software: This object is passed down to the child component responsible for the filters state
  // Upon receiving it, that component populates the empty reset method below with a callback to its own state,
  // which this component can then call.
  // TODO: refactor, extract filter state somewhere sane.
  const filterObject = {
    reset: () => {},
  };

  const openFilters = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <FiltersSideMenu onRemoveFilter={resetFilters}>
          <TableFilters
            filtersAvailable={availableFilters}
            filterObject={filterObject}
            onSubmit={applyFilters}
            initialValues={filters}
            tableName={tableName}
            initialFiltersName={filtersName}
            setFiltersName={setFiltersName}
          />
        </FiltersSideMenu>
      ),
    });
  };

  const saveFilters = () => {
    if (viewsByResource[tableName][0].id === currentView.id) {
      showDialog({
        id: DialogShowId.CUSTOM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.ADD_VIEW),
        children: (
          <AddNewView
            onSubmit={({ name }) => {
              appActions.updateViews({
                data: {
                  views_attributes: [
                    {
                      name,
                      view_type: tableName,
                      filter_fields_attributes: setFiltersView(),
                    },
                  ],
                },
                callback: () => {
                  dialogHide(DialogShowId.CUSTOM);
                  appActions.getViews({
                    data: {
                      view_type: tableName,
                    },
                  });
                },
              });
            }}
          />
        ),
      });
    } else {
      appActions.updateViews({
        data: {
          views_attributes: [
            {
              id: currentView.id,
              filter_fields_attributes: setFiltersView(),
            },
          ],
        },
        callback: () => {
          appActions.getViews({
            data: {
              view_type: tableName,
            },
          });
        },
      });
    }
  };

  const setFiltersView = () => {
    let currentFilters = Object.entries(filters);
    const operators = {};
    const indicesToRemove: any[] = [];

    currentFilters.forEach(([key, value], i) => {
      const index = key.lastIndexOf('_');
      if (key.includes('_operator')) {
        operators[key.substring(0, index)] = value;
        indicesToRemove.push(i);
      } else if (key.includes('_handler')) {
        const fieldName = key.substring(0, index);
        currentFilters.push([fieldName, '']);
        operators[fieldName] = value;
        indicesToRemove.push(i);
      } else if (key.includes('_start')) {
        const fieldName = key.substring(0, index);

        currentFilters.push([
          fieldName,
          [filters[`${fieldName}_start`] || 'null', filters[`${fieldName}_end`] || 'null'],
        ]);

        indicesToRemove.push(i);

        const endIndex = currentFilters.findIndex(([name]) => name === `${fieldName}_end`);
        if (endIndex !== -1) indicesToRemove.push(endIndex);
      } else if (
        key.includes('_end') &&
        !filters[`${key.substring(0, key.indexOf('_end'))}_start`]
      ) {
        const fieldName = key.substring(0, index);
        currentFilters.push([
          fieldName,
          [filters[`${fieldName}_start`] || 'null', filters[`${fieldName}_end`] || 'null'],
        ]);

        indicesToRemove.push(i);

        const pairedFieldIndex = currentFilters.findIndex(([name]) => name === fieldName);

        if (pairedFieldIndex !== -1) indicesToRemove.push(pairedFieldIndex);
      } else if (key.indexOf('min_') + key.indexOf('max_') !== -2) {
        const fieldName = key.substring(4);
        currentFilters.push([
          fieldName,
          [filters[`min_${fieldName}`] || 'null', filters[`max_${fieldName}`] || 'null'],
        ]);
        indicesToRemove.push(i);
      }
    });

    currentFilters = currentFilters.filter((i, index) => !indicesToRemove.includes(index));

    const filterFieldAttributes = currentFilters
      .filter(
        ([name]) =>
          !['sort_order', 'sort_by', 'page', 'page_limit'].includes(name) &&
          name.indexOf('_operator') === -1,
      )
      .map(([name, value]) => ({
        name,
        applied_filter: { operator: operators[name], value },
      }));

    currentView.filter_fields
      .filter(
        (filter_field) =>
          !filterFieldAttributes.some(
            (newFilterField) => (newFilterField as any).name === filter_field.name,
          ),
      )
      .forEach((filterField) => {
        filterFieldAttributes.push({
          ...filterField,
          _destroy: true,
        } as any);
      });

    return filterFieldAttributes;
  };

  const removeFilter = (key, extraKey) => (e) => {
    if (key === 'quick_search') {
      emptyQuickSearchField();
    }

    e.stopPropagation();

    const newFilters = clone(filters);

    newFilters[key] = undefined;
    if (extraKey) newFilters[extraKey] = undefined;

    applyFilters(newFilters);
  };

  const mediumColumns = ['total_tvac', 'remaining_balance'];
  const largeColumns = [
    'status',
    'reference',
    'full_name',
    'debtors__full_name',
    'recovery_step_id',
  ];

  const sizeColumn = (column_name) => {
    if (largeColumns.includes(column_name)) {
      return 400;
    }
    if (mediumColumns.includes(column_name)) {
      return 100;
    }
    return undefined;
  };

  const submit = (quick_search) => {
    const newFilters = { ...filters, quick_search };
    updateUrl(newFilters);
    setState({
      filters: newFilters,
    });
  };

  return (
    <div
      className={styles(
        'CustomTable',
        'views',
        noShadow && 'no-shadow',
        noMargin && 'no-margin',
        className,
      )}
    >
      {title && (
        <CustomTableTitle
          title={title}
          actions={actions}
          tableName={tableName}
          submit={submit}
          invalidateViews={loadOrRefreshViews}
        />
      )}
      <div className={styles('filters')}>
        {!isMobile && (
          <div className={styles('quick-search')}>
            <Input
              noMargin
              quickSearch
              type="text"
              placeholder={t(i18nKeys.FORM.QUICK_SEARCH.PLACEHOLDER)}
              onValueChanged={submit}
            />
            <Tooltip
              icon={IconName.INFO}
              size="14px"
              text={t(i18nKeys.FORM.QUICK_SEARCH.TOOLTIP[quickSearchKey(tableName)])}
            />
          </div>
        )}
        <div className={styles('filters-actions')}>
          <Button
            small
            noMargin
            noShadow
            label={t(i18nKeys.SAVE_FILTERS)}
            color={ButtonColor.GREY}
            onClick={saveFilters}
          />
          {filtersNumber > 0 && (
            <Button
              className={styles('remove-filter', 'filter')}
              noMargin
              small
              noShadow
              iconSize="16px"
              color={ButtonColor.WHITE}
              onClick={resetFilters}
            >
              <Icon name={IconName.FILTER} />
              <Icon name={IconName.SMALL_REMOVE} />
            </Button>
          )}
          <Button
            noMargin
            small
            iconSize="16px"
            noShadow
            color={ButtonColor.WHITE}
            iconLeft={IconName.FILTER}
            onClick={openFilters}
          >
            {filtersNumber > 0 && (
              <div className={styles('text-circle', 'absolute', 'border', 'badge')}>
                {filtersNumber}
              </div>
            )}
            {t(i18nKeys.FILTER)}
          </Button>
        </div>
      </div>
      <div className={styles('filters-container')}>
        {filtersToShow.map(({ name, text, key, pairedKey }) => (
          <div key={key} className={styles('filter-item')} onClick={openFilters}>
            <div className={styles('filter-name')}>{name}:</div>
            <div>
              {Array.isArray(text)
                ? text.map((el) => (filtersName[key] ? filtersName[key][el] : el)).join(', ')
                : text}
            </div>
            <Icon name={IconName.SMALL_REMOVE} onClick={removeFilter(key, pairedKey)} />
          </div>
        ))}
      </div>
      <div className={styles('listing-wrapper')}>
        <div className={styles('card-style', 'card')}>
          <div className={styles('listing', noShadow && 'no-shadow')} style={{ maxHeight }}>
            {currentView && (
              <table>
                <thead style={{ position: 'sticky', zIndex: 1 }}>
                  <tr>
                    <th className={styles('box-wrapper', noCheckbox && 'small')}>
                      <div className={styles('sortable-head', 'small')}>
                        {!noCheckbox && (
                          <div
                            onClick={onClickSelectAllOnPage}
                            className={styles(
                              'box',
                              'checkbox',
                              areAllItemsOnThisPageSelected && 'checked',
                            )}
                          />
                        )}
                      </div>
                      <div className={styles('custom-border')} />
                    </th>
                    {currentView.table_columns.map((item: any, index) => (
                      <th
                        key={item.title || index}
                        className={styles(
                          index === currentView.table_columns.length - 1 && 'last',
                          index === 0 && 'first',
                        )}
                      >
                        <div
                          style={{ width: sizeColumn(item.name) }}
                          className={styles(
                            `${item.name === 'actions' ? 'not-' : ''}sortable-head`,
                          )}
                          onClick={setOrder(item.name)}
                          title={item.name_translated}
                        >
                          {item.name_translated}
                          {filters.sort_by === item.name && (
                            <Icon
                              name={IconName.TAILDOWN}
                              size="15px"
                              className={styles(
                                'arrow',
                                filters.sort_order === 'asc' && 'inverted',
                              )}
                            />
                          )}
                        </div>
                        <div className={styles('custom-border')} />
                      </th>
                    ))}
                    <th key="edit_columns" style={{ width: 40 }} className={styles('last')}>
                      <div className={styles('not-sortable-head')}>
                        <Icon
                          name={IconName.SETTINGS_GEAR}
                          onClick={setColumn}
                          className="edit-column"
                        />
                      </div>
                      <div className={styles('custom-border')} />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {areAllItemsOnThisPageSelected &&
                    (pagination?.total_objects ?? 0) >
                      (filters.page_limit ?? profile.preferences.itemsPerPage) && (
                      <tr>
                        <td className={styles('select-all')} colSpan={100}>
                          <div>
                            <div>
                              {t(
                                (areAllItemsAcrossPagesSelected
                                  ? pagination!.total_objects
                                  : items.length) === 1
                                  ? i18nKeys.SELECTED_ELEMENT
                                  : i18nKeys.SELECTED_ELEMENTS,
                                {
                                  count: areAllItemsAcrossPagesSelected
                                    ? pagination!.total_objects
                                    : items.length,
                                },
                              )}
                              <span
                                onClick={() => setAreAllItemsAcrossPagesSelected((value) => !value)}
                              >
                                {t(
                                  (areAllItemsAcrossPagesSelected
                                    ? items.length
                                    : pagination!.total_objects) === 1
                                    ? i18nKeys.SELECT_ELEMENT
                                    : i18nKeys.SELECT_ELEMENTS,
                                  {
                                    count: areAllItemsAcrossPagesSelected
                                      ? items.length
                                      : pagination!.total_objects,
                                  },
                                )}
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )}

                  {(() => {
                    if (isLoading || isFetching) {
                      return Array(currentView.table_columns.length)
                        .fill(0)
                        .map((_, rowIndex) => (
                          <tr key={rowIndex}>
                            <td />
                            {Array(currentView.table_columns.length)
                              .fill(0)
                              .map((__, colIndex) => (
                                <td key={colIndex}>
                                  <Skeleton h="25px" my={5} radius="md" />
                                </td>
                              ))}
                          </tr>
                        ));
                    }

                    if (isEmpty(items)) {
                      return (
                        <tr style={{ position: 'relative', height: '65px' }}>
                          <div
                            className={styles('no-result')}
                            style={{ position: 'absolute', width: '100%' }}
                          >
                            {filtersNumber > 0
                              ? t(i18nKeys.NO_RESULT_FOR_THIS_SEARCH)
                              : (noResultMessage ?? t(i18nKeys.NO_RESULT))}
                          </div>
                        </tr>
                      );
                    }

                    return items.map((item, key) => (
                      <TableItem
                        key={key}
                        onClickRow={onClickRow(item)}
                        actionsCol={actionsCol}
                        item={item}
                        columns={currentView.table_columns}
                        noCheckbox={noCheckbox}
                        groupedInvoices={item?.attributes?.invoices_references}
                        isRemindersView={isRemindersView}
                        isSelected={selectedIds.includes(item.id)}
                        onClickCheckbox={(e) => {
                          handleClickCheckbox(e, item.id);
                        }}
                      />
                    ));
                  })()}
                </tbody>
              </table>
            )}
          </div>
          {!noFooter && (
            <TableFooter
              currentPage={currentPage}
              pagination={pagination!}
              currentPageLimit={filters.page_limit}
              setPage={setPage}
              itemsLength={items.length}
              setPageLimit={setPageLimit}
            />
          )}
        </div>
      </div>
      {loadMore && pagination && pagination.current_page < pagination.last_page && (
        <div className={styles('buttons')}>
          <Button
            noMargin
            color={ButtonColor.BLUE}
            label={t(i18nKeys.SHARED.CUSTOM_TABLE.LOAD_MORE)}
            onClick={() => loadMore(pagination)}
          />
        </div>
      )}
    </div>
  );
}

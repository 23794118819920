import { useEffect } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider } from 'react-hook-form';
import UserInfo from 'shared/components/UserInfo';
import { useLoadCompanyConfiguration, useLoadLightUsers, useProfile } from 'shared/hooks';
import { AmountFields, CustomSelect, DateSelector, Input } from 'shared/io';
import { useFilterForm, useSearchDebtors } from 'shared/utils/hooks';
import { currencySymbol } from 'shared/utils/normalization';
import { useGetConstants } from 'shared/utils/selectors';
import { onSubmitFunction } from 'types/react-hook-form';

import styleIdentifiers from './ThirdpartyCasesFiltersForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: any;
  filterObject: any;
};

export default function ThirdpartyCasesFiltersForm({
  initialValues,
  onSubmit,
  filterObject,
}: Props) {
  const { t } = useTranslation();
  const constants = useGetConstants();
  const { company } = useLoadCompanyConfiguration();
  const profile = useProfile();

  const { debtors, getDebtors } = useSearchDebtors();
  const { users = [] } = useLoadLightUsers();

  const { form, resetForm, submit } = useFilterForm({
    onSubmit,
    initialValues,
    fieldToRegister: [],
  });
  const { handleSubmit, register, setValue } = form;

  useEffect(() => {
    if (filterObject) {
      filterObject.reset = () => {
        resetForm();
      };
    }
  }, [filterObject, resetForm]);

  return (
    <FormProvider {...form}>
      <form className={styles('ThirdpartyCasesFiltersForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('container-fields')}>
          <div className={styles('fields')}>
            <div className={styles('large')}>
              <Input
                register={register('filenumber')}
                noMargin
                withBorder
                defaultValue=""
                shadow
                type="text"
                label={t(i18nKeys.THIRD_PARTY_CASES.REFERENCE)}
                onValueChanged={submit}
              />
            </div>
            <div className={styles('large')}>
              <Controller
                defaultValue=""
                name="debtor_name"
                render={() => (
                  <CustomSelect
                    CustomInput={Input}
                    noMargin
                    size="small"
                    load={getDebtors}
                    onInputChange={submit}
                    items={debtors}
                    name="debtor_name"
                    keyText="full_name"
                    keyValue="full_name"
                    withBorder
                    noBorder
                    noArrow
                    shadow
                    label={t(i18nKeys.COMMON.CLIENT)}
                    onValueChanged={submit}
                  />
                )}
              />
            </div>
            <div className={styles('large')}>
              <Input
                register={register('invoice_reference')}
                noMargin
                withBorder
                defaultValue=""
                shadow
                type="text"
                label={t(i18nKeys.INVOICE_NUMBER)}
                onValueChanged={submit}
              />
            </div>

            <Controller
              defaultValue=""
              name="status"
              render={() => (
                <CustomSelect
                  selectClassName={styles('large')}
                  keyText="description"
                  keyValue="value"
                  size="small"
                  name="status"
                  noMargin
                  noBorder
                  withBorder
                  shadow
                  items={constants.tp_case_statuses}
                  label={t(i18nKeys.STATUS)}
                  onValueChanged={submit}
                  removeAll={t(i18nKeys.FORM.SELECT_ALL)}
                />
              )}
            />

            <Controller
              defaultValue=""
              name="unread_messages"
              render={() => (
                <CustomSelect
                  selectClassName={styles('large')}
                  keyText="description"
                  keyValue="value"
                  size="small"
                  name="unread_messages"
                  noMargin
                  noBorder
                  withBorder
                  shadow
                  items={[
                    {
                      description: t(i18nKeys.YES),
                      value: 'true',
                    },
                    {
                      description: t(i18nKeys.NO),
                      value: 'false',
                    },
                  ]}
                  label={t(i18nKeys.FORM.THIRD_PARTY_CASES_FILTERS.UNREAD_MESSAGES)}
                  onValueChanged={submit}
                  removeAll={t(i18nKeys.FORM.SELECT_ALL)}
                />
              )}
            />

            <AmountFields
              className={styles('spacing')}
              name="total_tvac"
              label={t(i18nKeys.INVOICING.TOTAL_VAT_PRICE_CURRENCY, {
                text: currencySymbol(undefined, company),
              })}
              onChange={submit}
            />
          </div>
          <div className={styles('fields')}>
            <Controller
              defaultValue=""
              name="case_type"
              render={() => (
                <CustomSelect
                  selectClassName={styles('large')}
                  keyText="description"
                  keyValue="value"
                  size="small"
                  name="case_type"
                  noMargin
                  noBorder
                  withBorder
                  shadow
                  items={constants.tp_case_types}
                  label={t(i18nKeys.CASE_TYPE)}
                  onValueChanged={submit}
                />
              )}
            />

            <AmountFields
              className={styles('spacing')}
              name="remaining_balance"
              label={t(i18nKeys.BALANCE_CURRENCY, {
                text: currencySymbol(undefined, company),
              })}
              onChange={submit}
            />
            <DateSelector
              className={styles('input', 'date-selector')}
              name="sent_date_after"
              endName="sent_date_before"
              placeholder={t(i18nKeys.OF)}
              endPlaceholder={t(i18nKeys.FORM.TO)}
              withBorder
              shadow
              noMinDate
              label={t(i18nKeys.CREATION_DATE)}
              handleChange={(value) => {
                setValue('sent_date_after', value);
                submit();
              }}
              handleEndChange={(value) => {
                setValue('sent_date_before', value);
                submit();
              }}
            />
            <DateSelector
              className={styles('input', 'date-selector')}
              name="update_date_after"
              endName="update_date_before"
              placeholder={t(i18nKeys.OF)}
              endPlaceholder={t(i18nKeys.FORM.TO)}
              withBorder
              shadow
              noMinDate
              label={t(i18nKeys.LAST_UPDATE)}
              handleChange={(value) => {
                setValue('update_date_after', value);
                submit();
              }}
              handleEndChange={(value) => {
                setValue('update_date_before', value);
                submit();
              }}
            />
            {(profile.isAdmin || profile.roles.includes('all_invoices')) && (
              <Controller
                defaultValue=""
                name="account_manager_id"
                render={() => (
                  <CustomSelect
                    noMargin
                    selectClassName={styles('xl')}
                    keyValue="id"
                    onValueChanged={submit}
                    withBorder
                    shadow
                    itemRendering={(props) => <UserInfo picture {...props} />}
                    valueRendering={(props) => <UserInfo {...props} inline />}
                    name="account_manager_id"
                    items={users}
                    label={t(i18nKeys.ACCOUNT_MANAGER)}
                  />
                )}
              />
            )}
          </div>
        </div>
      </form>
    </FormProvider>
  );
}

import { Route, Switch, useHistory } from 'react-router';
import { useLoadCompanyConfiguration } from 'shared/hooks';

import { Box } from '@mantine/core';

import { AgedBalance } from './pages/AgedBalance';
import { Overview } from './pages/Overview';

export const Analytics = () => {
  const history = useHistory();
  const { company } = useLoadCompanyConfiguration();

  return (
    <Box w="80%" mx="auto">
      <Switch>
        <Route path="/analytics/aged-balance" component={AgedBalance} />
        <Route path="/analytics" component={Overview} />
      </Switch>
    </Box>
  );
};

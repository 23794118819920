import { useContext, useMemo } from 'react';
import { useCreateTask } from 'api';
import { CreateTaskBody } from 'api/models';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router';
import { InvoiceActionsDropdown } from 'shared/components';
import AYSModal from 'shared/components/AYSModal';
import { IconName } from 'shared/components/Icon';
import { LinkInvoiceForm, LinkInvoiceFormFields } from 'shared/forms/LinkInvoiceForm';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Dropdown } from 'shared/io';
import { PageTitle } from 'shared/layout';
import { isConnectorActive } from 'shared/utils/connector';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
  sideMenuShow,
} from 'store/view/view.actions';
import { CreditNote } from 'types/store/credit-note-state';

import InvoiceDetailContext from '../InvoiceDetail.context';
import InvoiceSendingOptions from '../InvoiceSendingOptions';
import MarkedInvoiceActions from '../MarkedInvoiceActions';

import styleIdentifiers from '../ToHandleDetail.module.scss';

const styles = classNames.bind(styleIdentifiers);

export default function InvoiceDetailHeader() {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const history = useHistory();
  const hasActiveConnector = isConnectorActive();
  const { data, credit, unmatchPayment, reloadInfo } = useContext(InvoiceDetailContext);

  const settleCreditNote = (settle) => () => {
    creditNoteActions.settle({
      id: data!.id,
      data: {
        settle,
      },
      callback: () => {
        creditNoteActions.info({
          id: data!.id,
          noReset: true,
          noLoading: true,
        });
      },
    });
  };

  const linkToInvoices: SubmitHandler<LinkInvoiceFormFields> = (values) => {
    const invoices_to_link = values.invoices_to_link
      .filter((e) => e.amount > 0)
      .filter(
        (e) =>
          !(data as CreditNote).matched_payments_attributes.some(
            (el) => Number(el.invoice_attributes.id) === Number(e.invoice_id),
          ),
      )
      .map((e) => ({
        id: e.invoice_id,
        amount: e.amount,
      }));

    creditNoteActions.linkToInvoices({
      data: {
        id: data.id,
        invoices_to_link,
      },
      callback: () => {
        (reloadInfo as any)();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const linkCreditNote = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.LARGE,
      keepMountOnExit: true,
      title: t(i18nKeys.INVOICE.LINK_TO_INVOICE),
      children: (
        <LinkInvoiceForm
          onSubmit={linkToInvoices}
          creditNote={data as CreditNote}
          reload={reloadInfo}
        />
      ),
    });
  };

  const duplicate = () => {
    dialogHide(DialogShowId.INVOICE);
    sideMenuHide();
    if (!data) return;

    if (credit) {
      creditNoteActions.copy({
        id: data.id,
        callback: (responseData) => history.push(`/credit-notes/edit/${responseData.data.id}`),
      });
    } else {
      invoiceActions.copy({
        id: data.id,
        callback: (responseData) => {
          history.push(`/invoices/edit/${responseData.data.id}`);
        },
      });
    }
  };

  const deleteInvoice = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.DELETE),
      keepMountOnExit: true,
      children: (
        <AYSModal
          text={t(
            credit
              ? i18nKeys.INVOICE.AYS_TO_DELETE_CREDIT_NOTE
              : i18nKeys.AYS.INVOICE_ACTION_DELETE,
          )}
          onConfirm={() => {
            (credit ? creditNoteActions : invoiceActions).delete({
              id: data.id,
              callback: () => {
                sideMenuHide();
                dialogHide(DialogShowId.INVOICE);
                history.push(`/${credit ? 'credit-notes' : 'invoices'}/listing`);
              },
            });
          }}
        />
      ),
    });
  };

  // TODO: Possibly obsolete, thridparties have been removed from the invoice actions field
  const noRecoverActionAvailable = useMemo(
    () =>
      !data.actions.some((action) => {
        switch (action) {
          case 'additional_reminder':
          case 'callcenter':
          case 'bailiff':
          case 'formal_notice':
          case 'lawyer':
            return true;
          default:
            return false;
        }
      }),
    [data?.actions],
  );

  const ignoreLateFees = () => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.IGNORE_LATE_FEES)}
          onConfirm={() => {
            invoiceActions.actionsIgnoreLateFees({
              id: data.id,
              callback: () => {
                sideMenuHide();
                (reloadInfo as any)();
              },
            });
          }}
        />
      ),
    });
  };

  const openSOForm = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.INVOICE.SENDING_OPTIONS),
      children: (
        <InvoiceSendingOptions
          invoiceId={data.id}
          sendingOptions={data.sending_options_attributes}
          debtorId={data.debtor_id}
        />
      ),
    });
  };

  const { mutate: createTask } = useCreateTask();

  const addTask = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          invoiceId={data.id}
          onSubmit={(taskData: CreateTaskBody) =>
            createTask(
              {
                data: {
                  ...taskData,
                  debtor_id: data.debtor_id,
                  invoice_ids: [data.id],
                },
              },
              {
                onSettled: () => {
                  sideMenuHide();
                },
              },
            )
          }
        />
      ),
    });
  };

  const canLinkOrSettle = data.paid === true || Number(data.remaining_balance) === 0;

  return (
    <PageTitle>
      {data.reference || data.id}
      <PageTitle.Actions>
        {credit ? (
          <>
            {data.actions.includes('delete') && (
              <Button
                className={styles('button')}
                noMargin
                noShadow
                label={t(i18nKeys.DELETE)}
                onClick={deleteInvoice}
                color={ButtonColor.GREY}
              />
            )}
            {!hasActiveConnector && (
              <Button
                className={styles('button')}
                noMargin
                noShadow
                label={t(i18nKeys.INVOICE.LINK_TO_INVOICE)}
                disabled={canLinkOrSettle}
                onClick={linkCreditNote}
                color={ButtonColor.BLUE}
              />
            )}
            <Button
              className={styles('button')}
              noMargin
              noShadow
              label={t(i18nKeys.INVOICE.BALANCE_CREDIT_NOTE)}
              onClick={settleCreditNote(true)}
              disabled={canLinkOrSettle}
              color={ButtonColor.GREEN}
            />
          </>
        ) : (
          <>
            <Dropdown
              selectorContent={
                <Button
                  noShadow
                  noMargin
                  label={t(i18nKeys.INVOICE.OTHER_ACTIONS)}
                  className={styles('button')}
                  color={ButtonColor.GREY}
                  iconRight={IconName.MINIMAL_DOWN}
                />
              }
              sideMenuInMobile
              sideMenuTitle={t(i18nKeys.INVOICE.OTHER_ACTIONS)}
            >
              {company.package.can_use_tasks && (
                <div className={styles('dropdown-item')} onClick={addTask}>
                  {t(i18nKeys.NEW_TASK)}
                </div>
              )}
              <div className={styles('dropdown-item')} onClick={openSOForm}>
                {t(i18nKeys.INVOICE.EDIT_SENDING_OPTIONS)}
              </div>
              {!hasActiveConnector && (
                <div className={styles('dropdown-item')} onClick={duplicate}>
                  {t(i18nKeys.DUPLICATE)}
                </div>
              )}
              {!hasActiveConnector && (
                <div
                  className={styles(
                    'dropdown-item',
                    !data.actions.includes('unmatch_payment') && 'disabled',
                  )}
                  onClick={unmatchPayment}
                >
                  {t(i18nKeys.INVOICE.UNMATCH_PAYMENT.UNMATCH_PAYMENT)}
                </div>
              )}
              {!hasActiveConnector && (
                <div
                  className={styles(
                    'dropdown-item',
                    data.actions.indexOf('delete') === -1 && 'disabled',
                  )}
                  onClick={deleteInvoice}
                >
                  {t(i18nKeys.DELETE)}
                </div>
              )}
              {data.late_fees_calculated_at && (
                <div
                  className={styles(
                    'dropdown-item',
                    !data.actions.includes('ignore_late_fees') && 'disabled',
                  )}
                  onClick={ignoreLateFees}
                >
                  {t(i18nKeys.IGNORE_LATE_FEES)}
                </div>
              )}
            </Dropdown>
            {/* -------------------------------------- */}
            <MarkedInvoiceActions
              invoice={data}
              element={
                <Button
                  noShadow
                  noMargin
                  label={t(i18nKeys.INVOICE.CHANGE_STATUS)}
                  className={styles('button')}
                  iconRight={IconName.MINIMAL_DOWN}
                />
              }
            />
            <InvoiceActionsDropdown
              reload={reloadInfo}
              invoice={data}
              selectorClass={styles(noRecoverActionAvailable && 'disabled')}
              item={
                <Button
                  noShadow
                  noMargin
                  label={t(i18nKeys.INVOICE.RECOVER_ACTIONS)}
                  disabled={noRecoverActionAvailable}
                  className={styles('button')}
                  color={ButtonColor.GREEN}
                  iconRight={IconName.MINIMAL_DOWN}
                />
              }
            />
          </>
        )}
      </PageTitle.Actions>
    </PageTitle>
  );
}

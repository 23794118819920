import { useDeleteTemplate } from 'api';
import { Template } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import AYSModal from 'shared/components/AYSModal';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import { formattedDate } from 'shared/utils/view';
import { DialogShowId, showDialog } from 'store/view/view.actions';
import { TableItemProps } from 'types/table';

import styleIdentifiers from './TemplateListItem.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = TableItemProps<Template> & {
  refetch: () => void;
};

export default function TemplateListItem({ item, refetch }: Props) {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const history = useHistory();

  const {
    id,
    attributes: { name, step_type, updated_at },
  } = item;

  const showTemplate = () => {
    history.push(`/settings/document-customization/edit/${item.id}`);
  };

  const { mutate: deleteTemplate } = useDeleteTemplate();

  const deleteItem = (e) => {
    e.stopPropagation();
    showDialog({
      id: DialogShowId.CONFIRM,
      title: t(i18nKeys.DELETE_TEMPLATE),
      children: (
        <AYSModal
          text={t(i18nKeys.AYS.DELETE_TEMPLATE)}
          confirmButtonText={t(i18nKeys.DELETE)}
          confirmButtonColor={ButtonColor.RED}
          onConfirm={() =>
            deleteTemplate(
              { id },
              {
                onSuccess: () => {
                  refetch();
                },
              },
            )
          }
        />
      ),
    });
  };
  return (
    <tr className={styles('template-item', step_type)} onClick={showTemplate}>
      <td />
      <td>{name}</td>
      <td>{t(i18nKeys.TEMPLATE_TYPES[step_type.toUpperCase()])}</td>
      <td>{formattedDate(updated_at, currentLang)}</td>
      <td className={styles('actions')}>
        <Icon name={IconName.SETTINGS_GEAR} onClick={showTemplate} />
        {company.package.can_create_template && (
          <Icon name={IconName.TRASH_SIMPLE} onClick={deleteItem} />
        )}{' '}
      </td>
    </tr>
  );
}

import { CompanyConfiguration } from 'shared/hooks';
import { CompanyTermsAndConditions } from 'types/store/account-state';

export const CGV = 'terms_and_conditions_attributes';

export enum CustomPreferencesFieldsName {
  SEND_COPY = 'send_copy',
  SEND_COPY_EMAILS = 'send_copy_emails',
  SEND_COPY_EMAILS_INPUT = 'send_copy_emails_input',
  SEND_TO_ALL_EMAILS = 'send_to_all_emails',
  ALLOW_PAYMENT_PLANS = 'allow_payment_plans',
}
export enum CustomDomainFieldsName {
  CUSTOM_DOMAIN = 'custom_domain',
  DEFAULT_FROM = 'default_from',
}

export enum CustomRecoveryFieldsName {
  SEND_COPY = 'send_copy',
  DAYS_BETWEEN_REMINDER = 'days_between_reminder',
  DEFAULT_FORMAL_NOTICE_TEMPLATE_ID = 'default_formal_notice_template_id',
  DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID = 'default_additional_reminder_template_id',
  FIRST_REMINDER_DELAY = 'first_reminder_delay',
  LAST_REMINDER_DELAY = 'last_reminder_delay',
  THRESHOLD_AMOUNT = 'threshold_amount',
  MINIMUM_SALDO_SENDING = 'minimum_saldo_sending',
  SEND_COPY_EMAILS = 'send_copy_emails',
  SEND_COPY_EMAILS_INPUT = 'send_copy_emails_input',
}

export enum ReconciliationFieldNames {
  IGNORE_LATE_FEES = 'ignore_late_fees',
}

export enum GeneralConditionsFieldsName {
  SEND_TOS = 'send_tos',
}

export enum PostalPreferencesFieldsName {
  PRINT_COLOR = 'print_color',
  PRINT_MODE = 'print_mode',
}

export enum ReminderPreferencesFieldNames {
  REMINDER_THRESHOLD_TYPE = 'reminder_threshold_type',
}

export enum SalePreferencesFieldsName {
  ID = 'id',
  NAME = 'name',
  MINIMUM_DELAY = 'minimum_payment_terms_delay',
  DEFAULT = 'default',
  PDF_BLOB = 'pdf',
  HAS_PDF = 'has_pdf',
  DESTROY = '_destroy',
  DELETABLE = 'deletable',
  USE_BELGIAN_LAW = 'use_belgian_law',
}

export enum SalePreferencesCGVFieldsName {
  INTEREST = 'interest',
  INTEREST_PERIOD = 'interest.period',
  INTEREST_VALUE = 'interest.value',
  FIX_FEE = 'fix_fee',
  FIX_FEE_VALUE = 'fix_fee.value',
  PENALTY_CLAUSE = 'penalty_clause',
  PENALTY_CLAUSE_TYPE = 'penalty_clause.type',
  PENALTY_CLAUSE_MINIMUM = 'penalty_clause.minimum',
  PENALTY_CLAUSE_VALUE = 'penalty_clause.value',
}

export enum CalculationType {
  ANNUAL = 'annual',
  QUARTERLY = 'quarterly',
  MONTHLY = 'monthly',
}

export enum InterestCalculationType {
  PERCENTAGE = 'percentage',
  FIX = 'fix',
}

export enum OperatorValues {
  EQUAL_TO = '==',
  OTHER_THAN = '!=',
  GREATER_THAN = '>',
  GREATER_THAN_OR_EQUAL_TO = '>=',
  LESS_THAN = '<',
  LESS_THAN_OR_EQUAL_TO = '<=',
}

export type CustomPreferencesFields = {
  [CustomPreferencesFieldsName.SEND_COPY]: boolean;
  [CustomPreferencesFieldsName.SEND_TO_ALL_EMAILS]: boolean;
  [CustomPreferencesFieldsName.ALLOW_PAYMENT_PLANS]: boolean;
  [CustomPreferencesFieldsName.SEND_COPY_EMAILS]?: string[];
  [CustomPreferencesFieldsName.SEND_COPY_EMAILS_INPUT]?: string;
};

export type CustomDomainFields = {
  [CustomDomainFieldsName.CUSTOM_DOMAIN]: string;
  [CustomDomainFieldsName.DEFAULT_FROM]: string;
};

export type CustomRecoveryFields = {
  [CustomRecoveryFieldsName.SEND_COPY]: boolean;
  [CustomRecoveryFieldsName.DAYS_BETWEEN_REMINDER]: number;
  [CustomRecoveryFieldsName.DEFAULT_FORMAL_NOTICE_TEMPLATE_ID]: string;
  [CustomRecoveryFieldsName.DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID]: any;
  [CustomRecoveryFieldsName.FIRST_REMINDER_DELAY]: any;
  [CustomRecoveryFieldsName.LAST_REMINDER_DELAY]: any;
  [CustomRecoveryFieldsName.THRESHOLD_AMOUNT]: any;
  [CustomRecoveryFieldsName.MINIMUM_SALDO_SENDING]: any;
  [CustomRecoveryFieldsName.SEND_COPY_EMAILS]: string[];
  [CustomRecoveryFieldsName.SEND_COPY_EMAILS_INPUT]: string;
  tp_accounting: {
    automatic_reminders: boolean;
  };
};

export type GeneralConditionsFields = {
  [GeneralConditionsFieldsName.SEND_TOS]: boolean;
};

export type PostalPreferencesFields = {
  [PostalPreferencesFieldsName.PRINT_COLOR]: string;
  [PostalPreferencesFieldsName.PRINT_MODE]: string;
};

export type ReminderPreferenceFields = {
  [ReminderPreferencesFieldNames.REMINDER_THRESHOLD_TYPE]: string | null;
};

export type CompanyTermsAndConditionsEdit = Omit<CompanyTermsAndConditions, 'id' | 'type'> & {
  [SalePreferencesFieldsName.ID]?: string | number;
  [SalePreferencesFieldsName.DESTROY]?: boolean; // <- Additional payload, not on the actual type
  [SalePreferencesFieldsName.DELETABLE]?: boolean; // <- Additional payload, not on the actual type
  [SalePreferencesFieldsName.HAS_PDF]: boolean;
  [SalePreferencesFieldsName.USE_BELGIAN_LAW]?: boolean;
  [SalePreferencesFieldsName.PDF_BLOB]?: File; // <- Additional payload, not on the actual type
  type?: string;
};

export type SalePreferencesFields = {
  [CGV]: CompanyTermsAndConditionsEdit[];
};

export type ReconciliationFields = {
  [ReconciliationFieldNames.IGNORE_LATE_FEES]: boolean;
};

export type CompanyEdit = Omit<Partial<CompanyConfiguration>, 'terms_and_conditions_attributes'> &
  CustomPreferencesFields &
  CustomRecoveryFields &
  GeneralConditionsFields &
  PostalPreferencesFields &
  SalePreferencesFields &
  ReconciliationFields &
  ReminderPreferenceFields;

// export type CompanyEdit = Partial<CompanyConfiguration>;

import { useState } from 'react';
import { styles } from 'modules/Navigation/components/NavMenuItem';
import { Document, Page } from 'react-pdf';

import { Box, Group, Pagination, Stack, useMantineTheme } from '@mantine/core';

interface PdfDocumentProps {
  pdfUrl: string;
}

export const PdfDocument = ({ pdfUrl }: PdfDocumentProps) => {
  const theme = useMantineTheme();
  const [{ currentPage, totalPages }, setPdfMetadata] = useState({
    currentPage: 1,
    totalPages: 1,
  });

  const onDocumentLoadSuccess = (pdf: unknown) => {
    if (typeof pdf !== 'object' || pdf === null) return;
    if (!('_pdfInfo' in pdf)) return;
    if (typeof pdf._pdfInfo !== 'object' || pdf._pdfInfo === null) return;
    if (!('numPages' in pdf._pdfInfo)) return;
    if (!(typeof pdf._pdfInfo.numPages === 'number')) return;

    setPdfMetadata({
      currentPage: 1,
      totalPages: pdf._pdfInfo.numPages,
    });
  };

  return (
    <Stack>
      <Box bd={`1px solid ${theme.colors.gray[4]}`}>
        <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            renderAnnotationLayer={false}
            renderTextLayer={false}
            width={900}
            pageNumber={currentPage}
          />
        </Document>
      </Box>
      {totalPages > 1 && (
        <Group w="100%" justify="right" mt="md">
          <Pagination
            size="md"
            total={totalPages}
            value={currentPage}
            onChange={(newPageIndex) =>
              setPdfMetadata((old) => ({ ...old, currentPage: newPageIndex }))
            }
          />
        </Group>
      )}
    </Stack>
  );
};

import { useEffect, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import AddContactPersonButton from 'shared/components/AddContactPersonButton/AddContactPersonButton';
import { Icon, IconName } from 'shared/components/Icon';
import { hasSendingMedium } from 'shared/components/SendingMedium';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Checkbox, CustomSelect } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import {
  addressesToAttributes,
  emailValues,
  getDefaultAddresses,
  getDefaultEmails,
} from 'shared/utils/contacts';
import { formattedDate } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';
import { error } from 'store/view/view.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { CreditNote } from 'types/store/credit-note-state';
import { Invoice } from 'types/store/invoice-state';
import { Address, StoreState } from 'types/storeTypes';
import { Template } from 'types/template';

import AddressPartialForm from '../partial/AddressPartialForm';

import SendInvoiceFormEmail from './SendInvoiceFormEmail';

import styleIdentifiers from './sendInvoiceForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  data: Invoice | CreditNote;
  credit?: boolean;
  close?: Function;
};

export default function SendInvoiceForm({ data, onSubmit, close, credit = false }: Props) {
  const { t, currentLang } = useTranslation();
  const constants = useSelector((state: StoreState) => state.app.constants);
  const recoveryPlans = useSelector((state: StoreState) => state.settings.recoveryPlanList.pages);
  const { company } = useLoadCompanyConfiguration();
  const isLoadingInvoice = useSelector((state: StoreState) => state.invoice.isLoading);
  const isLoadingCreditNote = useSelector((state: StoreState) => state.creditNote.isLoading);

  const { debtor_attributes: debtor } = data;

  const sendingMediaAttributes =
    debtor.sending_media_attributes.length > 0
      ? debtor.sending_media_attributes
      : company.sending_media_attributes;

  const [templates, setTemplates] = useState<Template[]>([]);

  useEffect(() => {
    if (!recoveryPlans?.length) {
      settingsActions.recoveryPlanListPage();
    }

    settingsActions.templateList({
      data: {
        step_type: credit ? 'credit_note' : 'invoice',
      },
      noLoading: true,
      callback: ({ data: response }) => {
        setTemplates(response.map((template) => treatTemplate(template)));
      },
    });
  }, []);

  const { data: sendingOptionsResponse, refetch } = useGetDebtorSendingOptions(debtor.id);

  const defaultValues = {
    recovery_plan_id: 'auto',
    sent_outside_app: null,
    print_color: company.print_color,
    print_mode: company.print_mode,
    emails: [] as string[],
    addresses_attributes: [] as Address[],
    template_id: (credit
      ? debtor.default_credit_note_template || company.default_templates.credit_note
      : debtor.default_invoice_template || company.default_templates.invoice
    ).id,
    by_mail: hasSendingMedium(sendingMediaAttributes, SendingMediaNames.email),
    by_post:
      hasSendingMedium(sendingMediaAttributes, SendingMediaNames.post) ||
      hasSendingMedium(sendingMediaAttributes, SendingMediaNames.registered_post),
    by_registered_post: hasSendingMedium(sendingMediaAttributes, SendingMediaNames.registered_post),
  };

  const form = useForm({
    defaultValues,
  });

  const { watch, register, handleSubmit, setValue, getValues, reset } = form;

  useEffect(() => {
    if (sendingOptionsResponse) {
      const { emails, addresses } = sendingOptionsResponse || {
        emails: [],
        addresses: [],
      };
      const defaultEmails = getDefaultEmails(emails, Notification.invoice_sending);
      const defaultAddresses = getDefaultAddresses(addresses, Notification.invoice_sending);
      const defaultEmailValues = emailValues(defaultEmails);
      reset({
        ...getValues(),
        emails: [...(debtor.send_to_all_emails ? defaultEmailValues : [defaultEmailValues[0]])],
        addresses_attributes: addressesToAttributes(defaultAddresses),
      });
    }
  }, [sendingOptionsResponse, debtor, getValues, reset]);

  const isSendingByMail = watch('by_mail');
  const isSendingByPost = watch('by_post');
  const isOutsideApp = watch('sent_outside_app') || false;

  const matchEmails = (emails: Array<string>) => {
    const contactPersons = sendingOptionsResponse?.emails ?? [];

    return emails.map((rawEmail) => {
      const maybeContactPerson = contactPersons.find((e) => e.value === rawEmail);
      return maybeContactPerson ?? { value: rawEmail };
    });
  };

  const submit = ({ addresses_attributes, ...rest }: typeof defaultValues) => {
    const formData = {
      ...rest,
      ...(isSendingByPost
        ? {
            addresses_attributes,
          }
        : null),
      emails: matchEmails(rest.emails),
    };
    if (formData.by_post || formData.by_mail || formData.sent_outside_app) {
      onSubmit(formData);
    } else {
      error({
        text: i18nKeys.FORM.SEND_INVOICE.SENDING_MEDIUM_REQUIRED,
      });
    }
  };

  const setSendOutsideApp = () => {
    setValue('by_post', false);
    setValue('by_mail', false);
  };

  return (
    <FormProvider {...form}>
      <form className={styles('SendInvoiceForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('head')}>
          {t(i18nKeys.FORM.SEND_INVOICE.TITLE)}
          {close && (
            <div className={styles('close')} onClick={() => close()}>
              <Icon name={IconName.SIMPLE_REMOVE} size="20px" />
            </div>
          )}
        </div>
        <div className={styles('content')}>
          <div>{t(i18nKeys.FORM.SEND_INVOICE.DESCRIPTION)}</div>
          {!credit && (
            <div>
              <Controller
                name="recovery_plan_id"
                defaultValue=""
                rules={{ required: true }}
                render={() => (
                  <CustomSelect
                    noMargin
                    selectClassName={styles('input')}
                    keyText="name"
                    keyValue="id"
                    withBorder
                    name="recovery_plan_id"
                    items={[{ name: t(i18nKeys.AUTOMATIC), id: 'auto' }, ...recoveryPlans!]}
                    label={t(i18nKeys.RECOVERY_PLAN)}
                  />
                )}
              />
            </div>
          )}
          <hr />
          <div className={styles('section')}>
            {(isSendingByMail || isSendingByPost) && (
              <AddContactPersonButton refetch={refetch} debtorId={debtor.id} />
            )}
            <div className={styles('check-wrapper')}>
              <Checkbox
                register={register('by_mail')}
                watch={watch}
                className="check-SendInvoice"
                label={t(i18nKeys.FORM.SEND_INVOICE.SEND_BY_EMAIL)}
                disabled={isOutsideApp}
              />
            </div>
            <div className={styles('input-wrapper')}>
              {isSendingByMail && (
                <>
                  <SendInvoiceFormEmail emails={sendingOptionsResponse?.emails || []} />
                  <Controller
                    rules={{ required: false }}
                    defaultValue=""
                    name="template_id"
                    render={() => (
                      <CustomSelect
                        label={t(i18nKeys.COMMON.TEMPLATE)}
                        keyText="name"
                        keyValue="id"
                        name="template_id"
                        items={templates}
                      />
                    )}
                  />
                </>
              )}
            </div>
          </div>
          <div
            className={styles(
              'section',
              !constants.external_services.postgreen.active && 'disabled',
            )}
          >
            <div className={styles('check-wrapper')}>
              <Checkbox
                register={register('by_post')}
                watch={watch}
                disabled={!constants.external_services.postgreen.active || isOutsideApp}
                label={t(i18nKeys.FORM.SEND_INVOICE.SEND_BY_MAIL)}
                className="check-SendInvoice"
              />
            </div>
            <div className={styles('input-wrapper')}>
              {isSendingByPost && (
                <>
                  {data!.additional_files_attributes &&
                    data!.additional_files_attributes.length > 0 && (
                      <div className={styles('info')}>
                        {t(
                          data!.additional_files_attributes.length > 1
                            ? i18nKeys.FORM.SEND_INVOICE.SEND_ADDITIONAL_FILES
                            : i18nKeys.FORM.SEND_INVOICE.SEND_ADDITIONAL_FILE,
                          { count: data!.additional_files_attributes.length },
                        )}
                      </div>
                    )}
                  <Checkbox
                    register={register('by_registered_post')}
                    watch={watch}
                    disabled={!constants.external_services.postgreen.active}
                    label={t(i18nKeys.REGISTERED)}
                  />
                  <AddressPartialForm addresses={sendingOptionsResponse?.addresses} />
                  <Controller
                    defaultValue=""
                    rules={{ required: true }}
                    name="print_color"
                    render={() => (
                      <CustomSelect
                        className={styles('input')}
                        label={t(i18nKeys.FORM.PREFERENCES.COLOR)}
                        name="print_color"
                        withBorder
                        keyText="description"
                        keyValue="value"
                        items={constants.print_colors}
                      />
                    )}
                  />
                  <Controller
                    defaultValue=""
                    rules={{ required: true }}
                    name="print_mode"
                    render={() => (
                      <CustomSelect
                        className={styles('input')}
                        label={t(i18nKeys.FORM.SEND_INVOICE.FRONT_OR_BACK)}
                        name="print_mode"
                        withBorder
                        keyText="description"
                        keyValue="value"
                        items={constants.print_modes}
                      />
                    )}
                  />
                </>
              )}
            </div>
            {!constants.external_services.postgreen.active && (
              <div className={styles('warn-message')}>
                <Icon name={IconName.INFO} />
                {t(i18nKeys.SERVICE_UNAVAILABLE_UNTIL, {
                  date: formattedDate(
                    constants.external_services.postgreen.unavailable_until!,
                    currentLang,
                  ),
                })}
              </div>
            )}
            <hr />
            <div className={styles('section')}>
              <div className={styles('check-wrapper')}>
                <Checkbox
                  register={register('sent_outside_app')}
                  watch={watch}
                  className="check-SendInvoice"
                  label={t(i18nKeys.FORM.SEND_INVOICE.VALID_WITHOUT_SEND)}
                  onChange={setSendOutsideApp}
                  disabled={isSendingByMail || isSendingByPost}
                />
              </div>
              <div className={styles('input-wrapper')}>
                <span className={styles('legend')}>
                  {t(i18nKeys.FORM.SEND_INVOICE.CAN_VALID_WITHOUT_SEND, {
                    text: t(credit ? i18nKeys.CREDIT_NOTE : i18nKeys.COMMON.INVOICE).toLowerCase(),
                  })}{' '}
                  {!credit && t(i18nKeys.FORM.SEND_INVOICE.SEND_REMINDERS)}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button
            noShadow
            noMargin
            disabled={!(isSendingByMail || isSendingByPost || isOutsideApp)}
            label={t(isOutsideApp ? 'FORM.VALIDATE' : 'FORM.VALIDATE_AND_SEND')}
            type="submit"
            isLoading={isLoadingInvoice || isLoadingCreditNote}
          />
        </div>
      </form>
    </FormProvider>
  );
}

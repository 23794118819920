import { useState } from 'react';
import { useCreateTask } from 'api';
import { CreateTaskBody } from 'api/models';
import { useSetAtom } from 'jotai';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory } from 'react-router';
import { openEditClient } from 'shared/action-component/EditClientForm/openEditClient';
import ImportClientsForm from 'shared/forms/ImportClientsForm';
import InvoiceImportForm from 'shared/forms/InvoiceImportForm';
import { ublProgressAtom } from 'shared/forms/InvoiceImportForm/InvoiceImportForm';
import ProductForm from 'shared/forms/ProductForm';
import { useIsMobile } from 'shared/hooks/utils';
import { isConnectorActive } from 'shared/utils/connector';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { productActions } from 'store/product/product.actions';
import { sideMenuHide, sideMenuShow } from 'store/view/view.actions';

import { Button, Menu, rem, Text } from '@mantine/core';
import { IconArrowRight, IconPlus, IconX } from '@tabler/icons-react';

import { TaskForm } from './Tasks';

const buttonStyles = (isOpen: boolean) => ({
  root: {
    width: '100%',
    color: '#666666',
    backgroundColor: 'white',
    height: 40,
    outline: isOpen ? '2px solid #DEDEDE' : '1px solid #DEDEDE',
    outlineOffset: isOpen ? '2px' : '0px',
    transition: 'outline-offset 0.1s ease, outline 0.1s ease',
  },
  label: {
    flexGrow: 1,
  },
});

const dropdownStyles = {
  dropdown: {
    width: rem(200),
    borderRadius: 8,
    borderColor: '#EDEDED',
    marginLeft: 15,
    boxShadow: '4px 8px 12px -2px rgba(0, 0, 0, 0.12)',
  },
};

export const QuickActionMenu = ({}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isMobile = useIsMobile();
  const [isOpen, setIsOpened] = useState(false);
  const hasActiveConnector = isConnectorActive();
  const setUblProgress = useSetAtom(ublProgressAtom);

  const { mutate: createTask } = useCreateTask();

  const newInvoice = () => {
    invoiceActions.detailReset();
    sideMenuHide();
    history.push('/invoices/create');
  };

  const newCreditNote = () => {
    creditNoteActions.detailReset();
    sideMenuHide();
    history.push('/credit-notes/create');
  };

  const addClient = () => {
    openEditClient({});
  };

  const importClient = () => {
    setUblProgress({ active: false });
    sideMenuShow({ unmount: true, content: <ImportClientsForm /> });
  };

  const importInvoice = () => {
    setUblProgress({ active: false });
    sideMenuShow({ unmount: true, content: <InvoiceImportForm /> });
  };

  const addProduct = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <ProductForm
          onSubmit={(values: Object) => {
            productActions.add({ data: values, callback: sideMenuHide });
          }}
        />
      ),
    });
  };

  const addTask = () => {
    sideMenuShow({
      unmount: true,
      content: (
        <TaskForm
          onSubmit={(data: CreateTaskBody) => createTask({ data }, { onSuccess: sideMenuHide })}
        />
      ),
    });
  };

  return (
    <Menu opened={isOpen} onChange={setIsOpened} position={isMobile ? 'top' : 'right-end'}>
      <Menu.Target>
        <Button
          justify="space-between"
          radius={8}
          styles={buttonStyles(isOpen)}
          leftSection={<IconPlus style={{ width: rem(16), height: rem(16), color: '#666666' }} />}
          rightSection={
            isOpen ? (
              <IconX style={{ width: rem(14), height: rem(14) }} />
            ) : (
              <IconArrowRight style={{ width: rem(14), height: rem(14) }} />
            )
          }
        >
          <Text py={12}> {t(i18nKeys.NAV.QUICK_ACTION.LABEL)} </Text>
        </Button>
      </Menu.Target>

      <Menu.Dropdown styles={dropdownStyles}>
        <Menu.Label>{t(i18nKeys.NAV.QUICK_ACTION.ADD_LABEL)}</Menu.Label>
        {!hasActiveConnector && (
          <>
            <Menu.Item onClick={newInvoice}>{t(i18nKeys.NAV.QUICK_ACTION.INVOICE)}</Menu.Item>
            <Menu.Item onClick={newCreditNote}>
              {t(i18nKeys.NAV.QUICK_ACTION.CREDIT_NOTE)}
            </Menu.Item>
            <Menu.Item onClick={addProduct}>{t(i18nKeys.NAV.QUICK_ACTION.PRODUCT)}</Menu.Item>
          </>
        )}
        <Menu.Item onClick={addClient}>{t(i18nKeys.NAV.QUICK_ACTION.CUSTOMER)}</Menu.Item>
        <Menu.Item onClick={addTask}>{t(i18nKeys.NAV.QUICK_ACTION.TASK)}</Menu.Item>
        <Menu.Label>{t(i18nKeys.NAV.QUICK_ACTION.IMPORT_LABEL)}</Menu.Label>
        {!hasActiveConnector && (
          <Menu.Item onClick={importInvoice}>
            {t(i18nKeys.NAV.QUICK_ACTION.IMPORT_INVOICES)}
          </Menu.Item>
        )}
        <Menu.Item onClick={importClient}>
          {t(i18nKeys.NAV.QUICK_ACTION.IMPORT_CUSTOMERS)}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

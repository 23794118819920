import { buildActions, makeReqCons } from 'store/actions';
import { ReduxFunction } from 'types/redux';

export const appConstants = {
  constants: makeReqCons('APP_CONSTANTS'),
  fetchAddress: makeReqCons('APP_FETCH_ADDRESS'),
  getView: makeReqCons('GET_VIEW'),
  getViews: makeReqCons('GET_VIEWS'),
  updateViews: makeReqCons('UPDATE_VIEWS'),
  getColumns: makeReqCons('GET_AVAILABLE_COLUMNS'),
  getFiltersName: makeReqCons('GET_FILTER_NAME'),
  getFilters: makeReqCons('GET_AVAILABLE_FILTERS'),
  customUrl: makeReqCons('CUSTOM_URL'),
  export: makeReqCons('INVOICE_EXPORT'),
};

type AppActions = {
  constants: Function;
  constantsRes: Function;
  fetchAddress: Function;
  fetchAddressRes: Function;
  getView: ReduxFunction;
  getViewRes: Function;
  getViews: ReduxFunction;
  getViewsRes: Function;
  updateViews: ReduxFunction;
  updateViewsRes: Function;
  getColumns: ReduxFunction;
  getColumnsRes: Function;
  getFiltersName: ReduxFunction;
  getFiltersNameRes: Function;
  getFilters: ReduxFunction;
  getFiltersRes: Function;
  customUrl: Function;
  customUrlRes: Function;
  export: ReduxFunction;
  exportRes: Function;
};

// Autogenerated actions according to constants
const appActions = buildActions<AppActions>('', appConstants, {});

export { appActions };

import { i18nKeys, useTranslation } from 'locales';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout/PageTitle';
import { accountActions } from 'store/account/account.actions';

import { useQueryClient } from '@tanstack/react-query';

import { SettingsCard } from '../SettingsCard';

import CompanySettingsForm from './CompanySettingsForm';

export const CompanySettings = () => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const queryClient = useQueryClient();

  const handleUpdate = (callback?: Function) => (values) => {
    const data = { ...values };

    if (company.phones_attributes && company.phones_attributes.length > 0) {
      const isPhoneDifferent =
        data.phones_attributes[0].number !== company.phones_attributes[0].number;
      if (isPhoneDifferent) {
        data.phones_attributes[0] = { number: data.phones_attributes[0].number };
        data.phones_attributes.push({ ...company.phones_attributes[0], _destroy: true });
      }
    }

    company.company_bank_accounts?.forEach((bankAccount) => {
      if (data.company_bank_accounts) {
        if (
          !data.company_bank_accounts.some(
            (companyBankAccount) => companyBankAccount.id === bankAccount.id,
          )
        ) {
          data.company_bank_accounts.push({
            id: bankAccount.id,
            _destroy: true,
          });
        }
      } else {
        data.company_bank_accounts = company.company_bank_accounts?.map(({ id }) => ({
          id,
          _destroy: true,
        }));
      }
    });

    data.company_bank_accounts_attributes = data.company_bank_accounts;
    delete data.bank_accounts_attributes;
    delete data.company_bank_accounts;

    accountActions.updateCompanyInfo({
      data,
      callback: () => {
        callback?.();
        queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
      },
    });
  };

  return (
    <>
      <PageTitle>{t(i18nKeys.SETTINGS.COMPANY.PROFILE)}</PageTitle>
      <SettingsCard>
        <CompanySettingsForm onSubmit={handleUpdate} />
      </SettingsCard>
    </>
  );
};

import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { IconName } from 'shared/components/Icon';
import {
  CodaboxFormFields,
  Integration,
  isEnabledIntegration,
  PontoFormFields,
} from 'shared/components/Integrations';
import OnboardingItem from 'shared/components/OnboardingItem';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { onBoardingActions } from 'store/onBoarding/onBoarding.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';

import { StepProps } from '../OnBoarding.types';

import Codabox from './Codabox/Codabox';
import Ponto from './Ponto/Ponto';
import BankAccountForm from './BankAccountForm';

import styleIdentifiers from './BankConnection.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = StepProps & {
  disableIntegration: boolean;
};

export default function BankConnection({ onValidStep, disableIntegration }: Props) {
  const { t } = useTranslation();
  const { refetchCompany } = useLoadCompanyConfiguration();

  const callback = async () => {
    const { error, isError, isSuccess } = await refetchCompany();

    dialogHide(DialogShowId.CUSTOM);

    if (isSuccess) onValidStep();
    else if (isError) {
      onValidStep(error.response?.data.next_step);
    }
  };

  const submitCodabox = (data: CodaboxFormFields) => {
    onBoardingActions.codaboxToken({
      callback,
      data,
    });
  };

  const submitPonto = (data: PontoFormFields) => {
    onBoardingActions.ponto({
      callback,
      data,
    });
  };

  const submitBankAccount = (values: any) => {
    const data = { ...values, iban: values.iban.replace(/ /g, '') };
    onBoardingActions.bankAccount({
      callback,
      data,
    });
  };

  const onChoosePonto = () => {
    if (!isEnabledIntegration(Integration.PONTO)) {
      return;
    }
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: 'Ponto',
      children: <Ponto onSubmit={submitPonto} />,
    });
  };

  const onChooseCodabox = () => {
    if (!isEnabledIntegration(Integration.CODABOX)) {
      return;
    }
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      title: t(i18nKeys.ONBOARDING[2].CODABOX.TITLE),
      children: <Codabox onSubmit={submitCodabox} />,
    });
  };

  const onChooseNoBankConnection = () => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      keepMountOnExit: true,
      title: t(i18nKeys.ONBOARDING[2].PAYMENT_ACCOUNT),
      children: <BankAccountForm onSubmit={submitBankAccount} />,
    });
  };

  return (
    <div className={styles('bank-connection', disableIntegration && 'center')}>
      <div className={styles('steps', disableIntegration && 'small')}>
        {disableIntegration ? (
          <BankAccountForm onSubmit={submitBankAccount} />
        ) : (
          <>
            <OnboardingItem
              title={t(i18nKeys.ONBOARDING[2].NO_BANK_CONNEXION)}
              icon={IconName.BANK}
              onClick={onChooseNoBankConnection}
              paddingBottom
            />
            {isEnabledIntegration(Integration.PONTO) && (
              <OnboardingItem
                title="Ponto"
                icon={IconName.PONTO}
                onClick={onChoosePonto}
                paddingBottom
              />
            )}
            {isEnabledIntegration(Integration.CODABOX) && (
              <OnboardingItem
                title="Codabox"
                icon={IconName.CODABOX}
                onClick={onChooseCodabox}
                paddingBottom
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}

import { Reducer, useReducer } from 'react';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, useFormContext } from 'react-hook-form';
import { IconName } from 'shared/components/Icon';
import Tooltip from 'shared/components/Tooltip';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Checkbox, CustomSelect, Input } from 'shared/io';
import { treatTemplate } from 'shared/serializer';
import { currencySymbol } from 'shared/utils/normalization';
import { email } from 'shared/utils/validation';
import { reducerState } from 'shared/utils/view';
import { settingsActions } from 'store/settings/settings.actions';

import {
  CustomRecoveryFields,
  CustomRecoveryFieldsName,
  ReminderPreferencesFieldNames,
} from '../PreferencesForm.constants';

import styleIdentifiers from './CustomRecoveryPartialForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onboarding?: boolean;
  onlinePayment?: boolean;
};
type State = Reducer<
  {
    additionalReminderTemplate: any[];
    fnTemplate: any[];
  },
  any
>;
export const CustomRecoveryPartialForm = ({ onboarding, onlinePayment }: Props) => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const [{ fnTemplate, additionalReminderTemplate }, setState] = useReducer<State>(reducerState, {
    additionalReminderTemplate: [],
    fnTemplate: [],
  });

  const { register, watch } = useFormContext<CustomRecoveryFields>();
  const shouldSendCopy = watch(CustomRecoveryFieldsName.SEND_COPY);

  const getTemplates = (step_type) => () => {
    settingsActions.templateList({
      data: {
        step_type,
      },
      noLoading: true,
      callback: ({ data }) => {
        setState({
          [step_type === 'formal_notice' ? 'fnTemplate' : 'additionalReminderTemplate']: data.map(
            (template) => treatTemplate(template),
          ),
        });
      },
    });
  };

  return (
    <div className={styles('grid-row', 'custom-recovery-partial-form')}>
      {onboarding && (
        <div className={styles('col-6')}>
          <Controller
            defaultValue=""
            name="fee_starting_step"
            render={() => (
              <CustomSelect
                name="fee_starting_step"
                label={
                  <span>
                    {t(i18nKeys.FORM.PREFERENCES.CLAIM_THE_ADDITIONAL_COSTS)}{' '}
                    <Tooltip
                      icon={IconName.INFO}
                      size="14px"
                      text={t('FORM.PREFERENCES.TOOLTIP.CLAIM_THE_ADDITIONAL_COSTS')}
                    />
                  </span>
                }
                keyText="description"
                keyValue="value"
                items={[
                  {
                    description: t(i18nKeys.TEMPLATE_TYPES.LAST_REMINDER),
                    value: 'last_reminder',
                  },
                  {
                    description: t(i18nKeys.TEMPLATE_TYPES.ADDITIONAL_REMINDER),
                    value: 'additional_reminder',
                  },
                  {
                    description: t(i18nKeys.TEMPLATE_TYPES.FORMAL_NOTICE),
                    value: 'formal_notice',
                  },
                ]}
              />
            )}
          />
        </div>
      )}
      <div className={styles('col-6')}>
        <Controller
          rules={{ required: true }}
          defaultValue=""
          name="automatic_reminders"
          render={() => (
            <CustomSelect
              label={t(i18nKeys.FORM.PREFERENCES.REMINDER_MANAGEMENT)}
              keyText="description"
              keyValue="value"
              name="automatic_reminders"
              items={[
                {
                  description: t(i18nKeys.FORM.PREFERENCES.AUTOMATIC),
                  value: 'true',
                },
                {
                  description: t(i18nKeys.FORM.PREFERENCES.MANUAL),
                  value: 'false',
                },
              ]}
            />
          )}
        />
      </div>
      <div className={styles('col-6')}>
        <Input
          label={t(i18nKeys.FORM.PREFERENCES.MIN_DAYS_BETWEEN_REMINDER)}
          type="number"
          min="0"
          register={register(CustomRecoveryFieldsName.DAYS_BETWEEN_REMINDER)}
        />
      </div>
      {!onboarding && (
        <>
          <div className={styles('col-6')}>
            <Controller
              rules={{ required: true }}
              defaultValue=""
              name={CustomRecoveryFieldsName.DEFAULT_FORMAL_NOTICE_TEMPLATE_ID}
              render={() => (
                <CustomSelect
                  label={t(i18nKeys.DEFAULT_FORMAL_NOTICE)}
                  keyText="name"
                  keyValue="id"
                  hideOnLoading
                  name={CustomRecoveryFieldsName.DEFAULT_FORMAL_NOTICE_TEMPLATE_ID}
                  load={getTemplates('formal_notice')}
                  items={fnTemplate}
                />
              )}
            />
          </div>
          <div className={styles('col-6')}>
            <Controller
              rules={{ required: true }}
              defaultValue=""
              name={CustomRecoveryFieldsName.DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID}
              render={() => (
                <CustomSelect
                  label={t(i18nKeys.DEFAULT_ADDITIONAL_REMINDER)}
                  keyText="name"
                  keyValue="id"
                  hideOnLoading
                  name={CustomRecoveryFieldsName.DEFAULT_ADDITIONAL_REMINDER_TEMPLATE_ID}
                  load={getTemplates('additional_reminder')}
                  items={additionalReminderTemplate}
                />
              )}
            />
          </div>
        </>
      )}
      {onboarding && (
        <>
          <div className={styles('col-6')}>
            <Input
              label={t(i18nKeys.FORM.PREFERENCES.DAYS_AFTER_DEADLINE)}
              type="number"
              min="0"
              register={register(CustomRecoveryFieldsName.FIRST_REMINDER_DELAY)}
            />
          </div>
          <div className={styles('col-6')}>
            <Input
              type="number"
              label={t(i18nKeys.FORM.PREFERENCES.DAYS_BETWEEN_REMINDERS)}
              register={register(CustomRecoveryFieldsName.LAST_REMINDER_DELAY)}
              min="0"
            />
          </div>{' '}
        </>
      )}
      <div className={styles('col-6')}>
        <Input
          type="number"
          label={
            <span>
              {t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_UNDER, {
                text: currencySymbol(undefined, company),
              })}{' '}
              <Tooltip
                icon={IconName.INFO}
                size="14px"
                text={t('FORM.PREFERENCES.TOOLTIP.DONT_SEND_REMINDER_UNDER')}
              />
            </span>
          }
          register={register(CustomRecoveryFieldsName.THRESHOLD_AMOUNT)}
          min="0"
        />
      </div>
      <div className={styles('col-6')}>
        <Input
          type="number"
          label={
            <span>
              {t(i18nKeys.FORM.PREFERENCES.DONT_SEND_REMINDER_TO_DEBTOR_UNDER, {
                text: currencySymbol(undefined, company),
              })}
              <Tooltip
                icon={IconName.INFO}
                size="14px"
                text={t('FORM.PREFERENCES.TOOLTIP.DONT_SEND_REMINDER_UNDER')}
              />
            </span>
          }
          register={register(CustomRecoveryFieldsName.MINIMUM_SALDO_SENDING)}
          min="0"
        />
      </div>
      <div className={styles('col-6')}>
        <Controller
          name={ReminderPreferencesFieldNames.REMINDER_THRESHOLD_TYPE}
          rules={{ required: true }}
          render={() => (
            <div>
              <CustomSelect
                name={ReminderPreferencesFieldNames.REMINDER_THRESHOLD_TYPE}
                label={t(i18nKeys.FORM.PREFERENCES.THRESHOLD)}
                items={[
                  {
                    description: t(i18nKeys.FORM.PREFERENCES.REMAINING_BALANCE),
                    value: 'remaining_balance',
                  },
                  {
                    description: t(i18nKeys.FORM.PREFERENCES.OVERDUE_BALANCE),
                    value: 'overdue_balance',
                  },
                ]}
                keyText="description"
                keyValue="value"
              />
            </div>
          )}
        />
      </div>
      {onboarding && (
        <div className={styles('col-6')}>
          <Controller
            defaultValue=""
            name="generate_invoice_in_debtor_locale"
            rules={{ required: true }}
            render={() => (
              <CustomSelect
                label={t(i18nKeys.DOCUMENTS_LOCALE)}
                keyText="description"
                keyValue="value"
                name="generate_invoice_in_debtor_locale"
                items={[
                  {
                    description: t(i18nKeys.DEBTOR_LOCALE),
                    value: 'true',
                  },
                  {
                    description: t(i18nKeys.COMPANY_LOCALE),
                    value: 'false',
                  },
                ]}
              />
            )}
          />
        </div>
      )}
      {onboarding && (
        <div className={styles('col-6')}>
          <Controller
            defaultValue=""
            name="display_qr_code"
            rules={{ required: true }}
            render={() => (
              <CustomSelect
                info={!onlinePayment ? t(i18nKeys.NOT_INCLUDED) : ''}
                notAllowed={!onlinePayment}
                noArrow={!onlinePayment}
                label={t(i18nKeys.DISPLAY_QR_CODE)}
                keyText="description"
                keyValue="value"
                name="display_qr_code"
                items={[
                  { description: t(i18nKeys.YES), value: 'true' },
                  { description: t(i18nKeys.NO), value: 'false' },
                ]}
              />
            )}
          />
        </div>
      )}
      <div className={styles('col-6')}>
        <Checkbox
          register={register('tp_accounting.automatic_reminders')}
          watch={watch}
          label={t(i18nKeys.FORM.PREFERENCES.SEND_AUTOMATIC_REMINDERS)}
        />
      </div>
      {onboarding && (
        <div className={styles('col-6')}>
          <Checkbox
            register={register(CustomRecoveryFieldsName.SEND_COPY)}
            watch={watch}
            label={
              <span>
                {t(i18nKeys.FORM.PREFERENCES.RECEIVE_COPY)}{' '}
                <Tooltip
                  icon={IconName.INFO}
                  size="14px"
                  text={t(i18nKeys.FORM.PREFERENCES.TOOLTIP.RECEIVE_COPY)}
                />
              </span>
            }
          />
          {shouldSendCopy && (
            <Controller
              defaultValue=""
              name={CustomRecoveryFieldsName.SEND_COPY_EMAILS}
              render={() => (
                <CustomSelect
                  name={CustomRecoveryFieldsName.SEND_COPY_EMAILS}
                  className={styles('send-copy-email')}
                  multiple
                  noSuggestions
                  noArrow
                  canAdd
                  itemsClassName={styles(onboarding && 'no-suggestion')}
                  itemRendering={({ item }) => (
                    <>
                      <div>
                        {item.first_name} {item.last_name}
                      </div>
                      <div>{item.email}</div>
                    </>
                  )}
                  keyText="email"
                  keyValue="email"
                  items={[]}
                  label={t(i18nKeys.FORM.PREFERENCES.RECEIVE_ON)}
                  inputValidation={email}
                  registerMultipleInput={register(CustomRecoveryFieldsName.SEND_COPY_EMAILS_INPUT, {
                    validate: {
                      emailInputNotEmpty: (value) => !value,
                    },
                  })}
                />
              )}
            />
          )}
        </div>
      )}
    </div>
  );
};

import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import Amount from 'shared/components/Amount';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import Percentage from 'shared/components/Percentage';
import { CalculationType, InterestCalculationType } from 'shared/forms/PreferencesForm';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { getCgv } from 'shared/utils/selectors';
import { accountActions } from 'store/account/account.actions';
import { OnClickFunction } from 'types/html-type';
import { Debtor } from 'types/store/client-state';

import styleIdentifiers from './TabInfoPayment.module.scss';

const styles = classNames.bind(styleIdentifiers);

type TabInfoPaymentProps = {
  debtor: Debtor;
  handleSettings: OnClickFunction;
};

export const TabInfoPayment = ({ debtor, handleSettings }: TabInfoPaymentProps) => {
  const { t } = useTranslation();

  const { company } = useLoadCompanyConfiguration();

  const { average_payment_delay, delay_rate } = debtor;

  const currentCGV = getCgv(company, debtor);

  if (!currentCGV) {
    return (
      <div className={styles('PaymentDelay', 'card')}>
        <h3>
          {t(i18nKeys.SALE_CONDITION)}
          <Icon name={IconName.PENCIL} className={styles('icon')} onClick={handleSettings} />
        </h3>
        <div className={styles('row')}>
          <div className={styles('col')}>
            <div className={styles('info-condition')}>
              {t(i18nKeys.CLIENT.DETAIL.NOT_HAVE_CONDITIONS_FOR_CLIENT)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const {
    id,
    default: isDefault,
    penalty_clause,
    interest,
    minimum_payment_terms_delay,
    has_pdf,
    fix_fee,
    name,
  } = currentCGV;

  return (
    <div className={styles('payment-delay', 'card')}>
      <h3>
        {t(i18nKeys.SALE_CONDITION)}
        <Icon name={IconName.PENCIL} className={styles('icon')} onClick={handleSettings} />
      </h3>
      <div className={styles('row')}>
        <div className={styles('col')}>
          <div className={styles('info-condition')}>
            <HTML
              html={
                isDefault
                  ? t(i18nKeys.CLIENT.DETAIL.NOT_HAVE_CONDITIONS_FOR_CLIENT)
                  : t(i18nKeys.CLIENT.DETAIL.HAVE_CONDITIONS_FOR_CLIENT, {
                      text: name,
                    })
              }
            />
          </div>
          <div className={styles('group')}>
            <span className={styles('subtitle')}>
              <HTML html={t(i18nKeys.CLIENT.DETAIL.AVERAGE_DELAY_AUTHORIZED)} />
            </span>
            <span className={styles('blue')}>
              {average_payment_delay || '-'} / {minimum_payment_terms_delay || '-'}{' '}
              {t(i18nKeys.DAY)}
            </span>
          </div>
          <div className={styles('group')}>
            <span className={styles('subtitle')}>
              <HTML html={t(i18nKeys.CLIENT.DETAIL.LATE_PAYMENT_RATE)} />
            </span>
            <span className={styles('blue')}>
              <Percentage value={delay_rate || 0} />
            </span>
          </div>
          {has_pdf && (
            <div className={styles('group')}>
              <span className={styles('subtitle')}>{t(i18nKeys.SALE_CONDITION)}</span>
              <div onClick={() => accountActions.getTerms({ id })} className={styles('download')}>
                <Icon name={IconName.CLOUD_DOWNLOAD} className={styles('icon')} />
                <span className={styles('txt', 'value')}>
                  {t(i18nKeys.ACTIVITIES.DOWNLOAD_DOCUMENT)}
                </span>
              </div>
            </div>
          )}
        </div>
        <div className={styles('col')}>
          {!!penalty_clause?.value && (
            <div className={styles('group')}>
              <span className={styles('subtitle')}>
                {t(i18nKeys.FORM.PREFERENCES.PENALTY_CLAUSE_VALUE, {
                  text: penalty_clause.type === InterestCalculationType.PERCENTAGE ? '%' : '€',
                })}
              </span>
              <span className={styles('value')}>
                {penalty_clause.type === InterestCalculationType.PERCENTAGE ? (
                  <>
                    <Percentage value={penalty_clause.value} />
                    <span className={styles('spacer')}>{' - '}</span>
                    <Amount value={penalty_clause.minimum} />
                  </>
                ) : (
                  <Amount value={penalty_clause.value} />
                )}
              </span>
            </div>
          )}
          {!!interest?.value && (
            <div className={styles('group')}>
              <span className={styles('subtitle')}>{t(i18nKeys.FORM.PREFERENCES.INTEREST)}</span>
              <span className={styles('value')}>
                <span>
                  {t(
                    i18nKeys.CALCULATION_TYPE[
                      Object.keys(CalculationType).find(
                        (key) => interest.period === CalculationType[key as keyof CalculationType],
                      ) as CalculationType
                    ],
                  )}
                </span>
                <span className={styles('spacer')}>{' - '}</span>
                <Percentage value={interest.value} />
              </span>
            </div>
          )}
          {!!fix_fee?.value && (
            <div className={styles('group')}>
              <span className={styles('subtitle')}>{t(i18nKeys.FORM.PREFERENCES.FIX_FEE)}</span>
              <span className={styles('value')}>
                <Amount value={fix_fee.value} />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { isDefined } from 'remeda';
import { getCreditNoteFormValues, InvoiceForm } from 'shared/forms/InvoiceForm';
import { creditNoteActions } from 'store/creditNote/creditnote.actions';
import { CreditNote } from 'types/store/credit-note-state';
import { StoreState } from 'types/storeTypes';

export default function ManageCreditNote({
  match: { params },
}: RouteComponentProps<{ id?: string }>) {
  // The store is optimistically typed as having its data always present, we need to tell it it can be undefined
  const creditNote = useSelector((state: StoreState) => state.creditNote.current?.data) as
    | CreditNote
    | undefined;

  const isEditMode = isDefined(params.id);
  const isCreateMode = !isEditMode;

  useEffect(() => {
    if (isEditMode) creditNoteActions.detail({ id: Number(params.id) });
  }, []);

  if (isCreateMode) {
    return (
      <InvoiceForm
        creditNote={creditNote}
        isCreditNote
        submit={(data) => creditNoteActions.add({ data })}
      />
    );
  }

  if (!isDefined(creditNote)) return null;

  return (
    <InvoiceForm
      initialValues={getCreditNoteFormValues(creditNote)}
      creditNote={creditNote}
      isCreditNote
      submit={(data) =>
        creditNoteActions.update({
          data: {
            id: creditNote.id,
            ...data,
          },
        })
      }
    />
  );
}

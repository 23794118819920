import { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { i18nKeys, useTranslation } from 'locales';
import { useHistory, useParams } from 'react-router-dom';
import { isDefined, isEmpty, isNullish } from 'remeda';
import HTML from 'shared/components/HTML/HTML';
import { PdfDocument } from 'shared/components/PdfDocument';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { PageTitle } from 'shared/layout';
import { formattedDate } from 'shared/utils/view';
import { EmailDeliveryStatus } from 'types/store/activity';

import {
  Accordion,
  ActionIcon,
  Anchor,
  Card,
  Center,
  Divider,
  Group,
  Select,
  Space,
  Stack,
  Tabs,
  Text,
} from '@mantine/core';
import { styled } from '@stitches/react';
import { IconArrowLeft } from '@tabler/icons-react';

import { useLoadReminder } from '../api';

const EmailStatusHistory = styled('div', {});

const EmailStatusHistoryItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  fontSize: '0.8rem',
  fontWeight: 'bold',
  padding: '10px 0px 0px 5px',
});

interface ReminderProps {}

export const Reminder = ({}: ReminderProps) => {
  const { t, currentLang } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { reminder, isReminderLoading } = useLoadReminder(id);

  const [currentPdfUrl, setCurrentPdfUrl] = useState<string | null>(null);
  const [currentRecipient, setCurrentRecipient] = useState<string | null>();

  const canUseEmailStatus = company.package.can_use_emails_activity;

  useEffect(() => {
    if (reminder != null) {
      const firstRecipient = reminder.emails[0];
      const firstPdf = reminder.letters[0];

      if (isDefined(firstPdf)) setCurrentPdfUrl(firstPdf.url);
      if (isDefined(firstRecipient)) setCurrentRecipient(firstRecipient.email);
    }
  }, [reminder == null]);

  const selectedEmail = reminder?.emails.find((recipient) => recipient.email === currentRecipient);

  const currentPdf = reminder?.letters.find((letter) => letter.url === currentPdfUrl);

  const flattenedStatuses = Object.keys(selectedEmail?.statuses ?? {}).reduce(
    (memo, currentKey) => [
      ...memo,
      ...selectedEmail?.statuses![currentKey].map((date) => ({
        status: currentKey,
        timestamp: dayjs(date),
      })),
    ],
    [] as Array<{ status: EmailDeliveryStatus; timestamp: Dayjs }>,
  );

  const sortedStatuses = flattenedStatuses.sort((a, b) => b.timestamp.diff(a.timestamp));

  const hasEmail = reminder && !isEmpty(reminder.emails);
  const hasPost = reminder && !isEmpty(reminder.letters);

  if (isReminderLoading) return null;

  if (!hasEmail && !hasPost) {
    return (
      <>
        <PageTitle>
          <ActionIcon onClick={() => history.push('/reminders')} size="lg" variant="light">
            <IconArrowLeft stroke={1.5} />
          </ActionIcon>
          {t(i18nKeys.REMINDER.REMINDER)}
        </PageTitle>
        <Text>{t(i18nKeys.REMINDER_LIST.BADGE.MISSING_INFORMATION)}</Text>
      </>
    );
  }

  return (
    <>
      <PageTitle>
        <ActionIcon onClick={() => history.push('/reminders')} size="lg" variant="light">
          <IconArrowLeft stroke={1.5} />
        </ActionIcon>
        {t(i18nKeys.REMINDER.REMINDER)}
      </PageTitle>
      <Tabs defaultValue={hasEmail ? 'email' : 'pdf'}>
        <Tabs.List my="lg">
          {hasEmail && <Tabs.Tab value="email">{t(i18nKeys.EMAIL)}</Tabs.Tab>}
          {hasPost && <Tabs.Tab value="pdf">{t(i18nKeys.POST)}</Tabs.Tab>}
        </Tabs.List>
        <Tabs.Panel value="email">
          <Card>
            <Stack gap="lg">
              <Group>
                <Text fw="bold" c="dimmed" fz="xs" w={120}>
                  {t(i18nKeys.REMINDER.SENT_TO)}
                </Text>
                <Select
                  w={300}
                  size="sm"
                  data={reminder?.emails.map((recipient) => recipient.email)}
                  value={currentRecipient}
                  onChange={setCurrentRecipient}
                  allowDeselect={false}
                />
              </Group>
              <Group>
                <Text fw="bold" c="dimmed" fz="xs" w={120}>
                  {t(i18nKeys.REMINDER.DATE_SENT)}
                </Text>
                <Text>{dayjs(selectedEmail?.sentAt).format('dddd DD MMMM YYYY - HH:mm')}</Text>
              </Group>
            </Stack>
            {sortedStatuses.length !== 0 && canUseEmailStatus && (
              <Group pt="sm">
                <Text fw="bold" c="dimmed" fz="xs" w={120}>
                  {t(i18nKeys.REMINDER.DELIVERY_STATUS)}
                </Text>
                <Accordion variant="separated" w={300}>
                  <Accordion.Item value="1">
                    <Accordion.Control>
                      {t(i18nKeys.REMINDER.SEE_DELIVERY_HISTORY)}
                    </Accordion.Control>
                    <Accordion.Panel>
                      <EmailStatusHistory>
                        {sortedStatuses.map((statusObject, index) => (
                          <EmailStatusHistoryItem key={index}>
                            <span>
                              {t(i18nKeys.MAILS.STATUS[statusObject.status.toUpperCase()])}
                            </span>
                            <span style={{ fontStyle: 'italic', color: '#bbb' }}>
                              {formattedDate(
                                statusObject.timestamp.toDate(),
                                currentLang,
                                'DD/MM/YYYY - HH:mm',
                              )}
                            </span>
                          </EmailStatusHistoryItem>
                        ))}
                      </EmailStatusHistory>
                    </Accordion.Panel>
                  </Accordion.Item>
                </Accordion>
              </Group>
            )}
            <Divider my="xl" />
            <HTML html={selectedEmail?.body} />
          </Card>
        </Tabs.Panel>
        <Tabs.Panel value="pdf">
          <Card>
            <Group>
              <Text fw="bold" c="dimmed" fz="xs" w={120}>
                {t(i18nKeys.REMINDER.CHOOSE_PDF)}
              </Text>
              <Select
                data={reminder?.letters.map((pdf, index) => ({
                  value: pdf.url ?? '',
                  label: `PDF ${index + 1}`,
                }))}
                onChange={setCurrentPdfUrl}
                value={currentPdfUrl}
              />
            </Group>
            <Space h="md" />
            <Group>
              <Text fw="bold" c="dimmed" fz="xs" w={120}>
                {t(i18nKeys.REMINDER.TRACKING_LINK)}
              </Text>
              {currentPdf && currentPdf.trackingLink ? (
                <Anchor href={currentPdf.trackingLink} target="_blank">
                  {currentPdf.trackingLink}
                </Anchor>
              ) : (
                <Text>{t(i18nKeys.REMINDER.NO_TRACKING_LINK)}</Text>
              )}
            </Group>
            <Space h="xl" />
            <Center>
              <PdfDocument pdfUrl={currentPdfUrl ?? ''} />
            </Center>
          </Card>
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

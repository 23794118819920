import React from 'react';
import { ContactPerson } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { get } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import ClientAddressForm from 'shared/action-component/EditClientForm/ClientForm/ClientAddressForm/ClientAddressForm';
import FormSection from 'shared/forms/FormSection';
import { Checkbox } from 'shared/io';

import styleIdentifiers from './ContactPersonForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type ContactPersonFormAddressProps = { contactPerson?: ContactPerson };

export const ContactPersonFormAddress = ({ contactPerson }: ContactPersonFormAddressProps) => {
  const { t } = useTranslation();

  const form = useFormContext();

  const {
    register,
    watch,
    formState: { errors },
  } = form;

  return (
    <FormSection title={t(i18nKeys.ADDRESS)} startClosed={!contactPerson?.attributes?.address}>
      <div className="col-12">
        <Checkbox
          label={t(i18nKeys.CONTACT_PERSON.USE_POSTAL_ADDRESS)}
          errorMessage={get(errors, 'use_postal_address')}
          register={register('use_postal_address')}
          watch={watch}
        />
      </div>
      <div className={styles('address')}>
        <ClientAddressForm name="address.attributes" />
      </div>
    </FormSection>
  );
};

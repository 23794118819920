import { ExternalMailStatus, Id, TaskStatus } from 'api/models';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { upperFirst } from 'lodash-es';
import { Link } from 'react-router-dom';
import Card from 'shared/components/Card';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { useSynchroniseIntegration } from 'shared/components/Integrations';
import Tooltip from 'shared/components/Tooltip';
import { useLoadCompanyConfiguration, useProfile } from 'shared/hooks';
import { RecentActivities as RecentActivitiesType } from 'shared/hooks/use-load-dashboard';
import { Button, ButtonColor } from 'shared/io';
import { DropdownDirection } from 'shared/io/Dropdown/Dropdown';
import { formattedDate } from 'shared/utils/view';

import { ActionIcon, Button as MantineButton } from '@mantine/core';
import { styled } from '@stitches/react';
import {
  IconArrowRight,
  IconAt,
  IconBuildingBank,
  IconFileInvoice,
  IconListCheck,
  IconMessage,
} from '@tabler/icons-react';

import styleIdentifiers from './recentActivities.module.scss';

const styles = classNames.bind(styleIdentifiers);

const Hover = styled('span', {
  ':hover': {
    filter: 'brightness(1.05)',
  },
});

type Props = {
  data: RecentActivitiesType;
  accountManagerId?: Id;
};

export default function RecentActivities({ data, accountManagerId }: Props) {
  const { t, currentLang } = useTranslation();

  const { company } = useLoadCompanyConfiguration();
  const profile = useProfile();

  const { synchronize, canSynchronize, lastImportDate } = useSynchroniseIntegration();

  const currentIntegration = company.tp_accounting.integration_in_use[0];
  const hasInvoicesToTreat = data.invoicesToTreat > 0;

  return (
    <Card className={styles('RecentActivities')} title={t(i18nKeys.YOUR_ACCOUNT)}>
      <div className={styles('activities')}>
        <div className={styles('item', hasInvoicesToTreat && 'invoices-active')}>
          <div className={styles('content')}>
            <ActionIcon
              component="span"
              style={{ pointerEvents: 'none' }}
              color={hasInvoicesToTreat ? 'orange.4' : 'blue.4'}
              variant="subtle"
            >
              <IconFileInvoice size={20} stroke={1.5} />
            </ActionIcon>
            <div className={styles('text')}>
              <HTML
                html={t(
                  data.invoicesToTreat > 1
                    ? i18nKeys.ACTIVITIES.INVOICES_TO_PAY_ATTENTION
                    : i18nKeys.ACTIVITIES.INVOICE_TO_PAY_ATTENTION,
                  { count: data.invoicesToTreat },
                )}
              />
            </div>
          </div>
          <Link to={`/invoices/to-handle${accountManagerId ? `?user_id=${accountManagerId}` : ''}`}>
            <Hover>
              <MantineButton
                justify="space-between"
                fw={500}
                variant={hasInvoicesToTreat ? 'gradient' : 'light'}
                gradient={{ from: 'orange.7', to: 'orange.6', deg: -90 }}
                rightSection={<IconArrowRight size={14} />}
              >
                {t(i18nKeys.PROCESS)}
              </MantineButton>
            </Hover>
          </Link>
        </div>

        {company.package.can_use_tasks && (
          <div className={styles('item', 'tasks')}>
            <div className={styles('content')}>
              <ActionIcon
                component="span"
                style={{ pointerEvents: 'none' }}
                color="blue.4"
                variant="subtle"
              >
                <IconListCheck size={20} stroke={1.5} />
              </ActionIcon>
              <div className={styles('text')}>
                <HTML
                  html={t(
                    data.ongoingTasksCount > 1
                      ? i18nKeys.DASHBOARD.ONGOING_TASKS_COUNT
                      : i18nKeys.DASHBOARD.ONGOING_TASK_COUNT,
                    { count: data.ongoingTasksCount },
                  )}
                />
              </div>
            </div>
            <Link to={`/tasks?status=${TaskStatus.toDo}&user_id=${accountManagerId ?? profile.id}`}>
              <MantineButton
                justify="space-between"
                fw={500}
                variant="light"
                rightSection={<IconArrowRight size={14} />}
              >
                {t(i18nKeys.SEE_ALL)}
              </MantineButton>
            </Link>
          </div>
        )}

        {company.bank_accounts_attributes && (
          <div className={styles('item', 'transactions')}>
            <div className={styles('content')}>
              <ActionIcon
                component="span"
                style={{ pointerEvents: 'none' }}
                color="blue.4"
                variant="subtle"
              >
                <IconBuildingBank size={20} stroke={1.5} />
              </ActionIcon>
              <div className={styles('text')}>
                <HTML
                  html={t(
                    data.paymentCount > 1
                      ? i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTIONS
                      : i18nKeys.ACTIVITIES.UNPROCESSED_TRANSACTION,
                    { count: data.paymentCount },
                  )}
                />
              </div>
            </div>
            <Link to="/transactions/reconciliation">
              <MantineButton
                justify="space-between"
                fw={500}
                variant="light"
                rightSection={<IconArrowRight size={14} />}
              >
                {t(i18nKeys.RECONCILE)}
              </MantineButton>
            </Link>
          </div>
        )}

        {company.package.can_use_external_mails && (
          <div className={styles('item', 'emails')}>
            <div className={styles('content')}>
              <ActionIcon
                component="span"
                style={{ pointerEvents: 'none' }}
                color="blue.4"
                variant="subtle"
              >
                <IconAt size={20} stroke={1.5} />
              </ActionIcon>
              <div className={styles('text')}>
                <HTML
                  html={t(
                    data.unprocessedEmailsCount > 1
                      ? i18nKeys.DASHBOARD.UNPROCESSED_EMAILS_COUNT
                      : i18nKeys.DASHBOARD.UNPROCESSED_EMAIL_COUNT,
                    { count: data.unprocessedEmailsCount },
                  )}
                />
              </div>
            </div>

            <Link
              to={`/mails?status=${ExternalMailStatus.unprocessed}${
                accountManagerId != null ? `&account_manager_id=${accountManagerId}` : ''
              }`}
            >
              <MantineButton
                justify="space-between"
                fw={500}
                variant="light"
                rightSection={<IconArrowRight size={14} />}
              >
                {t(i18nKeys.SEE_ALL_M)}
              </MantineButton>
            </Link>
          </div>
        )}

        {data.unreadThirdPartyMessagesCount > 0 && (
          <div className={styles('item', 'emails')}>
            <div className={styles('content')}>
              <ActionIcon
                component="span"
                style={{ pointerEvents: 'none' }}
                color="blue.4"
                variant="subtle"
              >
                <IconMessage size={20} stroke={1.5} />
              </ActionIcon>
              <div className={styles('text')}>
                <HTML
                  html={t(i18nKeys.DASHBOARD.UNREAD_THIRD_PARTY_MESSAGES_COUNT, {
                    pluralizedCount: `${data.unreadThirdPartyMessagesCount} ${t(
                      data.unreadThirdPartyMessagesCount === 1
                        ? i18nKeys.THIRD_PARTY_CASES.ONE
                        : i18nKeys.THIRD_PARTY_CASES.MANY,
                    )}`,
                  })}
                />
              </div>
            </div>

            <Link to="/thirdparty_cases?unread_messages=true">
              <MantineButton
                justify="space-between"
                fw={500}
                variant="light"
                rightSection={<IconArrowRight size={14} />}
              >
                {t(i18nKeys.SEE_ALL_M)}
              </MantineButton>
            </Link>
          </div>
        )}

        {!!company.tp_accounting.integration_in_use.length &&
          !company.tp_accounting.automatic_sync &&
          company[`${currentIntegration}_integration`]?.use_import && (
            <div className={styles('item', 'integration')}>
              <div className={styles('content')}>
                <Icon name={IconName.INTEGRATION} className={styles('icon')} />
                <div className="item-wrapper">
                  <div className={styles('text')}>
                    {t(i18nKeys.DASHBOARD.ACTIVE_INTEGRATION, {
                      text: upperFirst(currentIntegration),
                    })}
                  </div>
                  {lastImportDate && (
                    <div className={styles('info')}>
                      {t(i18nKeys.FORM.INTEGRATION.LAST_IMPORT_THE, {
                        date: formattedDate(lastImportDate, currentLang, 'LLL'),
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className={styles('button-container')}>
                  <Tooltip
                    icon={IconName.INFO}
                    iconClassName={styles('icon')}
                    direction={DropdownDirection.TOP}
                  >
                    {t(i18nKeys.FORM.INTEGRATION.MANUAL_SYNC_DESCRIPTION)}
                    <div className={styles('info')}>
                      {company.tp_accounting.manual_import_possible_from &&
                        t(i18nKeys.FORM.INTEGRATION.NEXT_IMPORT_THE, {
                          date: formattedDate(
                            company.tp_accounting.manual_import_possible_from,
                            currentLang,
                            'LLL',
                          ),
                        })}
                    </div>
                  </Tooltip>
                  <Button
                    disabled={!canSynchronize}
                    onClick={synchronize}
                    className={styles('variable')}
                    label={t(i18nKeys.SYNCHRONIZE)}
                    noMargin
                    color={ButtonColor.WHITE}
                    iconRight={IconName.SYNC}
                  />
                </div>
              </div>
            </div>
          )}
      </div>
    </Card>
  );
}

import { useEffect, useReducer, useState } from 'react';
import { SendingMedium } from 'api/models';
import { ClientDetailForm } from 'app/Private/Clients';
import classNames from 'classnames/bind';
import { get } from 'http';
import { i18nKeys, useTranslation } from 'locales';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  anyPass,
  conditional,
  isEmpty,
  isNot,
  isNullish,
  isPlainObject,
  isString,
  pipe,
} from 'remeda';
import { AVAILABLE_CURRENCIES } from 'shared';
import { IconName } from 'shared/components/Icon';
import SendingMedia from 'shared/components/SendingMedium/SendingMedia';
import FormSection from 'shared/forms/FormSection';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, ButtonColor, Checkbox, CustomSelect, Input, VatInput } from 'shared/io';
import { reducerState } from 'shared/utils/view';
import { appActions } from 'store/app/app.actions';
import { sideMenuSetAskBeforeClose } from 'store/view/view.actions';
import { AVAILABLE_LANGUAGES } from 'types';
import { onSubmitFunction } from 'types/react-hook-form';
import { Debtor } from 'types/store/client-state';
import { StoreState } from 'types/storeTypes';

import ClientAddressForm from './ClientAddressForm/ClientAddressForm';
import ClientCustomVariableForm from './ClientCustomVariableForm/ClientCustomVariableForm';
import BankAccounts from './BankAccounts';
import Emails from './Emails';
import Phones from './Phones';

import styleIdentifiers from './clientForm.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  initialValues: Debtor & {
    dirty: boolean;
    address_attributes: any;
    mainEmailIndex: any;
  };
};

export default function ClientForm({ onSubmit, initialValues }: Props) {
  const { company } = useLoadCompanyConfiguration();
  const { sending_medium } = useSelector((state: StoreState) => state.app.constants);
  const { t } = useTranslation();
  const [phonesTmp, setPhonesTmp] = useState<any[]>([]);
  const [bankAccountTmp] = useState<any[]>([]);
  const [newData, setData] = useReducer(reducerState, {});

  const defaultValues = {
    ...initialValues,
    sending_media_attributes: (initialValues?.sending_media_attributes || []).map((medium) => ({
      ...medium,
      force_sending: String(medium.force_sending),
    })) as SendingMedium[],
  };

  const form = useForm({
    defaultValues,
  });

  const {
    watch,
    setValue,
    reset,
    register,
    handleSubmit,
    formState: { isDirty, errors },
    getValues,
  } = form;

  register('dirty');
  register('mainEmailIndex');

  const formValue = watch();

  const { vat_number, company_name, emails, phones_attributes, debtor_bank_accounts_attributes } =
    formValue;

  useEffect(() => {
    sideMenuSetAskBeforeClose(isDirty);
  }, [isDirty]);

  const setFormDirty = () => {
    setTimeout(() => {
      setValue('dirty', (formValue as any).dirty ? (formValue as any).dirty + 1 : 1, {
        shouldDirty: true,
      });
    });
  };

  const addEmail = () => {
    reset({
      ...formValue,
      emails: [...(formValue.emails || []), { email: '' }] as any,
    });
  };

  const addPhone = () => {
    reset({
      ...formValue,
      phones_attributes: [...(formValue.phones_attributes || []), { number: '' }] as any,
    });
  };

  const addBankAccount = () => {
    reset({
      ...formValue,
      debtor_bank_accounts_attributes: [
        ...(formValue.debtor_bank_accounts_attributes || []),
        { iban: '', bic: '' },
      ] as any,
    });
  };

  const sendingMediaAttributes = watch('sending_media_attributes');

  const addSendingMedium = () => {
    const defaultSendingMedium = (sending_medium.filter(
      (e) => !sendingMediaAttributes.some((el) => e.value === el.name),
    ) || sending_medium)[0];

    if (sendingMediaAttributes.length < sending_medium.length) {
      setValue(
        'sending_media_attributes',
        [
          ...sendingMediaAttributes,
          {
            name: defaultSendingMedium.value,
            order: sendingMediaAttributes.length + 1,
            force_sending: 'true',
          },
        ] as SendingMedium[],
        {
          shouldTouch: true,
        },
      );
    }
  };

  const getAddress = () => {
    appActions.fetchAddress({
      data: {
        vat_number,
      },
      callback: (res: any) => {
        const keys = Object.keys(res);
        for (let i = 0; i < keys.length; i += 1) {
          const key = keys[i];
          const elem = res[key];
          if (key === 'company_name') continue;
          setValue(`address_attributes.${key}`, elem);
        }
        if (res.company_name && !company_name) setValue('full_name', res.company_name);
      },
    });
  };

  const onFormArrayClose = (name) => (isOpen) => {
    if (isOpen) {
      setTimeout(
        () => setValue(name, name === 'phones_attributes' ? phonesTmp : bankAccountTmp),
        100,
      );
    } else {
      const values = getValues();
      setPhonesTmp(values[name]);
    }
  };

  const submit = (data: any) => {
    data = { ...data, ...newData };

    delete (data as any).dirty;
    if ((data as any).recovery_plan_id === 'default') (data as any).recovery_plan_id = null;
    if ((data as any).default_invoice_template_id === 'default')
      (data as any).default_invoice_template_id = null;
    if ((data as any).default_credit_note_template_id === 'default')
      (data as any).default_credit_note_template_id = null;
    if ((data as any).send_to_all_emails === 'default') (data as any).send_to_all_emails = null;
    if ((data as any).allow_payment_plans === 'default') (data as any).allow_payment_plans = null;
    if (initialValues.unpaid_invoices_present && initialValues.id) {
      onSubmit(data);
    } else {
      onSubmit(data);
    }
  };

  // We can create a client with no adress at all, but if we do provide an address,
  // some of the address fields become mandatory.
  // prettier-ignore
  const wasAddressModified = pipe(
    getValues().address_attributes ?? {},
    Object.values,
    (vals) => vals.some(v => isString(v) && v.length > 0),
  );

  return (
    <FormProvider {...form}>
      <form className={styles('ClientForm')} onSubmit={handleSubmit(submit)}>
        <div className={styles('head')}>
          {initialValues && initialValues.id
            ? t(i18nKeys.FORM.CLIENT.EDIT_RECORD)
            : t(i18nKeys.NEW_CLIENT)}
        </div>
        <div className={styles('content')}>
          <div className="grid-row">
            <div className="col-12">
              <Input
                register={register('full_name', { required: true })}
                errorMessage={errors.full_name}
                className={styles('input', 'first-field')}
                withBorder
                noMargin
                type="text"
                label={t(i18nKeys.FORM.CLIENT.FULL_NAME)}
              />
            </div>
            <div className="col-6">
              <Checkbox
                register={register('moral_entity')}
                watch={watch}
                label={t(i18nKeys.FORM.CLIENT.LEGAL_ENTITY)}
              />
            </div>
            <div className="col-6">
              <Controller
                defaultValue=""
                name="locale"
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    items={AVAILABLE_LANGUAGES}
                    withBorder
                    name="locale"
                    label={t(i18nKeys.FORM.CLIENT.CONTACT_LANG)}
                  />
                )}
              />
            </div>
            <div className="col-12">
              <VatInput
                className={styles('input')}
                withBorder
                name="vat_number"
                label={t(i18nKeys.FORM.VAT)}
              />
              {!isEmpty(vat_number) && (
                <div className={styles('info')}>
                  <Button
                    noMargin
                    noShadow
                    color={ButtonColor.BLUE}
                    className={styles('link')}
                    label={t(i18nKeys.FORM.CLIENT.PRE_FILL)}
                    onClick={getAddress}
                  />
                </div>
              )}
            </div>
            <div className="col-12">
              <Input
                register={register('reference')}
                errorMessage={errors.reference}
                className={styles('input')}
                withBorder
                noMargin
                type="text"
                label={t(i18nKeys.DEBTOR.REFERENCE)}
                placeholder={t(i18nKeys.DEBTOR.REFERENCE_PLACEHOLDER)}
              />
            </div>
            <div className="col-12">
              <Controller
                defaultValue={company.currency}
                name="currency"
                render={() => (
                  <CustomSelect
                    selectClassName={styles('input')}
                    keyText="description"
                    keyValue="value"
                    items={AVAILABLE_CURRENCIES as any}
                    withBorder
                    name="currency"
                    label={t(i18nKeys.FORM.CLIENT.CURRENCY)}
                  />
                )}
              />
            </div>
            <div className="col-12">
              <Checkbox
                register={register('co_contractor')}
                watch={watch}
                className={styles('input')}
                label={t(i18nKeys.FORM.CLIENT.COCONTRACTOR_VAT0)}
              />
              <Checkbox
                register={register('intracom')}
                watch={watch}
                className={styles('input')}
                label={t(i18nKeys.FORM.CLIENT.INTRA_COMMUNITY_VAT0)}
              />
            </div>
            <FormSection
              className={styles('email-section')}
              title={t(i18nKeys.EMAILS)}
              onAdd={addEmail}
            >
              <Emails className={styles('input')} formArray={emails} setFormDirty={setFormDirty} />
            </FormSection>
            <FormSection title={t(i18nKeys.ADDRESS)}>
              <ClientAddressForm required={wasAddressModified} />
            </FormSection>
            <FormSection
              className={styles('phone-section')}
              title={t(i18nKeys.PHONE)}
              onAdd={addPhone}
              onToggle={onFormArrayClose}
            >
              <Phones
                className={styles('input')}
                formArray={phones_attributes}
                setFormDirty={setFormDirty}
              />
            </FormSection>
            <FormSection title={t(i18nKeys.COMMON.BANK_ACCOUNT)} onAdd={addBankAccount}>
              <BankAccounts
                className={styles('input')}
                formArray={debtor_bank_accounts_attributes}
                setFormDirty={setFormDirty}
              />
            </FormSection>
            <FormSection title={t(i18nKeys.DEBTOR.SENDING_MEDIUMS)}>
              <SendingMedia
                fieldName="sending_media_attributes"
                className={styles('input')}
                withBorder
                required={false}
              />
              {sendingMediaAttributes.length < sending_medium.length && (
                <Button
                  color={ButtonColor.GREY}
                  label={t(i18nKeys.ACTIONS.ADD)}
                  iconRight={IconName.PLUS}
                  onClick={addSendingMedium}
                  small
                />
              )}
            </FormSection>
            <FormSection title={t(i18nKeys.PREFERENCES)}>
              <ClientDetailForm data={initialValues} setData={setData} />
            </FormSection>
            <FormSection title={t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE)}>
              <ClientCustomVariableForm />
            </FormSection>
          </div>
        </div>
        <div className={styles('button-wrapper')}>
          <Button disabled={!isDirty} noMargin label={t(i18nKeys.SAVE)} type="submit" />
        </div>
      </form>
    </FormProvider>
  );
}

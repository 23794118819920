import { useState } from 'react';
import CustomVariablesBatchForm from 'app/Private/CustomVariables/CustomVariablesBatchForm/CustomVariablesBatchForm';
import { SetReasonForm } from 'app/Private/Reasons/SetReasonForm/SetReasonForm';
import { TaskForm } from 'app/Private/Tasks';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import qs from 'query-string';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseCollaboratorModal from 'shared/components/ChooseCollaboratorModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import ListResModal from 'shared/components/DeleteModal';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { ButtonColor } from 'shared/io';
import { treatRecoveryPlan } from 'shared/serializer';
import { useRefresh } from 'shared/utils/hooks';
import { queryParamParser, reloadCustomView } from 'shared/utils/view';
import { clientActions } from 'store/client/client.actions';
import { settingsActions } from 'store/settings/settings.actions';
import {
  dialogHide,
  DialogShowId,
  DialogShowSize,
  showDialog,
  sideMenuHide,
} from 'store/view/view.actions';
import { Debtor } from 'types/store/client-state';

import { PaymentDelayModal } from '../../Detail/PaymentDelayModal';
import { ChooseDefaultTemplate } from '../ChooseDefaultTemplate/ChooseDefaultTemplate';
import { BATCH_ACTIONS } from '../ClientsList.constants';

import styleIdentifiers from '../../../Invoices/Listing/BatchActions.module.scss';

const styles = classNames.bind(styleIdentifiers);

export enum Categories {
  RECOVERY = 'RECOVERY',
  FOLLOW_UP = 'FOLLOW_UP',
  ASSIGNMENT = 'ASSIGNMENT',
  OTHER = 'OTHER',
}

export type BatchActionsProps = {
  debtorsSelected: Debtor[];
  isSelectedAll: boolean;
};

export default function DebtorBatchActions({ debtorsSelected, isSelectedAll }: BatchActionsProps) {
  const { t } = useTranslation();
  const [isAllSelected, setIsAllSelected] = useState<boolean>(isSelectedAll);
  const { company } = useLoadCompanyConfiguration();
  const { doRefresh } = useRefresh();

  const aysModal = (action_type) => (onSubmit) => {
    let confirmText = '';

    if (action_type === BATCH_ACTIONS.DELETE) {
      if (debtorsSelected.length === 0) {
        confirmText = i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT_ALL;
      } else {
        confirmText =
          debtorsSelected.length === 1
            ? i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT
            : i18nKeys.CLIENT.AYS_TO_DELETE_CLIENT_MULTIPLE;
      }
    } else {
      const isDebtorPaused = action_type === BATCH_ACTIONS.PAUSE;

      if (debtorsSelected.length === 0) {
        confirmText = isDebtorPaused
          ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT_ALL
          : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT_ALL;
      } else if (debtorsSelected.length === 1) {
        confirmText = isDebtorPaused
          ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT
          : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT;
      } else {
        confirmText = isDebtorPaused
          ? i18nKeys.CLIENT.AYS_TO_PAUSE_CLIENT_MULTIPLE
          : i18nKeys.CLIENT.AYS_TO_UNPAUSE_CLIENT_MULTIPLE;
      }
    }

    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(confirmText, { count: debtorsSelected.length })}
          onConfirm={() => onSubmit({})}
        />
      ),
    });
  };

  const batchAction = (action_type, action) => () => {
    sideMenuHide();
    const data = {} as any;
    if (isAllSelected) {
      data.filter_params = queryParamParser(qs.parse(location.search));
      data.view_id = location.hash.slice(1);
    } else {
      data.debtor_ids = debtorsSelected?.map(({ id }) => id);
    }
    action((values) => {
      clientActions.batchAction({
        data: {
          ...data,
          ...values,
          action_type,
        },
        callback: (dataItem) => {
          dialogHide(DialogShowId.CONFIRM);
          showDialog({
            id: DialogShowId.CUSTOM,
            size: DialogShowSize.MEDIUM,
            title: t(i18nKeys.RESULT),
            children: <ListResModal data={dataItem} />,
          });
          reloadCustomView(clientActions.listPageRes);
          setIsAllSelected(false);
          doRefresh();
        },
      });
    });
  };

  const selectStepPlan = (
    newPlan,
    oldPlan,
    counter,
    maxItems,
    callback,
    step_switchs: any[] = [],
  ) => {
    MoveRecoveryPlanInvoices({
      newPlan,
      oldPlan,
      title: t(i18nKeys.PLAN.MOVE_INVOICES),
      onSubmit: (steps_switch) => {
        step_switchs.push({ to: newPlan.id, from: oldPlan.id, steps_switch });
        if (counter === maxItems) {
          callback(step_switchs);
        } else {
          selectStepPlan(newPlan, oldPlan, counter + 1, maxItems, callback, step_switchs);
        }
      },
    });
  };

  const setDefaultPlan = () => {
    sideMenuHide();
    let scope = {
      scope: 'debtor',
    } as any;
    if (isSelectedAll) {
      scope = { scope: 'debtor', ...qs.parse(location.search) };
      scope.view_id = location.hash.slice(1);
    } else {
      scope.debtor_ids = debtorsSelected.map((debtor) => debtor.id);
    }

    settingsActions.recoveryPlanList({
      callback: ({ data }) => {
        const recoveryPlans = data.map((item) => treatRecoveryPlan(item));

        showDialog({
          title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
          id: DialogShowId.CUSTOM,
          size: DialogShowSize.MEDIUM,
          children: (
            <ChooseRecoveryPlanModal
              recoverPlans={recoveryPlans}
              onClick={(planId) => {
                settingsActions.getRecoveryPlan({
                  id: Number(planId),
                  callback: (response) => {
                    showDialog({
                      id: DialogShowId.CUSTOM,
                      title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
                      children: (
                        <AYSModal
                          text={t(i18nKeys.CLIENT.MOVE_INVOICES_IN_PLAN)}
                          cancelButtonText={t(i18nKeys.NO)}
                          confirmButtonText={t(i18nKeys.YES)}
                          onCancel={() => {
                            settingsActions.getPlansInvoices({
                              data: scope,
                              callback: (res) => {
                                settingsActions.switchPlansInvoices({
                                  data: {
                                    ...scope,
                                    plans_switch: res.data.map((plan: any) => ({
                                      from: plan.id,
                                      to: planId,
                                      steps_switch: [],
                                    })),
                                  },
                                  callback: () => {
                                    dialogHide(DialogShowId.CUSTOM);
                                  },
                                });
                              },
                            });
                          }}
                          onConfirm={() => {
                            const newPlan = treatRecoveryPlan(response.data);
                            settingsActions.getPlansInvoices({
                              data: scope,
                              callback: (res) => {
                                const plans = res.data.map((plan) => treatRecoveryPlan(plan));

                                if (plans.length > 0) {
                                  selectStepPlan(
                                    newPlan,
                                    plans[0],
                                    1,
                                    plans.length,
                                    (plans_switch) => {
                                      settingsActions.switchPlansInvoices({
                                        data: {
                                          plans_switch,
                                          ...scope,
                                        },
                                        callback: () => {
                                          dialogHide(DialogShowId.CUSTOM);
                                        },
                                      });
                                    },
                                  );
                                } else {
                                  settingsActions.switchPlansInvoices({
                                    data: {
                                      ...scope,
                                    },
                                    callback: () => {
                                      dialogHide(DialogShowId.CUSTOM);
                                    },
                                  });
                                }
                              },
                            });
                          }}
                        />
                      ),
                    });
                  },
                });
              }}
            />
          ),
        });
      },
    });
  };

  const assignToAccManager = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CHOOSE_ACCOUNT_MANAGER),
      children: <ChooseCollaboratorModal onSubmit={onSubmit} />,
    });
  };

  const taskDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.FORM.TASK.NEW),
      children: <TaskForm batch debtors onSubmit={onSubmit} />,
    });
  };

  const handlePause = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CLIENT.PAUSE),
      children: <SetReasonForm onSubmit={onSubmit} reasonType="pause" />,
    });
  };

  const handleDispute = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.MARK_AS_DISPUTED),
      children: <SetReasonForm onSubmit={onSubmit} reasonType="dispute" />,
    });
  };

  const editDefaultTemplate = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CLIENT.EDITING_DEFAULT_TEMPLATE),
      children: <ChooseDefaultTemplate onSubmit={onSubmit} />,
    });
  };

  const selectDefaultCgv = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS),
      children: <PaymentDelayModal onSubmit={onSubmit} />,
    });
  };

  const customVariablesDetails = (onSubmit) => {
    showDialog({
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.SETTINGS.CUSTOM_VARIABLES.LONG_TITLE),
      children: <CustomVariablesBatchForm onSubmit={onSubmit} resourceType="debtor" />,
    });
  };

  const allowPaymentPlans = (onSubmit) => {
    showDialog({
      id: DialogShowId.CONFIRM,
      size: DialogShowSize.SMALL,
      title: t(i18nKeys.CONFIRMATION),
      children: (
        <AYSModal
          text={t(i18nKeys.BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TEXT)}
          cancelButtonText={t(i18nKeys.NO)}
          confirmButtonText={t(i18nKeys.YES)}
          onConfirm={() => onSubmit({ allow_payment_plans: true })}
          onCancel={() => onSubmit({ allow_payment_plans: false })}
        />
      ),
    });
  };

  const actions = [
    {
      name: i18nKeys.CLIENT.PAUSE,
      function: batchAction(BATCH_ACTIONS.PAUSE, handlePause),
      icon: IconName.PAUSE,
      category: Categories.RECOVERY,
    },
    {
      name: i18nKeys.CLIENT.UNPAUSE,
      function: batchAction(BATCH_ACTIONS.UNPAUSE, aysModal(BATCH_ACTIONS.UNPAUSE)),
      icon: IconName.PLAY,
      category: Categories.RECOVERY,
    },
    {
      name: i18nKeys.BATCH.ACTIONS.CUSTOM_VARIABLES,
      function: batchAction('custom_variables', customVariablesDetails),
      icon: IconName.PENCIL,
      category: Categories.OTHER,
      disabled: !company.package.can_use_custom_variables,
    },
    {
      name: i18nKeys.BATCH.ACTIONS.ALLOW_PAYMENT_PLANS.TITLE,
      function: batchAction('allow_payment_plans', allowPaymentPlans),
      icon: IconName.PAYMENT_BOOK,
      category: Categories.OTHER,
      disabled: !company.package.can_use_advanced_payment_plans,
    },
    {
      name: i18nKeys.DELETE,
      function: batchAction(BATCH_ACTIONS.DELETE, aysModal(BATCH_ACTIONS.DELETE)),
      icon: IconName.TRASH_SIMPLE,
      category: Categories.OTHER,
      color: ButtonColor.RED,
    },
    {
      name: i18nKeys.FORM.TASK.NEW,
      function: batchAction(BATCH_ACTIONS.TASK, taskDetails),
      icon: IconName.COMMENT,
      category: Categories.FOLLOW_UP,
      disabled: !company.package.can_use_tasks,
    },
    {
      name: i18nKeys.PLAN.MOVE_DEBTOR,
      function: setDefaultPlan,
      icon: IconName.REPLACE,
      category: Categories.ASSIGNMENT,
    },
    {
      name: i18nKeys.CLIENT.DETAIL.CHANGE_TERMS_AND_CONDITIONS,
      function: batchAction(BATCH_ACTIONS.ASSIGN_CGV, selectDefaultCgv),
      icon: IconName.REPLACE,
      category: Categories.ASSIGNMENT,
    },
    {
      name: i18nKeys.ASSIGN_ACCOUNT_MANAGER_SHORT,
      function: batchAction(BATCH_ACTIONS.ASSIGN, assignToAccManager),
      icon: IconName.USER,
      category: Categories.ASSIGNMENT,
    },
    {
      name: i18nKeys.CLIENT.EDIT_DEFAULT_TEMPLATE,
      function: batchAction(BATCH_ACTIONS.ASSIGN_TEMPLATE, editDefaultTemplate),
      icon: IconName.SEND,
      category: Categories.FOLLOW_UP,
    },
    {
      name: i18nKeys.MARK_AS_DISPUTED,
      function: batchAction(BATCH_ACTIONS.DISPUTE, handleDispute),
      icon: IconName.ALERT_CIRCLE,
      category: Categories.RECOVERY,
    },
  ];

  return (
    <div className={styles('batch-actions-wrapper')}>
      {Object.values(Categories).map((category) => (
        <div key={category}>
          <h5>{t(i18nKeys.BATCH.CATEGORIES[category])}</h5>
          <hr />
          <div className={styles('batch-actions')}>
            {actions
              .filter((action) => action.category === category)
              .map((action) => (
                <div
                  className={styles('batch-action', action.color, action.disabled && 'disabled')}
                  onClick={action.function}
                  key={action.name}
                >
                  <Icon name={action.icon} />
                  <p>{t(action.name)}</p>
                </div>
              ))}
          </div>
        </div>
      ))}
    </div>
  );
}

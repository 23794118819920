import { useEffect, useRef, useState } from 'react';
import { useGetDebtorSendingOptions } from 'api';
import { Notification, SendingMediaNames } from 'api/models';
import SendingOptionsForm from 'app/Private/Invoices/ToHandleDetail/SendingOptionsForm';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { Document, Page } from 'react-pdf';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { emailValues, getDefaultEmails, transformEmailsAndSubmit } from 'shared/utils/contacts';
import { invoiceActions } from 'store/invoice/invoice.actions';
import { onSubmitFunction } from 'types/react-hook-form';
import { Invoice } from 'types/store/invoice-state';

import { Box, LoadingOverlay } from '@mantine/core';

import styleIdentifiers from './AdditionalReminder.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {
  onSubmit: onSubmitFunction;
  invoice: Invoice;
  items: any;
  invoiceActionsData: any;
  initialValues: any;
};

export default function AdditionalReminder({
  onSubmit,
  invoice,
  items,
  invoiceActionsData,
  initialValues,
}: Props) {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const refContainer = useRef<HTMLDivElement | null>(null);

  const form = useForm({
    shouldUnregister: true,
    defaultValues: initialValues,
  });

  const [state, setState] = useState<any>({
    pageNumber: 1,
    numberOfPages: 1,
    url: '',
    email: '',
  });
  const { url, pageNumber, numberOfPages, email } = state;

  const [isPostSelected, setIsPostSelected] = useState(false);
  const [isEmailSelected, setIsEmailSelected] = useState(false);

  const sendingMediaNames = initialValues.sending_media_attributes.map(({ name }) => name);
  const sendEmail = sendingMediaNames.includes(SendingMediaNames.email);
  const sendPost = sendingMediaNames.includes(SendingMediaNames.post); // Can send via ...
  const sendRegisteredPost = sendingMediaNames.includes(SendingMediaNames.registered_post);

  const [activeTab, setActiveTab] = useState<'post' | 'email'>();
  const [currentTemplateId, setCurrentTemplateId] = useState<number | null>(
    company.default_templates.additional_reminder.id,
  );

  // @leo: Not ideal to have the child bubble up the state for the tabs,
  // but it's simpler then hoisting the whole SendingOptions form here for now
  useEffect(() => {
    if (isEmailSelected) {
      setActiveTab('email');
    } else {
      setActiveTab(isPostSelected ? 'post' : undefined);
    }
  }, [isEmailSelected]);

  useEffect(() => {
    if (isPostSelected) {
      setActiveTab('post');
    } else {
      setActiveTab(isEmailSelected ? 'email' : undefined);
    }
  }, [isPostSelected]);

  useEffect(() => {
    getEmail(currentTemplateId);
    getPdf(currentTemplateId);
  }, [currentTemplateId]);

  const {
    data: sendingOptionsResponse,
    isError,
    isLoading,
    refetch,
  } = useGetDebtorSendingOptions(invoice.debtor_id);

  const getPdf = (template_id: any) => {
    invoiceActions.document({
      id: invoice.id,
      noLoading: true,
      data: {
        document_type: 'additional_reminder_pdf',
        template_id,
        ...items,
      },
      callback: (data) => {
        setState((s) => ({
          ...s,
          pageNumber: 1,
          url: URL.createObjectURL(data),
        }));
      },
    });
  };

  const getEmail = (template_id: any) => {
    invoiceActions.previewTemplate({
      id: invoice.id,
      noLoading: true,
      data: {
        document_type: 'additional_reminder',
        manual_reminder: true,
        template_id,
        ...items,
      },
      callback: (data) => {
        setState((s) => ({
          ...s,
          email: data.email,
        }));
      },
    });
  };

  if (isLoading && !sendingOptionsResponse) {
    return (
      <Box w={400} h={400}>
        <LoadingOverlay visible />
      </Box>
    );
  }

  if (isError || !sendingOptionsResponse) {
    return <span>Error</span>;
  }

  const { emails } = sendingOptionsResponse;
  const defaultEmails = getDefaultEmails(emails, Notification.reminders);
  const defaultEmailValues = emailValues(defaultEmails);

  const onDocumentLoadSuccess = (pdf) => {
    setState((s) => ({
      ...s,
      numberOfPages: pdf._pdfInfo.numPages,
    }));
  };

  return (
    <FormProvider {...form}>
      <div className={styles('additional-reminder')}>
        <div className={styles('left')} ref={refContainer}>
          <div className={styles('tab-wrapper')}>
            {isEmailSelected && (
              <div
                className={styles('tab-item', activeTab === 'email' && 'active')}
                onClick={() => setActiveTab(SendingMediaNames.email)}
              >
                {t(i18nKeys.EMAIL)}
              </div>
            )}
            {isPostSelected && (
              <div
                className={styles('tab-item', activeTab === 'post' && 'active')}
                onClick={() => setActiveTab(SendingMediaNames.post)}
              >
                {t(i18nKeys.POST)}
              </div>
            )}
          </div>
          {activeTab === 'email' && email && (
            <div className={styles('email-preview')}>
              <HTML className="email-preview" html={email} />
            </div>
          )}
          {activeTab === 'post' && (
            <div className={styles('pdf-actions')} style={{ border: url ? '1px solid #bbb' : '' }}>
              {url ? (
                <>
                  <div className={styles('container-actions')}>
                    {pageNumber > 1 && (
                      <Icon
                        name={IconName.MINIMAL_UP}
                        onClick={() => {
                          setState({ ...state, pageNumber: pageNumber - 1 });
                        }}
                      />
                    )}
                    {numberOfPages > pageNumber && (
                      <Icon
                        name={IconName.MINIMAL_DOWN}
                        onClick={() => {
                          setState({ ...state, pageNumber: pageNumber + 1 });
                        }}
                      />
                    )}
                  </div>
                  <Document file={url} onLoadSuccess={onDocumentLoadSuccess}>
                    {/* for the scrollbarwidth */}
                    <Page
                      renderAnnotationLayer={false}
                      renderTextLayer={false}
                      width={(refContainer?.current?.offsetWidth || 680) - 10}
                      pageNumber={pageNumber}
                    />
                  </Document>
                </>
              ) : (
                <div className={styles('loading')}>
                  <div>{t(i18nKeys.LOADING)}</div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={styles('right')}>
          <SendingOptionsForm
            customText={t(i18nKeys.FORM.ADDITIONAL_REMINDER.SUMMARY)}
            customButtonText={t(i18nKeys.SUBMIT)}
            setTemplate={setCurrentTemplateId}
            onSubmit={(values) => transformEmailsAndSubmit(emails, values, onSubmit)}
            type="additional_reminder"
            invoiceActionsData={invoiceActionsData}
            setIsPostSelected={setIsPostSelected}
            setIsEmailSelected={setIsEmailSelected}
            initialValues={{
              ...invoice.sending_options_attributes,
              template_id: company.default_templates.additional_reminder,
              send_email: sendEmail,
              send_post: sendPost,
              send_registered_post: sendRegisteredPost,
            }}
            invoice={{
              ...invoice,
              debtor_attributes: {
                ...invoice.debtor_attributes,
                emails: [
                  ...new Set([...defaultEmailValues, ...invoice.sending_options_attributes.emails]),
                ],
              },
            }}
            debtorSendingOptions={sendingOptionsResponse}
            refetchSendingOptions={refetch}
          />
        </div>
      </div>
    </FormProvider>
  );
}

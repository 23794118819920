import React from 'react';
import AnimateNumber from 'animated-number-react';
import { useManualReconciliation } from 'api';
import { Id } from 'api/models';
import { i18nKeys, useTranslation } from 'locales';
import { flow, map, omit, sum } from 'lodash/fp';
import CustomTable from 'shared/components/CustomTable';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button } from 'shared/io';
import { sortByDate } from 'shared/utils/date';
import { currencySymbol, formatAmount } from 'shared/utils/normalization';
import { Invoice } from 'types/store/invoice-state';
import { PaymentHistory } from 'types/store/transaction-state';

import { styled } from '@stitches/react';

import { InvoiceTableItem } from './InvoiceTableItem';

const ModalBody = styled('div', { padding: '20px' });

const Tally = styled('div', {
  fontSize: '1.25rem',
  fontWeight: 'bold',
  marginTop: '20px',
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  color: '#343944',
  '& span:nth-of-type(0)': {
    color: '#eb6d2f',
  },
  variants: {
    isSaturated: {
      true: {
        color: '#25c1a4',
      },
    },
  },
});

const ActionRow = styled('div', {
  width: '100%',
  display: 'flex',
  justifyContent: 'end',
  gap: '15px',
});

const InvoiceTable = CustomTable(InvoiceTableItem);

function buildHeaders(t: (key: string) => string) {
  return [
    { title: t(i18nKeys.FORM.REFERENCE) },
    { title: t(i18nKeys.ISSUE_DATE) },
    { title: t(i18nKeys.FORM.CHOOSE_INVOICES.REMAINING_AMOUNT) },
    { title: t(i18nKeys.WITH_FEES) },
    { title: t(i18nKeys.AMOUNT) },
  ];
}

function getInitialAmounts(invoices: Array<Invoice>, totalAssignableAmount: number) {
  const [finalAmounts] = sortByDate(invoices, 'issue_date', 'oldestFirst').reduce(
    ([amounts, remainingAssignableAmount], invoice) => {
      const amount = Math.min(remainingAssignableAmount, Number(invoice.remaining_balance));

      const fixedPrecisionAmount = Number(amount.toFixed(2));

      return [
        {
          ...amounts,
          [invoice.id]: String(fixedPrecisionAmount),
        },
        remainingAssignableAmount - fixedPrecisionAmount,
      ] as [Record<Id, string>, number];
    },
    [{}, totalAssignableAmount] as [Record<Id, string>, number],
  );

  return finalAmounts;
}

function handleSetAmount(
  invoiceId: Id,
  _newAmount: string,
  amounts: Record<Id, string>,
  invoices: Array<Invoice>,
  totalAssignableAmount: number,
  setAmounts: React.Dispatch<React.SetStateAction<Record<Id, string>>>,
) {
  // Allow fully erasing the field to avoid sticky leading 0s
  if (_newAmount === '') {
    setAmounts((prev) => ({
      ...prev,
      [invoiceId]: '',
    }));
    return;
  }

  _newAmount = _newAmount.replaceAll(',', '.');
  const newAmount = Number(_newAmount);

  if (newAmount < 0) {
    setAmounts((prev) => ({
      ...prev,
      [invoiceId]: '0',
    }));
    return;
  }

  const currentlyAssignedAmountWithoutThisField = flow(
    omit([invoiceId]),
    Object.values,
    map(Number),
    sum,
  )(amounts);

  const amountMissingFromTotalWithoutThisField = Number(
    (totalAssignableAmount - currentlyAssignedAmountWithoutThisField).toFixed(2),
  );

  const invoice = invoices.find((inv) => inv.id === invoiceId);
  if (invoice == null) return;

  const isNewAmountTooHigh =
    newAmount > amountMissingFromTotalWithoutThisField ||
    newAmount > invoice.remaining_balance_with_fees;

  const clampedAmount = Math.min(
    invoice.remaining_balance_with_fees,
    amountMissingFromTotalWithoutThisField,
  );

  setAmounts((prev) => ({
    ...prev,
    [invoiceId]: isNewAmountTooHigh ? clampedAmount.toString() : newAmount.toString(),
  }));
}

interface ManualReconciliationModalProps {
  payments: Array<PaymentHistory>;
  invoices: Array<Invoice>;
  onSuccess: () => void;
}

export const ManualReconciliationModal = ({
  payments,
  invoices,
  onSuccess,
}: ManualReconciliationModalProps) => {
  const { t } = useTranslation();
  const { company } = useLoadCompanyConfiguration();
  const { mutate: reconcile, isPending } = useManualReconciliation();

  const totalAssignableAmount = flow(map('remaining_balance'), map(Number), sum)(payments);

  const [assignedAmounts, setAssignedAmounts] = React.useState<Record<Id, string>>(() =>
    getInitialAmounts(invoices, totalAssignableAmount),
  );

  const currentlyAssignedAmount = flow(Object.values, map(Number), sum)(assignedAmounts);
  const isSaturated = currentlyAssignedAmount === totalAssignableAmount;

  const handleReconciliation = () => {
    reconcile(
      {
        data: {
          invoices: Object.entries(assignedAmounts).map(([id, amount]) => ({
            id,
            amount: Number(amount),
          })),
          payment_ids: payments.map((payment) => payment.id),
        },
      },
      {
        onSuccess: () => onSuccess(),
      },
    );
  };

  return (
    <ModalBody>
      <InvoiceTable
        noMinHeight
        style={{ fontSize: '12px' }}
        headers={buildHeaders(t)}
        items={invoices}
        itemProps={{
          handleSetAmount: (id, amount) =>
            handleSetAmount(
              id,
              amount,
              assignedAmounts,
              invoices,
              totalAssignableAmount,
              setAssignedAmounts,
            ),
          amounts: assignedAmounts,
          disableButtons: isSaturated,
        }}
        noCheckbox
      />
      <Tally isSaturated={isSaturated}>
        <span style={{ color: isSaturated ? '#25c1a4' : '#eb6d2f' }}>
          <AnimateNumber
            duration={400}
            value={currentlyAssignedAmount}
            formatValue={(value) =>
              formatAmount(value, ',', '.', 2, currencySymbol(undefined, company))
            }
          />
        </span>{' '}
        <span style={{ color: isSaturated ? '#25c1a4' : 'inherit' }}>
          {`/ ${formatAmount(
            totalAssignableAmount,
            ',',
            '.',
            2,
            currencySymbol(undefined, company),
          )}`}
        </span>
      </Tally>
      <ActionRow>
        <Button
          disabled={currentlyAssignedAmount === 0 || isPending}
          onClick={handleReconciliation}
        >
          {t(i18nKeys.RECONCILE)}
        </Button>
      </ActionRow>
    </ModalBody>
  );
};

import { useEffect } from 'react';
import { AssignableModel, AssignableTo } from 'api/models';
import { AutomaticAssignationsList } from 'app/Private/AutomaticAssignations';
import classNames from 'classnames/bind';
import { i18nKeys, useTranslation } from 'locales';
import { FormProvider, useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MoveRecoveryPlanInvoices from 'shared/action-component/MoveRecoveryPlanInvoices/MoveRecoveryPlanInvoices';
import AYSModal from 'shared/components/AYSModal';
import ChooseRecoveryPlanModal from 'shared/components/ChooseRecoveryPlanModal';
import HTML from 'shared/components/HTML/HTML';
import { Icon, IconName } from 'shared/components/Icon';
import { CustomRecoveryPartialForm } from 'shared/forms/PreferencesForm';
import { useLoadCompanyConfiguration } from 'shared/hooks';
import { Button, Dropdown } from 'shared/io';
import { settingsActions } from 'store/settings/settings.actions';
import { dialogHide, DialogShowId, DialogShowSize, showDialog } from 'store/view/view.actions';
import { RecoveryPlanShort } from 'types/recovery-plan';
import { StoreState } from 'types/storeTypes';

import styleIdentifiers from './ListDebtCollectionCustomization.module.scss';

const styles = classNames.bind(styleIdentifiers);

type Props = {};

export default function ListDebtCollectionCustomization({}: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const { company } = useLoadCompanyConfiguration();
  const recoverPlans = useSelector((state: StoreState) => state.settings.recoveryPlanList);

  const includedPlanCount = company.package?.included_plan_count || 0;
  const pagesLength = recoverPlans?.pages?.length || 0;
  const canAdd = includedPlanCount > pagesLength;

  const form = useForm({
    shouldUnregister: true,
    defaultValues: {
      ...company,
      generate_invoice_in_debtor_locale: company.generate_invoice_in_debtor_locale?.toString(),
      tp_accounting_automatic_reminders: company.tp_accounting.automatic_reminders,
      automatic_reminders: company.automatic_reminders.toString(),
      default_formal_notice_template_id: company.default_templates.formal_notice,
      default_additional_reminder_template_id: company.default_templates.additional_reminder,
    },
  });

  const onSubmit = (values: any) => {
    settingsActions.editDebtCollection({
      data: values,
      callback: () => {
        queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
      },
    });
  };

  const submit = (value) => {
    if (
      JSON.parse(value.automatic_reminders) !== JSON.parse((company as any).automatic_reminders)
    ) {
      showDialog({
        id: DialogShowId.CONFIRM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.CONFIRMATION),
        children: (
          <AYSModal
            text={
              JSON.parse(value.automatic_reminders) ? (
                <div className={styles('confirm-modal')}>
                  <HTML html={t(i18nKeys.FORM.PREFERENCES.AYS_AUTOMATIC_REMINDER_MODE)} />
                  {company.reminders_to_send_count &&
                    (company.reminders_to_send_count.next_work_day ?? 0) > 0 && (
                      <div>
                        {t(
                          (company.reminders_to_send_count?.next_work_day ?? 0) > 1
                            ? i18nKeys.FORM.PREFERENCES.NEXT_WORKING_DAYS
                            : i18nKeys.FORM.PREFERENCES.NEXT_WORKING_DAY,
                          {
                            count: company.reminders_to_send_count.next_work_day ?? 0,
                          },
                        )}
                      </div>
                    )}
                </div>
              ) : (
                <div className={styles('confirm-modal')}>
                  <HTML html={t(i18nKeys.FORM.PREFERENCES.AYS_MANUAL_REMINDER_MODE)} />
                </div>
              )
            }
            onConfirm={() => {
              onSubmit(value);
              form.reset(value);
            }}
          />
        ),
      });
    } else {
      onSubmit(value);
      form.reset(value);
    }
  };

  const addRecoverPlan = () => {
    history.push('/settings/workflows/recovery-plans/create');
  };

  useEffect(() => {
    settingsActions.recoveryPlanListPage();
  }, []);

  const setPlanAsDefault = (plan: RecoveryPlanShort, steps_switch?: any) => {
    settingsActions.editDebtCollection({
      data: {
        recovery_plan_id: plan.id,
        steps_switch,
      },
      callback: () => {
        queryClient.invalidateQueries({ queryKey: ['company-configuration'] });
        settingsActions.recoveryPlanListPage();
        dialogHide(DialogShowId.CUSTOM);
      },
    });
  };

  const setAsDefault = (plan: RecoveryPlanShort) => () => {
    if (company.recovery_plan && (company.recovery_plan.invoices_using ?? 0) > 0) {
      showDialog({
        id: DialogShowId.CUSTOM,
        size: DialogShowSize.SMALL,
        title: t(i18nKeys.SET_DEFAULT_PLAN),
        children: (
          <AYSModal
            text={t(i18nKeys.PLAN.MOVE_INVOICES_IN_NEW_PLAN)}
            cancelButtonText={t(i18nKeys.NO)}
            confirmButtonText={t(i18nKeys.YES)}
            onConfirm={() => {
              MoveRecoveryPlanInvoices({
                title: t(i18nKeys.SET_DEFAULT_PLAN),
                oldPlanId: company.recovery_plan.id,
                newPlanId: plan.id,
                onSubmit: (steps_switch) => {
                  setPlanAsDefault(plan, steps_switch);
                },
                companyDefault: true,
              });
            }}
            onCancel={() => {
              dialogHide(DialogShowId.CUSTOM);
              setPlanAsDefault(plan);
            }}
          />
        ),
      });
    } else {
      setPlanAsDefault(plan);
    }
  };

  const editPlan = (itemId) => () => {
    history.push(`/settings/workflows/recovery-plans/edit/${itemId}`);
  };

  const moveInvoicesPlan = (oldPlan: RecoveryPlanShort) => () => {
    showDialog({
      title: t(i18nKeys.PLAN.WHERE_MOVE_INVOICES),
      id: DialogShowId.CUSTOM,
      size: DialogShowSize.MEDIUM,
      children: (
        <ChooseRecoveryPlanModal
          recoverPlans={recoverPlans.pages!}
          onClick={(planId) => {
            MoveRecoveryPlanInvoices({
              title: t(i18nKeys.PLAN.INVOICES),
              newPlanId: planId,
              oldPlanId: oldPlan.id,
              onSubmit: (steps_switch) => {
                settingsActions.switchPlansInvoices({
                  data: {
                    plans_switch: [
                      {
                        from: oldPlan.id,
                        to: planId,
                        steps_switch,
                      },
                    ],
                  },
                  callback: () => {
                    settingsActions.recoveryPlanListPage();
                    dialogHide(DialogShowId.CUSTOM);
                  },
                });
              },
            });
          }}
        />
      ),
    });
  };

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className={styles('list-debt-collection-customization')}>
      <div className={styles('recovery-plans')}>
        <ul>
          {recoverPlans.pages?.map((item) => (
            <li key={item.id}>
              <div key={item.id} className={styles('item', 'card')} onClick={editPlan(item.id)}>
                <div className={styles('title')}>
                  <h4 title={item.name}>{item.name}</h4>
                  {item.company_default && (
                    <div className={styles('label', 'badge')}>{t(i18nKeys.DEFAULT)}</div>
                  )}
                  <div onClick={stopPropagation}>
                    <Dropdown
                      contentClass={styles('dropdown-container')}
                      selectorContent={
                        <Icon name={IconName.POINTS} className={styles('menu-icon')} />
                      }
                      toggleContent
                    >
                      {item.company_default && item.invoices_using === 0 ? (
                        <div className={styles('dropdown-item')}>
                          {t(i18nKeys.NO_AVAILABLE_ACTIONS)}
                        </div>
                      ) : (
                        <>
                          {!item.company_default && (
                            <div className={styles('dropdown-item')} onClick={setAsDefault(item)}>
                              {t(i18nKeys.PLAN.SET_AS_DEFAULT)}
                            </div>
                          )}
                          {item.invoices_using > 0 && (
                            <div
                              className={styles('dropdown-item')}
                              onClick={moveInvoicesPlan(item)}
                            >
                              {t(i18nKeys.PLAN.MOVE_INVOICES)}
                            </div>
                          )}
                        </>
                      )}
                    </Dropdown>
                  </div>
                </div>

                <div className={styles('item-body')}>
                  <div>{t(i18nKeys.N_STEP, { count: item.recovery_steps_count })} </div>
                  {item.debtors_using + item.invoices_using === 0 ? (
                    <div> {t(i18nKeys.UNUSED)}</div>
                  ) : (
                    <>
                      <div>
                        {item.debtors_using}{' '}
                        <Icon
                          title={t(i18nKeys.PLAN.CLIENTS_NUMBER_INCLUDED)}
                          name={IconName.USER}
                        />{' '}
                      </div>
                      <div>
                        {item.invoices_using}{' '}
                        <Icon
                          title={t(i18nKeys.PLAN.INVOICES_NUMBER_INCLUDED)}
                          name={IconName.INVOICE}
                        />{' '}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          ))}
          <li>
            <div
              className={styles('item', 'card', 'add-item', !canAdd && 'disabled')}
              onClick={addRecoverPlan}
            >
              <div>
                <Icon name={IconName.PLUS} />
                <span>{t(i18nKeys.ACTIONS.ADD)}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <AutomaticAssignationsList
        defaultType={AssignableTo.recovery_plan}
        tabItems={[
          {
            model_to_assign: AssignableModel.invoice,
            assign_to_type: AssignableTo.recovery_plan,
            key: i18nKeys.COMMON.INVOICE,
          },
          {
            model_to_assign: AssignableModel.debtor,
            assign_to_type: AssignableTo.recovery_plan,
            key: i18nKeys.COMMON.CLIENT,
          },
        ]}
      />

      <form onSubmit={form.handleSubmit(submit)}>
        <FormProvider {...form}>
          <h3>{t(i18nKeys.FORM.PREFERENCES.CUSTOM_RECOVERY)}</h3>
          <CustomRecoveryPartialForm />
          <div className={styles('button-wrapper')}>
            <Button
              noShadow
              disabled={!form.formState.isDirty}
              label={t(i18nKeys.SAVE)}
              type="submit"
            />
          </div>
        </FormProvider>
      </form>
    </div>
  );
}
